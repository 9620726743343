import React, { FC, useEffect, useState } from "react";
import { Row, Col, Button, Modal, ModalBody, Label } from "reactstrap";
import { Controller, useFormContext } from "react-hook-form";
import FormInput from "../../form-components/InputComponent";
import FormSelect from "../../form-components/SelectComponent";
import { Icon, TooltipComponent } from "../../../components/Component";
import FormDatePicker from "../../form-components/DatePicker";
import { Divider, Popover, Space } from "antd";
import { EditOutlined } from "@ant-design/icons";
import QuotationApprovalDrawer from "../../purchase-orders/QuotationApprovalDrawer";
import DeliveryAddressMaster from "../../masters/delivery-address/DeliveryAddressMaster";
import deliveryAddressApis from "../../../api/master/deliveryAddress";
import usersApis from "../../../api/master/users";
import PRApprovalDrawer from "./PRApprovalDrawer";
import { useDispatch, useSelector } from "react-redux";
import { fetchSequenceAccess } from "../../../store/slices/misc/sequenceAccess";
import contractorsApis from "../../../api/master/contractors";

interface SelectOptions {
  label: string;
  value: string;
}
const PurchaseRequest_GeneralDetails: FC = (props: any) => {
  const dispatch: any = useDispatch();
  const { data: sequenceAccess } = useSelector(
    (state: any) => state.sequenceAccess
  );
  const {
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useFormContext();
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [usersOptions,setUsersOptions] = useState<SelectOptions[]>([]);
  const [popUpContent, setPopUpContent] = useState<JSX.Element | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [approvals, setApprovals] = useState({
    open: false,
    data: [],
  });
  const approvalRequired = watch("approvalRequired");
  const deliveryAddressId = watch("deliveryAddressId");
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
    []
  );
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const siteIdWatch = watch("siteId");
  const AllFormDetails = watch();


  useEffect(() => {
    fetchSites();
    fetchDeliveryAddress();
    fetchUsers();
    const { approvalsData } = AllFormDetails;
    if (approvalsData) {
      setApprovals(JSON.parse(approvalsData));
    }
  }, []);


  const submitForm = (data: any) => {
    setValue("approvalsData", JSON.stringify(approvals));
    if (approvalRequired == "true" && approvals?.data?.length === 0) {
      return;
    }
    console.log("data", data);
    props?.next();
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser()
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const fetchUsers = async () => {
    try {
      const data = await usersApis.fetchBuyerUser()
      if (data) {
        console.log("Data",data)
        let usersOptions = data?.data?.map((item: any) => ({
          label: item.name,
          value: item.userID.toString(),
        }));
        setUsersOptions(usersOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };


  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }

    let address = options?.find((item: any) => item[matchKey] == id);
    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            {/* <li><strong>{address.deliveryLocationName}</strong></li> */}
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city},{address?.districtName}, {address?.stateName},
              {address?.countryName} - {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };


  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModelPopUp = (id?: any, type?: string) => {
    const content = (
      <DeliveryAddressMaster
        formAction={type}
        id={id}
        toggleModelPopUp={() => {
          setIsOpen(false);
          fetchDeliveryAddress();
        }}
      />
    );
    setPopUpContent(content);
    setIsOpen(true);
  };
  useEffect(() => {
    if (siteIdWatch) {
      fetchContractorList(siteIdWatch);
    }
  }, [siteIdWatch]);

  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);


  return (
    <>
      <form className="content clearfix" onSubmit={handleSubmit(submitForm)}>
        <Row className="gy-4">
        {!sequenceAccess?.includes("Purchase Request") ? (
            <Col lg="4">
              <FormInput
                name="purchaseRequestId"
                label={`Purchase Request Number`}
                required={true}
                placeholder="Enter Purchase Request Number"
              />
            </Col>
          ) : (
            <></>
          )}
          <Col lg="4">
            <FormInput
              name="referenceIndentNumber"
              label="Indent Number"
              required={true}
              type="text"
              placeholder="Enter Indent Number"
              tooltipText="Enter Indent Number."
            />
          </Col>
          <Col lg="4">
            <FormDatePicker
              name="indentDate"
              label="Indent Date"
              required={true}
              minDate={new Date()}
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="siteId"
              label={`Site`}
              options={sitesOptions}
              required={true}
              placeholder="-Select-"
              tooltipText="Select the site where the materials will be used."
            />
          </Col>
        </Row>
        <Row className="gy-4 mt-1">
        <Col lg="4">
            <FormSelect
              name="contractorId"
              label={`Contractor`}
              options={contractorOptions}
              placeholder="-Select-"
              tooltipText="Select the contractor for this purchase request."
            />
          </Col>
          <Col lg="4">
            <FormSelect
              name="requestedBy"
              label={`Requested By`}
              options={usersOptions}
              placeholder="-Select-"
              // tooltipText="Select the general ledger account for financial tracking."
            />
          </Col>
          <Col lg="4">
              <FormDatePicker
                name="needByDate"
                label="Need By Date"
                required={true}
                minDate={new Date()}
              />
            </Col>
          
        </Row>
        <Row className="gy-4 mt-1">
        <Col lg="4">
            <FormSelect
              name="approvalRequired"
              label="Approval Required ?"
              options={[
                { label: "Yes", value: "true" },
                { label: "No", value: "false" },
              ]}
              placeholder="Select"
              required={true}
            />
            <div style={{ display: "none" }}>
              <FormInput name="approvalsData" label="" type="text" />
            </div>
            {approvalRequired == "true" && (
              <Popover
                content="Click here to set required approvals"
                title="Approval Required"
              >
                <a
                  href="#"
                  className="success"
                  onClick={(e) => {
                    e.preventDefault();
                    setApprovals((pre) => {
                      return {
                        ...pre,
                        open: true,
                      };
                    });
                  }}
                >
                  <EditOutlined /> Set Approvals &nbsp;
                  {approvals?.data?.length == 0 ? (
                    <span className="mandatory">Please set approvals</span>
                  ) : (
                    <></>
                  )}
                </a>
              </Popover>
            )}
          </Col>
            <Col lg="4">
              <FormSelect
                name="deliveryAddressId"
                label="Delivery Address"
                options={deliveryAddressOptions}
                required={true}
                placeholder="-Select-"
                tooltipText="Select the delivery address for the purchase order."
                popOverOptions={
                  <ShowAddressPopOver
                    id={deliveryAddressId}
                    matchKey="id"
                    options={deliveryAddressList}
                    text="View Selected Delivery Address"
                  />
                }
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider style={{ margin: "8px 0" }} />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Button
                        type="button"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleModelPopUp(null, "");
                        }}
                        style={{
                          width: "100%",
                          textAlign: "center",
                        }}
                      >
                        <span>Add Address</span>
                      </Button>
                    </Space>
                  </>
                )}
              />
            </Col>
        </Row>
        <Row className="gy-4 mt-1">
          <Col>
            <Button color="primary" type="submit">
              Next
            </Button>
          </Col>
        </Row>
      </form>
      {approvals.open && <PRApprovalDrawer
        open={approvals.open}
        siteId={siteIdWatch}
        onClose={() => {
          setApprovals((pre) => {
            return {
              ...pre,
              open: false,
            };
          });
        }}
        onSubmit={(data: any) => {
          setApprovals({
            open: false,
            data: data,
          });
        }}
        setApprovalSubmissionStatus={(status: boolean) => { }}
        approvalData={approvals.data} // Pass the current approval data
      />}
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setIsOpen(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>{popUpContent}</ModalBody>
      </Modal>
    </>
  )

};

export default PurchaseRequest_GeneralDetails;