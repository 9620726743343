import { message, Upload } from "antd";
import { UploadFile } from "antd/es/upload/interface";
import { formatCurrency } from "../../utils/Utils";

const { Dragger } = Upload;

const FormatCurrency = (
  number: any,
  currencyCode: any,
) => {
  try{
    return formatCurrency(number, currencyCode);
  }catch(error){
    return ""
  }
};

  const beforeUpload = (file: UploadFile) => {
    const isAllowedType = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/gif",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(file.type || "");
    if (!isAllowedType) {
      message.error(
        "You can only upload PDF, JPG, JPEG, PNG, GIF, DOC, DOCX files!"
      );
      return Upload.LIST_IGNORE;
    }
    if (file.size && file.size / 1024 / 1024 >= 2) {
      message.error("File must be smaller than 5MB!");
      return Upload.LIST_IGNORE;
    }
    return true;
  };

const Misc = { FormatCurrency,beforeUpload };

export default Misc;
