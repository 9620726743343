import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import FormatDate from "../../masters/DateFormater";
import { useNavigate } from "react-router";
import { Icon, TooltipComponent } from "../../../components/Component";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function PurchaseOrderReports() {
  const navigate = useNavigate();
  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      width: 200,
      dataIndex: "orderId",
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink" 
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumer}`, { state: {
                previousPath:"/purchase-order-reports-list"
              }});
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      key: "orderDate",
      title: "Order Date",
      width: 100,
      dataIndex: "orderDate",
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      key: "createdByName",
      title: "Created By",
      dataIndex: "createdByName",
      width: 200,
    },
    {
      key: "creationDate",
      title: "Creation Date",
      dataIndex: "creationDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      key: "reqDeliveryDate",
      title: "Requested Delivery Date",
      dataIndex: "reqDeliveryDate",
      width: 170,
      render: (date: any) => <span>{date?FormatDate(date): "N/A"}</span>,
    },
    {
      key: "supplierName",
      title: "Supplier Name",
      dataIndex: "supplierName",
      width: 200,
    },
    {
      key: "amount",
      title: "Order Value With Tax",
      dataIndex: "amount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      key: "deliveryAddress",
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return<>
        <span id={`desc-tooltip-${index}`}>
          {text?.length > 20 ? `${text!.substring(0, 25)}... ` : text}
        </span>
        {
          text?.length > 20 && (
            <TooltipComponent
              iconClass="info-circle text-info"
              icon="info"
              id={`desc-tooltip-${index}`}
              direction="top"
              text={text}
              containerClassName={""}
            />
          )
        }
        </>
      },
    },
    {
      key: "siteName",
      title: "Site Name",
      dataIndex: "siteName",
      width: 200,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: 200,
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Purchase Order"
      moduleKey="purchaseOrdersReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
      }}
      previousPath="/view-purchase-reports"
    />
  );
}

export default PurchaseOrderReports;
