import React, { useState, useEffect, FC, useCallback, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../layout/content/Content";
import {
  Card,
  Badge,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Spinner,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalHeader,
} from "reactstrap";
import Head from "../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Drawer,
  Upload,
  Select,
  Popover,
  DatePicker,
  TableColumnsType,
  Timeline,
  Empty,
  UploadFile,
  List,
  Checkbox,
} from "antd";
import FormSelect from "../form-components/SelectComponent";
import FormInput from "../form-components/InputComponent";
import FormDatePicker from "../form-components/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import QuotationsAside from "./QuotationsAside";
import statusMapper from "../purchase-orders/StatusMapper";
// import QuotationApprovalDrawer from "../invoice/QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../masters/Misc";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import invoiceApis from "../../api/Invoice/invoice";
import Swal from "sweetalert2";
import CropperComponent from "../components/CropperComponent";
import SignatureModal from "../purchase-orders/SignatureModal";
import usersApis from "../../api/master/users";
import quotationApis from "../../api/master/quotations";
import { useLocation } from "react-router-dom";
import QuotationApprovalDrawer from "./newQuotations/QuotationApprovalDrawer";
import { AddressDetails, Material, SelectOptions } from "./newQuotations/types";
import Dragger from "antd/es/upload/Dragger";
import costCenterApis from "../../api/master/costCenter";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import MaterialModal from "./MaterialModal";
import supplierApis from "../../api/master/supplier";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { formatDecimal } from "../../utils/Utils";
import PdfGenerator from "./BuyerToSellerPDF";
import EmailCreationModal from "./EmailCreationModal";
import FormTextArea from "../form-components/TextAreaComponent";
import contractorsApis from "../../api/master/contractors";

const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}

const ViewDetailsEdit: FC<any> = () => {
  // const pdfBlobData: any = useRef(null);
  const [pdfBlobData, setPdfBlobData] = useState<any>({
    current: null,
    url: "",
  });
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [formatedSuppliersList, setFormatedSuppliersList] = useState<any>([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareOptionsToggle, setShareOptionsToggle] = useState(false);
  const location = useLocation();
  const screenNavigationStatus = location.state?.screenStatus;
  const [isLoading, setIsLoading] = useState(true);
  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );
  let { qid }: any = useParams();
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [rfqStatus, setRfqStatus] = useState<any>("");
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [supplierList, setSupplierList] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
  const [newAttachments, setNewAttachments] = useState<any>([]);
  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const [newInstructions, setNewInstructions] = useState<any>("");
  const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);

  const methods = useForm({
    mode: "onChange",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [supplierData, setSupplierData] = useState<any>([]);

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const termsAndConditionsWatch = watch("termsAndConditions");
  const instructionsWatch = watch("instructions");
  useEffect(() => {
    if (termsAndConditionsWatch) {
      setNewTermsAndConditions(termsAndConditionsWatch);
    }
  }, [termsAndConditionsWatch]);
  useEffect(() => {
    if (instructionsWatch) {
      setNewInstructions(instructionsWatch);
    }
  }, [instructionsWatch]);

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);
  const [disableApprove, setDisableApprove] = useState(false);
  const [disableReject, setDisableReject] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectRecord, setRejectRecord] = useState<any>(null);
  const [form] = Form.useForm();
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>(
    []
  );
  const [billingAddressList, setBillingAddressList] = useState<
    AddressDetails[]
  >([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [selectedArrayData, setSelectedArrayData] = useState([]);

  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);

  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);

  const deliveryLevelTypeWatch = watch("deliveryLevelType");
  const deliveryDateTypeWatch = watch("deliveryDateType");
  const siteIdWatch = watch("siteId");

  useEffect(() => {
    if (siteIdWatch) {
      fetchContractorList(siteIdWatch + "");
    }
  }, [siteIdWatch]);

  useEffect(() => {
    fetchDetails();
    fetchAddresses();
    fetchCostCenter();
    fetchSites();
    viewChange();
    fetchDeliveryAddress();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const TriggerEmail = async (emailData: any, _methods: any) => {
    try {
      const formData = new FormData();
      formData.append("msgType", "purchaseOrderConfirmation");
      formData.append("supplierId", emailData?.supplierId);
      formData.append("logo", currentUser?.orgLogo);
      formData.append("email", emailData?.to);
      formData.append("cc", emailData?.cc);
      formData.append("bcc", emailData?.bcc);

      const _response: any = await quotationApis.getLatestBySupplierQuotationId(
        qid,
        emailData?.supplierId
      );
      if (!_response?.status) {
        return;
      }
      const supplierDataResponse = _response?.response?.data;

      const { pdfBlob: customePdfBlob }: any = await PdfGenerator(
        returnBillingDetails(OriginalData),
        returnSupplierDetails(OriginalData),
        returnDetails(supplierDataResponse?.quotationInfo?.quotationLines),
        currentUser?.orgLogo,
        false,
        termsAndConditions
      );

      if (emailData?.attachments?.length > 0) {
        emailData?.attachments?.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          let file: any = null;
          if (index == 0) {
            file = new File(
              [customePdfBlob],
              OriginalData?.quotationHeader?.rfqId + ".pdf",
              {
                type: "application/pdf",
              }
            );
          } else {
            file = attachment?.originFileObj;
          }
          formData.append(`files`, file);
        });
      }
      const response: any = await quotationApis.emailNotify(qid, formData);
      const { data, status } = response;
      if (status) {
        _methods.reset();
        Swal.fire("Success", "Email has been sent successfully", "success");
        setShowEmailModal(false);
      }
    } catch (error) {
      console.log(error);
      setShowEmailModal(false);
    }
  };

  const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
    setNewAttachments((pre: any) => {
      return [...pre, ...info.fileList];
    });
    setShowSubmitBtm(true);
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchSuppliers = async (LineItems: any) => {
    console.log("data", LineItems);
    try {
      let allCat: any = [];
      LineItems?.forEach((item: any) => {
        allCat.push(item.categoryId);
      });
      console.log("allCat", allCat);
      const allCatString = allCat.join(",");
      console.log("allCatString", allCatString);
      const { data, status, message } =
        await supplierApis.fetchSuppliersByCategoryId({ id: allCatString });
      console.log("status:", status);
      if (status) {
        console.log("1111111::::");
        let formatedData = data.map((item: any) => ({
          supplierName: item.supplierName, // Adjust this based on the actual key in your data
          supplierID: item?.supplierOrgID
            ? item?.supplierOrgID?.toString()
            : item?.supplierID?.toString(),
        }));
        console.log("formatedData", formatedData);
        setSuppliers(formatedData);
        return formatedData;
      } else {
        throw new Error(message);
      }
    } catch (error) {}
  };

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchDetails = async () => {
    try {
      const res = await quotationApis.getById(qid);
      const { response, status, message } = res;
      if (status) {
        const data = response.data;
        setOriginalData(data);
        console.log("data", data);
        setRfqStatus(data?.quotationHeader.status);
        console.log("rfqStatus", data?.quotationHeader.status);
        if (data?.quotationApprovals) {
          let ids = data?.quotationApprovals?.map((item: any) => item?.userId);
          ids = ids?.filter((item: any) => item?.status != "Approved");
          setApproverIds(ids);
        }

        if (data?.addtionalData.selectedSuppliers) {
          console.log("Suppliers:::", suppliers);
          const selectedSuppliersSet = new Set(
            data?.addtionalData.selectedSuppliers.map(String)
          );
          const filteredSuppliers = suppliers.filter((supplier) =>
            selectedSuppliersSet.has(supplier.supplierID)
          );
          console.log("filteredSuppliers", filteredSuppliers);
          setSupplierData(filteredSuppliers);
          setSelectedArrayData(data?.addtionalData.selectedSuppliers);
        }

        if (data?.quotationHeader?.dms?.length > 0) {
          setDMS(data?.quotationHeader?.dms);
          const _termsAndConditions = data?.quotationHeader?.dms?.find(
            (item: any) => item.moduleFileType == "termsAndConditions"
          );
          if (_termsAndConditions) {
            const data: any = await fetchHtmlContentFromUrl(
              _termsAndConditions?.filePath
            );
            setTermsAndConditions(data);
            setNewTermsAndConditions(data);
          }
          const _instructions = data?.quotationHeader?.dms?.find(
            (item: any) => item.moduleFileType == "instructions"
          );
          if (_instructions) {
            const data: any = await fetchHtmlContentFromUrl(
              _instructions?.filePath
            );
            setInstructions(data);
            setNewInstructions(data);
          }
          const _attachments = data?.quotationHeader?.dms?.filter(
            (item: any) => item.moduleFileType == "attachments"
          );
          if (_attachments) {
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                fileName: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }
        setMaterialData(data);
        const LineItems: any = data?.quotationLines?.map((item: any) => {
          const file = item.dms?.find(
            (doc: any) => doc.quotationLineId == item.quotationLineId
          );
          console.log("file", file);
          if (file) {
            item.file = file;
            item.attachment = true;
          } else item.attachment = false;
          return item;
        });
        setData(LineItems);
        console.log("lines", LineItems);
        setShowSubmitBtm(false);
        setDeletedAttachments([]);
        setNewAttachments([]);
        if (LineItems) {
          const _suppliers: any = await fetchSuppliers(LineItems);
          setFormatedSuppliersList(_suppliers);
        }
        // setCurrency({
        //     description: currencyCode,
        //     currency_code: currencyCode,
        //     currency_symbol: currencySymbol,
        //     currency_format: currencyFormat,
        //     example: currencyCode,
        // });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        const costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAddresses = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        const addressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setBillingAddressList(data);
        setBillingAddressOptions(addressOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    // {
    //     ...Table.EXPAND_COLUMN,
    //     fixed: "left",
    // },
    // {
    //     title: "MATERIAL ID",
    //     dataIndex: "materialId",
    //     key: "materialId",
    //     fixed: "left" as const,
    //     // width: 120,
    // },
    {
      title: "CATEGORY",
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left" as const,
      // width: 150,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      // width: 200,
    },
    // {
    //     title: "DESCRIPTION",
    //     dataIndex: "description",
    //     key: "description",
    //     width: 200,
    // },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "qty",
      width: 170,
      render: (text: any, record: any) => (
        <>
          {/* {console.log("awardedQty", record.awardedQty)} */}
          {Number(text).toLocaleString("en-IN")}
          {/* {record.awardedQty} */}
          {record.awardedQty !== 0 && record.awardedQty !== undefined && (
            <Popover
              content={
                <div>
                  {/* Convert awardedQty to a number and handle invalid values */}
                  <p>
                    Converted Qty:{" "}
                    {!isNaN(Number(record?.awardedQty))
                      ? Number(record.awardedQty).toLocaleString("en-IN")
                      : "N/A"}
                  </p>
                </div>
              }
              title="Info"
            >
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </Popover>
          )}
        </>
      ),
    },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      // width: 150,
    },
    {
      title: "NEED BY DATE",
      dataIndex: "deliveryDate",
      key: "deliveryDate",
      hidden:
        materialData?.quotationHeader?.deliveryDateType === "LINE_LEVEL"
          ? false
          : true,
      render: (text) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right",
      width: 150,
      hidden: ["REJECTED"].includes(rfqStatus),
      render: (text, record: any, index) => (
        <Button
          size="sm"
          color=""
          onClick={() => {
            setEditLineRow(record);
          }}
        >
          <Icon name="edit" />
        </Button>
      ),
    },
  ]);

  const filteredColumns = columns.filter((col) => {
    if (
      col.key == "operation" &&
      ["Awarded", "Negotiated", "Quoted"].includes(rfqStatus)
    ) {
      return false; // Filter out the "Action" column if poStatus is "IN_PROGRESS"
    }
    return true; // Keep other columns
  });

  // const checkboxValues = watch();

  // const [selectedSuppliers, setSelectedSuppliers]:any = useState();

  // useEffect(() => {
  //     setSelectedSuppliers(new Set(selectedSuppliers?.map(String)))
  //     const newSelectedSuppliers = Object.keys(checkboxValues)
  //         .filter(key => checkboxValues[key])
  //         .map(key => key.replace('supplier_', ''));
  //     setSelectedSuppliers(new Set(newSelectedSuppliers));
  //     setShowSubmitBtm(true);
  // }, [checkboxValues]);

  const handleCheckboxChange = (supplierID: any) => (event: any) => {
    console.log("PreviousData::selectedArrayData", selectedArrayData);
    console.log("supplierID", supplierID);
    let isChecked = event.target.checked;
    setValue(`supplier_${supplierID}`, isChecked);
    console.log("isChecked", isChecked);
    setSelectedArrayData((prevSelectedArrayData: any) => {
      if (isChecked) {
        // Add ID to the array
        console.log("add", [...prevSelectedArrayData, Number(supplierID)]);
        return [...prevSelectedArrayData, Number(supplierID)];
      } else {
        // Remove ID from the array
        console.log(
          "remove",
          prevSelectedArrayData.filter((id: any) => id !== Number(supplierID))
        );
        return prevSelectedArrayData.filter(
          (id: any) => id !== Number(supplierID)
        );
      }
    });
    setShowSubmitBtm(true); // Show submit button when any checkbox is changed
  };

  const renderSupplierCheckboxes = (
    suppliers: { supplierName: string; supplierID: string }[],
    isDisabled: boolean
  ) => {
    let selectedSuppliersSet: any = new Set(
      OriginalData.addtionalData.selectedSuppliers.map((id: any) => String(id))
    );

    return suppliers.map((supplier) => (
      <div key={supplier.supplierID} style={{ marginBottom: "15px" }}>
        <Controller
          name={`supplier_${supplier.supplierID}`}
          control={methods.control}
          defaultValue={selectedSuppliersSet.has(supplier.supplierID)}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={field.value}
              onChange={handleCheckboxChange(supplier.supplierID)}
              className="custom-checkbox"
              disabled={isDisabled} // Disable checkboxes based on rfqStatus
            >
              <span style={{ color: "black" }}>{supplier.supplierName}</span>
            </Checkbox>
          )}
        />
      </div>
    ));
  };

  const renderSupplierColumns = (
    suppliers: { supplierName: string; supplierID: string }[]
  ) => {
    // Split the suppliers into groups of 4
    const supplierGroups = [];
    for (let i = 0; i < suppliers.length; i += 4) {
      supplierGroups.push(suppliers.slice(i, i + 4));
    }

    // Check if the rfqStatus requires disabling the checkboxes
    const isDisabled = ["Awarded", "Negotiated", "Quoted"].includes(rfqStatus);

    return (
      <Row style={{ marginBottom: "15px", marginTop: "15px" }}>
        {supplierGroups.map((group, index) => (
          <Col key={index} md="auto">
            {renderSupplierCheckboxes(group, isDisabled)}{" "}
            {/* Pass the `isDisabled` flag */}
          </Col>
        ))}
      </Row>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = OriginalData?.quotationApprovals;
    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "APPROVED";
              const isRejected = approval.status == "REJECTED";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {/* <Badge color="outline-success">Yes</Badge> */}
          Yes
        </span>
      </Popover>
    );
  };

  const billingAddressContent = materialData?.quotationHeader
    ?.billingAddressName ? (
    <div className="billingAddress_align">
      <p>
        {materialData?.quotationHeader?.billingAddressName?.addressLine1},{" "}
        {materialData?.quotationHeader?.billingAddressName?.addressLine2}
      </p>
      <p>
        {materialData?.quotationHeader?.billingAddressName?.city},{" "}
        {
          materialData?.quotationHeader?.billingAddressName?.district
            ?.districtName
        }
        , {materialData?.quotationHeader?.billingAddressName?.state?.stateName},{" "}
        {
          materialData?.quotationHeader?.billingAddressName?.countryInfo
            ?.countryName
        }{" "}
        - {materialData?.quotationHeader?.billingAddressName?.pincode}
      </p>
    </div>
  ) : null;

  const orderDeliveryAddressContent = materialData?.quotationHeader
    ?.deliveryAddressName ? (
    <div className="billingAddress_align">
      <p>
        {materialData?.quotationHeader?.deliveryAddressName?.addressLine1},{" "}
        {materialData?.quotationHeader?.deliveryAddressName?.addressLine2}
      </p>
      <p>
        {materialData?.quotationHeader?.deliveryAddressName?.cityName},{" "}
        {
          materialData?.quotationHeader?.deliveryAddressName?.district
            ?.districtName
        }
        , {materialData?.quotationHeader?.deliveryAddressName?.state?.stateName}
        ,{" "}
        {
          materialData?.quotationHeader?.deliveryAddressName?.countryInfo
            ?.countryName
        }{" "}
        - {materialData?.quotationHeader?.deliveryAddressName?.pincode}
      </p>
    </div>
  ) : null;

  useEffect(() => {
    if (editLineRow) {
      setAddNewRowModel(true);
    }
  }, [editLineRow]);

  const requiredDocsContent = OriginalData?.requiredDocs ? (
    <Table
      dataSource={OriginalData?.requiredDocs?.map(
        (doc: any, index: number) => ({
          key: index + 1,
          sNo: index + 1,
          documentName: doc.documentName,
          mandatory: doc.mandatory ? "Yes" : "No",
        })
      )}
      columns={[
        {
          title: "S.No",
          dataIndex: "sNo",
          key: "sNo",
        },
        {
          title: "Document Name",
          dataIndex: "documentName",
          key: "documentName",
        },
        {
          title: "Mandatory to Upload",
          dataIndex: "mandatory",
          key: "mandatory",
        },
      ]}
      pagination={false}
      size="small"
      style={{ minWidth: "200px" }}
    />
  ) : null;

  const SubmitChanges = async () => {
    try {
      const _formData = new FormData();
      const updatedLineItems: any = [];
      const newLineItems: any = [];
      const deletedLineItems: any = [];

      const newfiledata: any = [];
      const deletedFileData: any = [...deletedAttachments];
      const updatedFileData: any = [];

      materialData.addtionalData.selectedSuppliers = selectedArrayData;

      materialData?.quotationLines?.forEach((data: any) => {
        console.log("data", data);
        if (!data?.quotationLineId) {
          // new item
          console.log("newLINES::::::IFF");
          // newLineItems.push({
          //     materialId: data.materialID || null,
          //     materialName: data.materialName || "",
          //     categoryId: data.categoryID || null,
          //     categoryName: data.categoryName || "",
          //     uomId: data.uomID || null,
          //     quantity: data.qty || "",
          //     needByDate: data.needByDate || "",
          //     deliveryAddressId: data.deliveryAddressID || null,
          //     deliveryDate: data.needByDate || "",
          // });
          if (data?.file && data?.file[0]) {
            data.fileIndex = newfiledata.length;
            const fileLine = new File([data.file[0]], data.file[0].name);
            _formData.append("files", fileLine);
            // _formData.append("files", data?.file[0]);
            newfiledata.push({
              fileIndex: newfiledata.length,
              module: "Quotation",
              moduleFileType: "Special Instructions",
              level: "LINE_LEVEL",
            });
          }
        } else {
          // check for update
          console.log("ELSE::::", data);

          const OData = OriginalData?.quotationLines?.find(
            (item: any) => item.quotationLineId == data.quotationLineId
          );
          console.log("OData", OData);
          let updatedItem: any = {};
          updatedItem.quotationLineId = data.quotationLineId;
          // if (Number(OData.quantity) !== Number(data.quantity)) {
          //     updatedItem.quantity = Number(data.quantity);
          // }

          // if (Number(OData.categoryId) !== Number(data.categoryId)) {
          //     updatedItem.categoryId = Number(data.categoryId);
          // }

          // if (OData.description !== data.description) {
          //     updatedItem.description = data.description;
          // }

          if (Object.keys(updatedItem).length > 0) {
            //     if (OriginalData?.needByDateType == "Line Level") {
            //         updatedItem.needByDate = dayjs(data.needByDate).format(
            //             "YYYY-MM-DD"
            //         );
            //     }
            //     if (OriginalData?.deliveryAddressType == "Line Level") {
            //         updatedItem.deliveryAddressId = Number(data.deliveryAddressId);
            //     }
            //     updatedItem.materialId = Number(data.materialId);
            updatedItem.quotationLineId = data.quotationLineId;
            //     updatedItem.uuid = OData.uuid;
            updatedLineItems.push(updatedItem);
          }

          if (data?.file && data?.file[0]) {
            if (OData?.dms[0]?.docId) {
              deletedFileData.push({
                docId: OData?.dms[0]?.docId,
              });
            }
            data.fileIndex = newfiledata.length;
            newfiledata.push({
              fileIndex: newfiledata.length,
              module: "Quotation",
              moduleFileType: "Special Instructions",
              quotationLineId: OData.quotationLineId,
              level: "LINE_LEVEL",
            });
            console.log("newfiledata");

            console.log("data.file::::", data.file);
            const fileLine = new File([data.file[0]], data.file[0].name);
            // if(fileLine){

            // }
            console.log(
              "new File([data.file[0]], data.file[0].fileName",
              fileLine
            );
            _formData.append("files", fileLine);
          }
        }
      });

      newAttachments.forEach((attachment: any, index: number) => {
        if (!attachment) {
          return;
        }
        console.log("attachment", attachment);
        const file = attachment?.originFileObj;

        _formData.append("files", file);
        newfiledata.push({
          fileIndex: newfiledata.length,
          module: "Quotations",
          moduleFileType: "attachments",
          level: "HEADER_LEVEL",
        });
      });

      const existingLineItems: any = [];
      OriginalData?.quotationLines?.forEach((item: any) => {
        if (item?.quotationLineId) {
          const obj: any = {
            quotationLineId: item.quotationLineId,
            materialId: item.materialId,
          };
          if (OriginalData?.needByDateType == "Line Level") {
            obj.needByDate = item.needByDate;
          }
          if (OriginalData?.deliveryAddressType == "Line Level") {
            obj.deliveryAddressId = item.deliveryAddressId;
          }
          existingLineItems.push(obj);
        }
      });

      if (isInstructionsChanged) {
        const instructionsFile = new Blob([newInstructions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([instructionsFile], "instructions.html", {
            type: "text/html",
          })
        );
        newfiledata.push({
          fileIndex: newfiledata.length,
          module: "Quotations",
          moduleFileType: "instructions",
          level: "HEADER_LEVEL",
        });
        setIsInstructionsChanged(false);
        const _instructions = DMS?.find(
          (item: any) => item.moduleFileType == "instructions"
        );
        if (_instructions?.docId) {
          deletedFileData.push({
            docId: _instructions.docId,
          });
        }
      }
      if (isTermsAndConditionsChanged) {
        const termsAndConditionsFile = new Blob([newTermsAndConditions], {
          type: "text/html",
        });
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        newfiledata.push({
          fileIndex: newfiledata.length,
          module: "Quotations",
          moduleFileType: "termsAndConditions",
          level: "HEADER_LEVEL",
        });
        setIsTermsAndConditionsChanged(false);
        const _termsAndConditions = DMS?.find(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions?.docId) {
          deletedFileData.push({
            docId: _termsAndConditions.docId,
          });
        }
      }

      let lastSubmissionDate = dayjs(
        materialData.quotationHeader?.lastSubmissionDate
      ).format("YYYY-MM-DD");
      materialData.quotationHeader.lastSubmissionDate = lastSubmissionDate;
      const filesData = {
        newfiledata,
        deletedFileData,
        updatedFileData,
      };

      materialData.quotationLines.forEach((item: any) => {
        if (materialData.quotationHeader.deliveryLevelType === "ORDER_LEVEL") {
          item.deliveryAddressId = null;
          item.deliveryAddressName = null;
        }
        if (materialData.quotationHeader.deliveryDateType === "ORDER_LEVEL") {
          item.deliveryDate = null;
        }
      });

      _formData.append("filesData", JSON.stringify(filesData));

      // _formData.append("files", JSON.stringify([]));

      _formData.append(
        "quotationHeader",
        JSON.stringify(materialData.quotationHeader)
      );
      _formData.append(
        "quotationLines",
        JSON.stringify(materialData.quotationLines)
      );
      _formData.append(
        "requiredDocs",
        JSON.stringify(materialData.requiredDocs)
      );
      _formData.append(
        "quotationApprovals",
        JSON.stringify(materialData.quotationApprovals)
      );

      _formData.append(
        "additionalData",
        JSON.stringify(materialData.addtionalData)
      );
      console.log("MaterialDAta::::", materialData);

      // return

      const res = await quotationApis.newVersion(_formData);
      const { status, message }: any = res;
      if (status === true) {
        fetchDetails();
        Swal.fire("Updated!", message, "success");
        navigate("/view-rfqs");
      } else {
        Swal.fire("Error!", message, "error");
      }
    } catch (error) {
      console.log(error);
    }
    setDisableSubmit(false);
  };

  const returnBillingDetails = (OriginalData: any) => {
    if (!OriginalData?.quotationHeader?.billingAddressName) {
      return {};
    }
    let address = OriginalData.quotationHeader.billingAddressName;
    if (!address) {
      return {};
    }
    return {
      name: currentUser?.orgName || "",
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.cityName,
      state: address?.state?.stateName,
      pincode: address.pincode,
      country: address?.countryInfo?.countryName,
      gstin: currentUser?.gstin || "",
      pan: currentUser?.pan || "",
      cin: currentUser?.cin || "",
    };
  };

  const returnSupplierDetails = (OriginalData: any) => {
    let seller = OriginalData?.supplierDetails;
    if (!seller) {
      return {};
    }
    return {
      name: OriginalData?.supplierName || "",
      addressLine1: seller?.address?.area || "",
      addressLine2: seller?.address?.district?.districtName || "",
      city: seller?.address?.city || "",
      state: seller?.address?.state?.stateName || "",
      pincode: seller?.address?.pincode || "",
      country: seller?.address?.country?.countryName || "",
      gstin: seller?.gstin || "",
      pan: seller?.pan || "",
      cin: seller?.cin || "",
    };
  };

  const returnDetails = (lineData: any) => {
    try {
      let address: any = OriginalData?.quotationHeader?.deliveryAddressName;
      return {
        quoteNumber: OriginalData?.quotationHeader?.rfqId,
        quoteDate: dayjs(OriginalData?.quotationHeader?.createdAt).format(
          "DD-MMM-YYYY"
        ),
        terms: "",
        expectedShipmentDate:
          materialData?.deliveryAddressType == "Order Level"
            ? dayjs(materialData?.needByDate).format("DD-MMM-YYYY")
            : "",
        placeofSupply: address?.cityName || "",
        creditDays:
          materialData?.paymentTerms != "Cash and Carry"
            ? materialData?.creditDays
            : 0,
        deliveryAddressString:
          [
            address?.addressLine1,
            address?.addressLine2,
            address?.cityName,
            address?.district?.districtName,
            address?.state?.stateName,
            address?.pincode?.toString(),
            address?.countryInfo?.countryName,
          ]
            ?.filter(Boolean)
            ?.join(", ") || "",
        totals: {
          taxableAmount: formatDecimal(
            materialData?.lineItems?.reduce(
              (a: any, b: any) => a + Number(b.quantity) * Number(b.unitPrice),
              0
            )
          ),
          cgst: formatDecimal(
            materialData?.lineItems?.reduce(
              (a: any, b: any) => a + Number(b.cgst),
              0
            )
          ),
          sgst: formatDecimal(
            materialData?.lineItems?.reduce(
              (a: any, b: any) => a + Number(b.sgst),
              0
            )
          ),
          igst: formatDecimal(
            materialData?.lineItems?.reduce(
              (a: any, b: any) => a + Number(b.igst),
              0
            )
          ),
          total: formatDecimal(
            materialData?.lineItems?.reduce(
              (a: any, b: any) => a + Number(b.grossTotal),
              0
            )
          ),
        },
        lines: lineData?.map((item: any, index: any) => {
          let itemDescription = item?.materialName;
          if (item?.description) {
            itemDescription += "\t" + item?.description;
          }
          if (materialData?.deliveryAddressType != "ORDER_LEVEL") {
            let _address = deliveryAddressList?.find(
              (_item: any) => _item?.id == item?.deliveryAddressId
            );
            if (_address) {
              const addressString = [
                _address.addressLine1,
                _address.addressLine2,
                _address.city,
                _address.stateName,
                _address.pincode?.toString(),
                _address.countryName,
              ]
                .filter(Boolean)
                .join(", ");
              itemDescription += "\n\nDelivery Address: " + addressString;
            }
          }
          return {
            sno: index + 1,
            itemDescription,
            hsnSac: item?.hsnCode || "--",
            rate: Number(item?.unitPrice || 0),
            cgstPercentage: Number(item?.cgstPercentage || 0),
            cgstAmount: Number(item?.cgst || 0),
            sgstPercentage: Number(item?.sgstPercentage || 0),
            sgstAmount: Number(item?.sgst || 0),
            igstPercentage: Number(item?.igstPercentage || 0),
            igstAmount: Number(item?.igst || 0),
            taxableAmount: Number(item?.grossTotal || 0),
            qty: item?.quantity + " " + item?.uomName,
            uom: item?.uomLabel,
          };
        }),
      };
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const handleReject = async (id: string) => {
    setRejectRecord(id);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      // Assuming `record.headerId` is used to identify the purchase order
      const payload = {
        headerId: rejectRecord,
        reason: values.reason,
      };

      // Call the API to submit the rejection
      await apiCallToRejectBudget(rejectRecord, values.reason);

      // Handle success (e.g., show a success message, refresh the table)

      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      // Handle validation errors or API errors
    }
  };

  const apiCallToRejectBudget = async (quotationId: any, reason: string) => {
    // Replace this with your actual API call logic
    try {
      const res = await quotationApis.reject(quotationId, { reason: reason });
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire("Rejected!", "Quotation has been Rejected", "success");
        navigate(-1);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleApprove = async (id: string) => {
    console.log("id", id);
    try {
      const res = await quotationApis.approve(id);
      const { response, status, message } = res;
      console.log("data", res);
      if (status) {
        Swal.fire("Approved!", "Quotation has been Approved", "success");
        navigate(-1);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };
  // console.clear();
  return (
    <>
      <Head title="View Quotation Details" />
      <Modal
        isOpen={generalInformationEdit}
        toggle={() => setGeneralInformationEdit(false)}
        className="modal-dialog-centered"
        size="xl"
        backdrop="static"
      >
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            setGeneralInformationEdit(false);
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>

        <ModalBody>
          <>
            <h5 className="title">Update General Information</h5>
            <div style={{ marginTop: "15px" }}></div>

            <FormProvider {...methods}>
              <form
                className="content clearfix"
                onSubmit={handleSubmit((data) => {
                  console.log("data", data);
                  setMaterialData((pre: any) => {
                    // console.log("pre", pre)
                    let billingAddressDetails: any = billingAddressList?.find(
                      (item: any) => item.id == data?.billingAddress
                    );

                    let deliveryAddressDetails = deliveryAddressList.find(
                      (item: any) => item.id == data?.deliveryAddressId
                    );
                    console.log("DATA:::", billingAddressDetails);
                    // return
                    const newQuotationHeader = {
                      ...pre.quotationHeader, // Keep existing values
                      ...data,
                      lastSubmissionDate: dayjs(data.lastSubmissionDate).format(
                        "DD-MMM-YYYY"
                      ),
                      deliveryDate: dayjs(data.needByDate).format(
                        "DD-MMM-YYYY"
                      ),
                      deliveryDateType: data.deliveryDateType,
                      paymentTerms: data.paymentTerms,
                      billingAddressId: data?.billingAddress,
                      deliveryAddressId: data?.deliveryAddressId,
                      billingAddressName: {
                        deliveryLocationName: billingAddressOptions.find(
                          (item) => item.value == data?.billingAddress
                        )?.label,
                        addressLine1: billingAddressDetails.addressLine1,
                        addressLine2: billingAddressDetails.addressLine2,
                        city: billingAddressDetails.city,
                        district: {
                          districtName: billingAddressDetails.districtName,
                        },
                        state: {
                          stateName: billingAddressDetails.stateName,
                        },
                        countryInfo: {
                          countryName: billingAddressDetails.countryName,
                        },
                        pincode: billingAddressDetails.pincode,
                      },
                      deliveryAddressName: {
                        deliveryLocationName: deliveryAddressOptions.find(
                          (item) => item.value == data?.deliveryAddressId
                        )?.label,
                        addressLine1: deliveryAddressDetails?.addressLine1,
                        addressLine2: deliveryAddressDetails?.addressLine2,
                        city: deliveryAddressDetails?.city,
                        district: {
                          districtName: deliveryAddressDetails?.districtName,
                        },
                        state: {
                          stateName: deliveryAddressDetails?.stateName,
                        },
                        countryInfo: {
                          countryName: deliveryAddressDetails?.countryName,
                        },
                        pincode: deliveryAddressDetails?.pincode,
                      },
                      siteName: sitesOptions.find(
                        (item) => item.value == data?.siteId
                      )?.label,
                      costCenterName: costCenterOptions.find(
                        (item) => item.value == data?.costCenter
                      )?.label,
                      contractorName: contractorOptions.find(
                        (item) => item.value == data?.contractorId
                      )?.label,
                    };

                    console.log("updatedData", newQuotationHeader);

                    const updatedMaterialData = {
                      ...pre, // Keep existing data
                      quotationHeader: newQuotationHeader, // Update quotationHeader
                    };

                    return {
                      ...pre,
                      ...data,
                      ...updatedMaterialData,
                    };
                  });
                  setGeneralInformationEdit(false);
                  setAddNewRowModel(false);
                  setEditLineRow(null);
                  console.log("setMaterialData", materialData);
                  setShowSubmitBtm(true);
                })}
              >
                <Row className="gy-4">
                  <Col lg="4">
                    <FormSelect
                      name="quotationFor"
                      label="Quotation For"
                      options={[
                        { label: "Material", value: "Material" },
                        { label: "Service", value: "Service" },
                      ]}
                      required={true}
                      placeholder="Select Quotation Type"
                    />
                  </Col>
                  <Col lg="8">
                    <FormInput
                      name="quotationDescription"
                      label="Quotation Description"
                      required={true}
                      type="text"
                      minLength={2}
                      maxLength={100}
                      placeholder="Enter Description"
                    />
                  </Col>
                </Row>
                <Row className="gy-4 mt-1">
                  <Col lg="4">
                    <FormSelect
                      name="costCenter"
                      label="Cost Center"
                      options={costCenterOptions}
                      // required={true}
                      placeholder="Select Cost Center"
                    />
                  </Col>
                  <Col lg="4">
                    <FormDatePicker
                      name="lastSubmissionDate"
                      label="Last Submission Date"
                      required={true}
                      placeholder="Select Date"
                      minDate={new Date()}
                    />
                  </Col>
                  <Col lg="4">
                    <FormSelect
                      name="deadlineType"
                      label="Time limit"
                      options={[
                        { label: "Soft", value: "SOFT" },
                        { label: "Hard", value: "HARD" },
                      ]}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>
                </Row>
                <Row className="gy-4 mt-1">
                  <Col lg="4">
                    <FormSelect
                      name="siteId"
                      label="Site Name"
                      options={sitesOptions}
                      required={true}
                      placeholder="Select"
                      disabled={
                        OriginalData?.quotationHeader?.prIds?.length > 0
                          ? true
                          : false
                      }
                    />
                  </Col>
                  <Col lg="4">
                    <FormSelect
                      name="contractorId"
                      label={`Contractor`}
                      options={contractorOptions}
                      placeholder="-Select-"
                      tooltipText="Select the contractor for this purchase order."
                    />
                  </Col>
                  <Col lg="4">
                    <FormSelect
                      name="additionalCharges"
                      label="Additional Charges?"
                      options={[
                        { label: "Fixed", value: "Fixed" },
                        { label: "Flexible", value: "Flexible" },
                      ]}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col lg="4">
                    <FormSelect
                      name="autoRelease"
                      label="Auto Release"
                      defaultValue={{
                        label: "Yes",
                        value: "Y",
                      }}
                      options={[
                        { label: "Yes", value: "Y" },
                        { label: "No", value: "N" },
                      ]}
                      required={true}
                      placeholder="Select"
                    />
                  </Col>
                  <Col lg="4">
                    <FormSelect
                      name="billingAddress"
                      label="Billing Address"
                      options={billingAddressOptions}
                      required={true}
                      placeholder="Select"
                      //   onChange={onBillingAddressChange}
                    />
                    {/* {formState.selectedBillingAddress && (
                                            <Popover content={(
                                                <ul>
                                                    <li>{formState.selectedBillingAddress.addressLine1} {formState.selectedBillingAddress.addressLine2}</li>
                                                    <li>{formState.selectedBillingAddress.cityName}, {formState.selectedBillingAddress.stateName}, {formState.selectedBillingAddress.countryName} - {formState.selectedBillingAddress.pincode}</li>
                                                </ul>
                                            )} title="Selected Address">
                                                <a>View Selected Billing Address</a>
                                            </Popover>
                                        )} */}
                  </Col>
                  <Col lg="4">
                    <FormSelect
                      name="deliveryLevelType"
                      label="Delivery Address at"
                      options={[
                        { label: "Order Level", value: "ORDER_LEVEL" },
                        { label: "Line Level", value: "LINE_LEVEL" },
                      ]}
                      required={true}
                      placeholder="Select"
                      disabled={
                        OriginalData?.quotationHeader?.prIds?.length > 0
                          ? true
                          : false
                      }
                      //   onChange={handleDeliveryAddressTypeChange}
                    />
                  </Col>

                  <Row className="mt-4">
                    {deliveryLevelTypeWatch === "ORDER_LEVEL" && (
                      <>
                        <Col lg="4">
                          <FormSelect
                            name="deliveryAddressId"
                            label="Delivery Address"
                            options={deliveryAddressOptions}
                            required={true}
                            placeholder="Select"
                            disabled={
                              OriginalData?.quotationHeader?.prIds?.length > 0
                                ? true
                                : false
                            }
                            // onChange={handleDeliveryAddressChange}
                          />
                          {/* {formState.selectedDeliveryAddress && (
                                                <Popover content={(
                                                    <ul>
                                                        <li>{formState.selectedDeliveryAddress.addressLine1} {formState.selectedDeliveryAddress.addressLine2}</li>
                                                        <li>{formState.selectedDeliveryAddress.cityName}, {formState.selectedDeliveryAddress.stateName}, {formState.selectedDeliveryAddress.countryName} - {formState.selectedDeliveryAddress.pincode}</li>
                                                    </ul>
                                                )} title="Selected Address">
                                                    <a>View Selected Delivery Address</a>
                                                </Popover>
                                            )} */}
                        </Col>
                      </>
                    )}
                    <Col lg="4">
                      <FormSelect
                        name="deliveryDateType"
                        label="Multiple Delivery Dates ?"
                        options={[
                          { label: "Yes", value: "LINE_LEVEL" },
                          { label: "No", value: "ORDER_LEVEL" },
                        ]}
                        placeholder="-Select-"
                        // tooltipText="delivery date will be based on the line level. If you select 'No' then delivery date will be based on the Order Level."
                        required={true}
                      />
                    </Col>

                    {deliveryDateTypeWatch === "ORDER_LEVEL" && (
                      <Col lg="4">
                        <FormDatePicker
                          name="needByDate"
                          label="Need By Date"
                          required={true}
                          placeholder="Select Date"
                          minDate={new Date()}
                        />
                      </Col>
                    )}
                    <Col lg="4">
                      <FormSelect
                        name="paymentTerms"
                        label="Payment Terms"
                        options={[
                          { label: "Cash & Carry", value: "Cash and Carry" },
                          // { label: "Advance", value: "Advance" },
                          { label: "Credit", value: "Credit" },
                        ]}
                        required={true}
                        placeholder="Select Payment Terms"
                      />
                    </Col>
                  </Row>
                </Row>
                <Row className="mt-4">
                  <Col lg="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          className="btn btn-primary btn-md"
                          type="submit"
                          color="primary"
                        >
                          Save
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#cancel"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setGeneralInformationEdit(false);
                            reset();
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </form>
            </FormProvider>
          </>
        </ModalBody>
      </Modal>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            {isLoading == true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner card-inner-lg">
                {sm && mobileView && (
                  <div
                    className="toggle-overlay"
                    onClick={() => setSm(!sm)}
                  ></div>
                )}
                <BlockHead size="lg">
                  <BlockBetween>
                    <BlockHeadContent>
                      <BlockTitle tag="h4">
                        <span>
                          RFQ - {OriginalData?.quotationHeader?.rfqId} |{" "}
                          {(statusMapper[rfqStatus] &&
                            statusMapper[rfqStatus]?.buyer?.title) ||
                            rfqStatus}{" "}
                        </span>
                      </BlockTitle>
                    </BlockHeadContent>
                    <div className="d-flex">
                      {screenNavigationStatus == "Pending RFQ Approvals" && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableApprove ? (
                                <Button color="success" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="success"
                                  type="button"
                                  onClick={() => {
                                    setDisableApprove(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, Approve it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleApprove(
                                          materialData?.quotationHeader
                                            ?.quotationId
                                        );
                                      } else {
                                        setDisableApprove(false);
                                      }
                                    });
                                  }}
                                >
                                  Approve
                                </Button>
                              )}
                            </li>
                            <li className="pt-0 pb-0">
                              {disableReject ? (
                                <Button color="danger" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="danger"
                                  onClick={() => {
                                    setDisableReject(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "You won't be able to revert this!",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, Reject it!",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        handleReject(
                                          materialData?.quotationHeader
                                            ?.quotationId
                                        );
                                      } else {
                                      }
                                    });
                                  }}
                                  type="button"
                                  className="btn-dim btn btn-secondary"
                                >
                                  Reject
                                </Button>
                              )}
                            </li>
                          </ul>
                        </div>
                      )}
                      {showSubmitBtm && (
                        <div className="actions clearfix">
                          <ul
                            className="m-auto d-flex align-items-center justify-content-center p-0"
                            style={{
                              flexDirection: "row-reverse",
                            }}
                          >
                            <li className="pt-0 pb-0">
                              {disableSubmit ? (
                                <Button color="primary" type="button">
                                  <Spinner size="sm" color="light" />
                                </Button>
                              ) : (
                                <Button
                                  color="primary"
                                  type="button"
                                  onClick={() => {
                                    setDisableSubmit(true);
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Do you want to submit the changes?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, submit it!",
                                      cancelButtonText: "No, cancel",
                                    }).then(async (result) => {
                                      if (result.isConfirmed) {
                                        await SubmitChanges();
                                      } else {
                                        setDisableSubmit(false);
                                      }
                                    });
                                  }}
                                >
                                  Save Changes
                                </Button>
                              )}
                            </li>
                            <li className="pt-0 pb-0">
                              <Button
                                color="secondary"
                                onClick={() => {
                                  Swal.fire({
                                    title: "Are you sure?",
                                    text: "Do you want to revert the changes?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Yes, revert it!",
                                    cancelButtonText: "No, cancel",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      fetchDetails();
                                      Swal.fire(
                                        "Reverted!",
                                        "Your changes have been reverted.",
                                        "success"
                                      );
                                    }
                                  });
                                }}
                                type="button"
                                className="btn-dim btn btn-secondary"
                              >
                                Revert Changes
                              </Button>
                            </li>
                          </ul>
                        </div>
                      )}
                      {!showSubmitBtm && (
                        <div className="paddingLeft10">
                          <Button
                            type="button"
                            color=""
                            onClick={async () => {
                              const { pdfUrl, pdfBlob }: any =
                                await PdfGenerator(
                                  returnBillingDetails(OriginalData),
                                  returnSupplierDetails(OriginalData),
                                  returnDetails(data),
                                  currentUser?.orgLogo,
                                  false,
                                  termsAndConditions
                                );
                                console.clear()
                                console.log("pdfUrl", pdfUrl);
                                
                              setPdfBlobData({ current: pdfBlob, url: pdfUrl });
                              setShowPdfModal(true);
                            }}
                          >
                            PDF <Icon name="file-pdf" />
                          </Button>
                        </div>
                      )}
                      <li className="pt-0 pb-0 p-2">
                        <Dropdown
                          isOpen={shareOptionsToggle}
                          toggle={() => {
                            setShareOptionsToggle(!shareOptionsToggle);
                          }}
                        >
                          <DropdownToggle className="btn btn-white btn-outline-light">
                            <Icon name="share"></Icon>
                            <span>Send</span>
                          </DropdownToggle>
                          <DropdownMenu>
                            {/* <DropdownItem onClick={() => {}}>
                                    <Icon name="archive"></Icon>
                                    <span>Send SMS</span>
                                  </DropdownItem> */}
                            <DropdownItem
                              onClick={() => {
                                setShowEmailModal(true);
                              }}
                            >
                              <Icon name="mail"></Icon>
                              <span>Send Mail</span>
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </li>

                      {!showSubmitBtm && (
                        <div className="paddingLeft10">
                          <Button
                            className="toggle"
                            color="primary"
                            onClick={async () => {
                              navigate(-1);
                            }}
                          >
                            <Icon name="arrow-left" />
                            <span>Back</span>
                          </Button>
                        </div>
                      )}
                    </div>
                    <BlockHeadContent className="align-self-start d-lg-none">
                      <Button
                        className={`toggle btn btn-icon btn-trigger mt-n1 ${
                          sm ? "active" : ""
                        }`}
                        onClick={() => setSm(!sm)}
                      >
                        <Icon name="menu-alt-r" />
                      </Button>
                    </BlockHeadContent>
                  </BlockBetween>
                </BlockHead>
                <div className="content clearfix">
                  <AntCollapse defaultActiveKey={["1"]}>
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <span>General Details</span>
                          {/* {rfqStatus} */}
                          {!["Awarded", "Negotiated", "Quoted"].includes(
                            rfqStatus
                          ) && (
                            <span
                              className="cursor-pointer position-absolute"
                              style={{
                                right: 20,
                                top: 10,
                                fontSize: "18px",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                setValue(
                                  "quotationFor",
                                  materialData?.quotationHeader.quotationFor
                                );
                                setValue(
                                  "paymentTerms",
                                  materialData?.quotationHeader.paymentTerms
                                );
                                setValue(
                                  "quotationDescription",
                                  materialData?.quotationHeader
                                    .quotationDescription
                                );
                                setValue(
                                  "costCenter",
                                  materialData?.quotationHeader?.costCenter
                                );
                                setValue(
                                  "lastSubmissionDate",
                                  dayjs(
                                    materialData?.quotationHeader
                                      ?.lastSubmissionDate
                                  )
                                );
                                setValue(
                                  "deadlineType",
                                  materialData?.quotationHeader?.deadlineType
                                );
                                setValue(
                                  "additionalCharges",
                                  materialData?.quotationHeader
                                    ?.additionalCharges
                                );
                                setValue(
                                  "autoRelease",
                                  materialData?.quotationHeader?.autoRelease
                                );
                                setValue(
                                  "billingAddress",
                                  materialData?.quotationHeader
                                    ?.billingAddressId
                                );
                                setValue(
                                  "deliveryLevelType",
                                  materialData?.quotationHeader
                                    ?.deliveryLevelType
                                );
                                setValue(
                                  "deliveryDateType",
                                  materialData?.quotationHeader
                                    ?.deliveryDateType
                                );
                                setValue(
                                  "deliveryAddressId",
                                  materialData?.quotationHeader
                                    ?.deliveryAddressId
                                );
                                setValue(
                                  "siteId",
                                  materialData?.quotationHeader?.siteId
                                );
                                setValue(
                                  "needByDate",
                                  dayjs(
                                    materialData?.quotationHeader?.deliveryDate
                                  )
                                );
                                setGeneralInformationEdit(true);
                                e.stopPropagation();
                              }}
                            >
                              <Icon name="edit" />
                            </span>
                          )}
                        </div>
                      }
                      key="1"
                    >
                      <Descriptions
                        title=""
                        className="custom-description-title"
                      >
                        <Descriptions.Item label="Quotation For">
                          {materialData?.quotationHeader?.quotationFor}
                        </Descriptions.Item>
                        <Descriptions.Item label="Description">
                          {materialData?.quotationHeader?.quotationDescription}
                        </Descriptions.Item>
                        <Descriptions.Item label="Cost Center">
                          {materialData?.quotationHeader?.costCenterName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last Submission Date">
                          <span className="badge-dim badge bg-primary rounded-pill">
                            <strong>
                              {dayjs(
                                materialData?.quotationHeader
                                  ?.lastSubmissionDate
                              ).format("DD-MMM-YYYY")}
                            </strong>
                          </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="Time Limit">
                          {materialData?.quotationHeader?.deadlineType}
                        </Descriptions.Item>
                        <Descriptions.Item label="Site Name">
                          {materialData?.quotationHeader?.siteName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Contractor">
                          {materialData?.quotationHeader?.contractorName}
                        </Descriptions.Item>
                        <Descriptions.Item label="Approvals">
                          {<ApprovalTimelineContent />}
                        </Descriptions.Item>
                        <Descriptions.Item label="Additional Charges">
                          {" "}
                          {materialData?.quotationHeader?.additionalCharges}
                        </Descriptions.Item>
                        <Descriptions.Item label="Auto Release">
                          {materialData?.quotationHeader?.autoRelease === "Y"
                            ? "Yes"
                            : "No"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Billing Address">
                          <Popover
                            content={billingAddressContent}
                            title={
                              <span style={{ color: "#2263b3" }}>
                                Billing Address
                              </span>
                            }
                          >
                            <span
                              style={{
                                color: "#2263b3",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {
                                materialData?.quotationHeader
                                  ?.billingAddressName?.deliveryLocationName
                              }
                            </span>
                          </Popover>
                        </Descriptions.Item>
                        <Descriptions.Item label="Delivery Address Type">
                          {materialData?.quotationHeader?.deliveryLevelType ===
                          "LINE_LEVEL"
                            ? "Line Level"
                            : "Order Level"}
                        </Descriptions.Item>
                        <Descriptions.Item label="Documents Required From Supplier">
                          {materialData?.quotationHeader
                            ?.supplierDocsRequired === "Y" ? (
                            <Popover
                              content={requiredDocsContent}
                              title={
                                <span style={{ color: "#2263b3" }}>
                                  Required Documents
                                </span>
                              }
                            >
                              <span
                                style={{
                                  color: "#2263b3",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                Yes
                              </span>
                            </Popover>
                          ) : (
                            "No"
                          )}
                        </Descriptions.Item>
                        {materialData?.quotationHeader?.deliveryLevelType ===
                          "ORDER_LEVEL" && (
                          <>
                            <Descriptions.Item label="Delivery Address">
                              <Popover
                                content={orderDeliveryAddressContent}
                                title={
                                  <span style={{ color: "#2263b3" }}>
                                    Delivery Address
                                  </span>
                                }
                              >
                                <span
                                  style={{
                                    color: "#2263b3",
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  }}
                                >
                                  {
                                    materialData?.quotationHeader
                                      ?.deliveryAddressName
                                      ?.deliveryLocationName
                                  }
                                </span>
                              </Popover>
                            </Descriptions.Item>
                          </>
                        )}
                        <Descriptions.Item label="Multiple Delivery Dates">
                          {materialData?.quotationHeader?.deliveryDateType ===
                          "LINE_LEVEL"
                            ? "Yes"
                            : "No"}
                        </Descriptions.Item>
                        {materialData?.quotationHeader?.deliveryDateType ===
                          "ORDER_LEVEL" && (
                          <Descriptions.Item label="Need By Date">
                            <span className="badge-dim badge bg-primary rounded-pill">
                              <strong>
                                {dayjs(
                                  materialData?.quotationHeader?.deliveryDate
                                ).format("DD-MMM-YYYY")}
                              </strong>
                            </span>
                          </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Payment Terms">
                          {materialData?.quotationHeader?.paymentTerms}
                        </Descriptions.Item>
                      </Descriptions>
                    </Panel>
                    <Panel header="Materials" key="2">
                      <Table
                        columns={filteredColumns}
                        dataSource={data.map((item: any, index: any) => ({
                          ...item,
                          key: index,
                        }))}
                        scroll={{ x: 1000 }}
                        expandable={{
                          expandedRowRender: (record: any) => (
                            <div className="expandableBody">
                              {/* {record} */}
                              <div style={{ flex: 1, textAlign: "left" }}>
                                <p style={{ margin: 0 }}>
                                  {record.description}
                                </p>
                                {record.attachment && (
                                  <div>
                                    <PaperClipOutlined /> &nbsp;
                                    {record?.file[0]?.name ? (
                                      <a
                                        href={URL.createObjectURL(
                                          record.file[0]
                                        )}
                                        download={record.file[0]?.name}
                                      >
                                        {record.file[0]?.name}.
                                      </a>
                                    ) : (
                                      <a
                                        href={record?.file?.filePath}
                                        download={record?.file?.filePath}
                                        target="_blank"
                                      >
                                        {record?.file?.fileName}
                                      </a>
                                    )}
                                  </div>
                                )}
                              </div>
                              {materialData?.quotationHeader
                                ?.deliveryLevelType === "LINE_LEVEL" &&
                                record.deliveryAddressName && (
                                  <div
                                    className="lineAddress"
                                    style={{ flex: 1 }}
                                  >
                                    <h6>Delivery Address</h6>
                                    <ul>
                                      <li>
                                        {
                                          record.deliveryAddressName
                                            .addressLine1
                                        }
                                      </li>
                                      <li>
                                        {
                                          record.deliveryAddressName
                                            .addressLine2
                                        }
                                      </li>
                                      <li>
                                        {record.deliveryAddressName.city},{" "}
                                        {
                                          record.deliveryAddressName.state
                                            .stateName
                                        }
                                        ,{" "}
                                        {
                                          record.deliveryAddressName.countryInfo
                                            .countryName
                                        }{" "}
                                        - {record.deliveryAddressName.pincode}
                                      </li>
                                    </ul>
                                  </div>
                                )}
                            </div>
                          ),
                        }}
                        bordered
                        size="small"
                        pagination={false}
                        locale={{
                          emptyText: (
                            <span
                              style={{
                                height: "300px",
                                color: "#333",
                                fontSize: "16px",
                              }}
                            >
                              Please click on "Add New Item" to add purchase
                              order lines.
                            </span>
                          ),
                        }}
                      />

                      {data?.length > 0 && (
                        <Row
                          style={{
                            margin: "20px",
                          }}
                        >
                          <Col
                            className={`${
                              !["Pending", "Draft", "Requested"].includes(
                                rfqStatus
                              )
                                ? "justify-between"
                                : "justify-end"
                            }`}
                          >
                            {["Pending", "Draft", "Requested"].includes(
                              rfqStatus
                            ) && (
                              <a
                                onClick={() => {
                                  setAddNewRowModel(true);
                                  setEditLineRow(null);
                                }}
                                href="#add"
                                className="addNewItem"
                              >
                                <Icon name="plus-round" />
                                <span>Add New Item</span>
                              </a>
                            )}
                          </Col>
                        </Row>
                      )}
                    </Panel>
                    <Panel header="Other Information" key="3">
                      <Descriptions
                        title="Other Information"
                        className="custom-description-title"
                      >
                        <Descriptions.Item label="Instructions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setInstructionsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="Terms and Conditions">
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                            onClick={() => setTermsVisible(true)}
                          >
                            View
                          </span>
                        </Descriptions.Item>

                        <Descriptions.Item label="">
                          <div>
                            <strong className="">
                              Attachments &nbsp;
                              <PaperClipOutlined />
                            </strong>
                            <div>
                              {attachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>{index + 1}.</strong>
                                      &nbsp;
                                      {attachment.fileName}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                      &nbsp; &nbsp;
                                      {[
                                        "Pending",
                                        "Draft",
                                        "Requested",
                                      ].includes(rfqStatus) && (
                                        <a
                                          style={{
                                            color: "red",
                                            fontSize: "16px",
                                          }}
                                          onClick={() => {
                                            setDeletedAttachments(
                                              (prev: any) => [
                                                ...prev,
                                                { docId: attachment?.docId },
                                              ]
                                            );
                                            let updatedAttachmentsList =
                                              attachments?.filter(
                                                (item: any, i: number) =>
                                                  i != index
                                              );
                                            setAttachments(
                                              updatedAttachmentsList
                                            );
                                            setShowSubmitBtm(true);
                                          }}
                                        >
                                          <DeleteOutlined />
                                        </a>
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                              {newAttachments?.map(
                                (attachment: any, index: number) => (
                                  <div
                                    key={index}
                                    className="w-100 d-flex justify-content-between"
                                  >
                                    <div>
                                      <strong>
                                        {attachments?.length + index + 1}.
                                      </strong>
                                      &nbsp;
                                      {attachment?.name}
                                      &nbsp; &nbsp; &nbsp; &nbsp;
                                    </div>
                                    <div>
                                      <a
                                        href={attachment.link}
                                        download={attachment.link}
                                        style={{
                                          color: "black",
                                          fontSize: "18px",
                                        }}
                                      >
                                        <DownloadOutlined />
                                      </a>
                                      &nbsp; &nbsp;
                                      <a
                                        style={{
                                          color: "red",
                                          fontSize: "16px",
                                        }}
                                        onClick={() => {
                                          setNewAttachments((prev: any) =>
                                            prev.filter(
                                              (item: any, i: number) =>
                                                i != index
                                            )
                                          );
                                          setShowSubmitBtm(true);
                                        }}
                                      >
                                        <DeleteOutlined />
                                      </a>
                                    </div>
                                  </div>
                                )
                              )}
                              {["Pending", "Draft", "Requested"].includes(
                                rfqStatus
                              ) && (
                                <div className="mt-1">
                                  <Dragger
                                    beforeUpload={beforeUpload}
                                    fileList={[]}
                                    onChange={handleAttachmentChange}
                                  >
                                    <a
                                      onClick={() => {}}
                                      className="addNewItem"
                                    >
                                      <Icon name="plus-round" />
                                      <span>Add Attachment</span>
                                    </a>
                                  </Dragger>
                                </div>
                              )}
                            </div>
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item>
                          <div>
                            <strong
                              style={{ color: "#333", fontWeight: "bold" }}
                            >
                              Suppliers : &nbsp;
                            </strong>
                            <div>{renderSupplierColumns(suppliers)}</div>
                          </div>
                        </Descriptions.Item>
                        {/* {suppliers &&
                                                    <Descriptions.Item label="Suppliers">
                                                        {suppliers.map((item) => (
                                                            <li>
                                                                {item.supplierName}
                                                            </li>
                                                        )
                                                        )}
                                                    </Descriptions.Item>
                                                } */}
                      </Descriptions>
                    </Panel>
                  </AntCollapse>
                </div>

                {showEmailModal && (
                  <EmailCreationModal
                    modal={showEmailModal}
                    toggle={() => setShowEmailModal(!showEmailModal)}
                    sendEmail={TriggerEmail}
                    suppliers={formatedSuppliersList}
                    selectedArrayData={selectedArrayData}
                    body={
                      <>
                        <p>
                          We are reaching out to request a quotation for the
                          supply of the following items. Attached, please find
                          the full details of our requirements, including
                          specifications and quantities.
                        </p>

                        <p>
                          <strong>Quotation Request Details:</strong>
                        </p>
                        <p>
                          <strong>Quotation Number:</strong>{" "}
                          {OriginalData?.quotationHeader?.rfqId}
                          <br />
                          {/* <strong>Request Date:</strong>{" "} */}
                          {/* {dayjs(
                              materialData?.quotationHeader?.deliveryDate
                            )?.format("DD-MMM-YYYY")} */}
                          {/* <br /> */}
                          <strong>Quotation Date : </strong>{" "}
                          {dayjs(
                            OriginalData?.quotationHeader?.createdAt
                          ).format("DD-MMM-YYYY")}
                          <br />
                          <strong>Last Submission Date:</strong>{" "}
                          {dayjs(
                            materialData?.quotationHeader?.lastSubmissionDate
                          )?.format("DD-MMM-YYYY")}
                          <br />
                          {/* <strong>Need by Date:</strong>{" "}
                            {dayjs(
                                  materialData?.quotationHeader?.deliveryDate
                                ).format("DD-MMM-YYYY")}
                            <br/> */}
                          <strong>Required by:</strong>{" "}
                          {dayjs(
                            materialData?.quotationHeader?.deliveryDate
                          )?.format("DD-MMM-YYYY")}
                          <br />
                          <strong>Delivery Location:</strong>{" "}
                          {returnDetails(data)?.deliveryAddressString}
                        </p>

                        <p>
                          We would appreciate it if you could provide your best
                          offer, including any applicable discounts, by the
                          requested deadline.
                        </p>

                        <p>
                          For assistance, feel free to reach out to us at{" "}
                          <a href={`mailto:{${currentUser?.email}}}`}>
                            {currentUser?.email}
                          </a>
                          .
                        </p>
                        <p>
                          This is an automatically generated email. Please do
                          not reply directly to this email.
                        </p>
                      </>
                    }
                    defaultAttachments={Object.assign({}, pdfBlobData.current, {
                      name: OriginalData?.quotationHeader?.rfqId + ".pdf",
                      type: "application/pdf",
                    })}
                    orgDetails={
                      {
                        orgLogo:currentUser?.orgLogo,
                        orgName:currentUser?.orgName,
                      }
                    }
                  />
                )}

                {addNewRowModel && (
                  <MaterialModal
                    modal={addNewRowModel}
                    closeModal={() => {
                      setAddNewRowModel(false);
                      setEditLineRow(null);
                    }}
                    status={rfqStatus}
                    formData={{
                      action: editLineRow == null ? "add" : "edit",
                      index: editLineRow?.quotationLineId,
                      item: editLineRow,
                    }}
                    append={(data: any) => {
                      console.log("append");
                      let newLineItems = materialData?.quotationLines;
                      const _data = newLineItems.find((item: any) => {
                        if (
                          item.materialId == data.materialId
                          // &&
                          // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                          // dayjs(data.needByDate).format("DD-MMM-YYYY") &&
                          // item.deliveryAddressId == data.deliveryAddressId
                        )
                          return true;
                      });

                      if (_data) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "Material Already Added",
                        });
                        return;
                      }

                      setMaterialData((pre: any) => {
                        let newData = { ...pre };
                        newData?.quotationLines?.push(data);
                        console.log("addNEW::DATA", newData);
                        return newData;
                      });
                      setData((pre: any) => {
                        return [...pre, data];
                      });
                      setShowSubmitBtm(true);
                    }}
                    update={(id: any, data: any) => {
                      console.log("update", data, id);
                      let newLineItems = materialData?.quotationLines;
                      console.log("newLineItems", newLineItems);
                      const _data = newLineItems
                        .filter((item: any) => item.quotationLineId != id)
                        .find((item: any) => {
                          if (
                            item.materialId == data.materialId
                            // &&
                            // dayjs(item.needByDate).format("DD-MMM-YYYY") ==
                            // dayjs(data.needByDate).format(
                            //     "DD-MMM-YYYY"
                            // ) &&
                            // item.deliveryAddressId == data.deliveryAddressId
                          )
                            return true;
                        });
                      console.log("_dataaa", _data);
                      if (_data) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: " Material Already Added",
                        });
                        return;
                      }

                      newLineItems = newLineItems.map((item: any) => {
                        if (item.quotationLineId == id) {
                          return data;
                        }
                        return item;
                      });
                      console.log("newLineItems", newLineItems);
                      setMaterialData((pre: any) => {
                        let newData = { ...pre, quotationLines: newLineItems };
                        console.log("newData", newData);
                        return newData;
                      });
                      setData(newLineItems);
                      setShowSubmitBtm(true);
                    }}
                    needByDateType={
                      materialData?.quotationHeader?.deliveryDateType
                    }
                    deliveryAddressType={
                      materialData?.quotationHeader?.deliveryLevelType
                    }
                  />
                )}
              </div>
            )}
          </div>
        </Card>
      </Content>
      <Modal
        isOpen={showPdfModal}
        toggle={() => setShowPdfModal(false)}
        className="modal-dialog-centered"
        size={"xl"}
      >
        <ModalHeader toggle={() => setShowPdfModal(false)}>
          Quotation Preview
        </ModalHeader>
        <ModalBody>
          <iframe
            src={pdfBlobData?.url}
            style={{
              width: "100%",
              height: "80vh",
              border: "none",
            }}
            title="PDF Viewer"
          ></iframe>
        </ModalBody>
      </Modal>
      <AntModal
        title={undefined}
        visible={instructionsVisible}
        footer={null}
        onCancel={() => {
          setIsInstructionsChanged(false);
          setInstructionsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Instructions </h5>
          {!isInstructionsChanged &&
            ["Pending", "Draft", "Requested"].includes(rfqStatus) && (
              <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
            )}
        </div>
        {["Pending", "Draft", "Requested"].includes(rfqStatus) &&
        isInstructionsChanged ? (
          <FormProvider {...methods}>
            <form>
              <div className="mt-1 mb-3">
                <FormTextArea
                  name="instructions"
                  label=""
                  placeholder="Enter Instructions"
                  required
                  rows={10}
                  defaultValue={newInstructions}
                />
              </div>
            </form>
          </FormProvider>
        ) : instructions ? (
          <pre className="m-3">{instructions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Instructions Available for this Purchase Order" />
          </div>
        )}
        {isInstructionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setInstructionsVisible(false);
                  setIsInstructionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsInstructionsChanged(false);
                  setNewInstructions(instructions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
      <AntModal
        title={undefined}
        visible={termsVisible}
        footer={null}
        onCancel={() => {
          setIsTermsAndConditionsChanged(false);
          setTermsVisible(false);
        }}
        width="60%"
        bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
      >
        <div className="d-flex">
          <h5 className="m-3">Terms and Conditions</h5>
          {!isTermsAndConditionsChanged &&
            ["Pending", "Draft", "Requested"].includes(rfqStatus) && (
              <EditOutlined
                onClick={() => setIsTermsAndConditionsChanged(true)}
              />
            )}
        </div>
        {["Pending", "Draft", "Requested"].includes(rfqStatus) &&
        isTermsAndConditionsChanged ? (
          <FormProvider {...methods}>
            <form>
              <div className="mt-1 mb-3">
                <FormTextArea
                  defaultValue={newTermsAndConditions}
                  name="termsAndConditions"
                  label=""
                  placeholder="Enter Terms and Conditions"
                  required
                  rows={10}
                />
              </div>
            </form>
          </FormProvider>
        ) : termsAndConditions ? (
          <pre className="m-3">{termsAndConditions || ""}</pre>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            <Empty description="No Terms and Conditions Available for this Quotation" />
          </div>
        )}

        {isTermsAndConditionsChanged && (
          <ul className="actions clearfix d-flex">
            <li>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setTermsVisible(false);
                  setIsTermsAndConditionsChanged(true);
                  setShowSubmitBtm(true);
                }}
              >
                Submit
              </Button>
            </li>
            <li className="m-3 mt-0">
              <Button
                color="primary"
                onClick={() => {
                  setIsTermsAndConditionsChanged(false);
                  setNewTermsAndConditions(termsAndConditions);
                }}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        )}
      </AntModal>
      <AntModal
        title="Reject Budget"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Submit"
        cancelText="Cancel"
      >
        <Form form={form} layout="vertical" name="rejectForm">
          <Form.Item
            name="reason"
            label="Reason for Rejection"
            rules={[{ required: true, message: "Please input your reason!" }]}
          >
            <Input.TextArea placeholder="Type your reason here..." />
          </Form.Item>
        </Form>
      </AntModal>
    </>
  );
};

export default ViewDetailsEdit;
