import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon, TooltipComponent } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import AntSelect from "../form-components/AntSelectComponent";
import { Popover } from "antd";
import adminOrgApis from "../../api/master/adminOrg";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import misc from "../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import moment from "moment";
import supplierApis from "../../api/master/supplier";
import materialsApis from "../../api/master/materials";
interface SelectOptions {
  label: string;
  value: any;
}

type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}
const TAX_SLAP_DROPDOWN = [
  {
    label: "0%",
    value: "0",
  },
  {
    label: "5%",
    value: "1",
  },
  {
    label: "12%",
    value: "2",
  },
  {
    label: "18%",
    value: "3",
  },
  {
    label: "28%",
    value: "4",
  },
];
const MaterialModal: FC<any> = ({
  modal,
  closeModal,
  append,
  update,
  formData,
  taxConfiguration,
  needByDateType,
  deliveryAddressType,
  status,
}) => {
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
  const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
  const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);

  const methods = useForm({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
    setValue,
  } = methods;

  const [alertInfo, setAlertInfo] = useState({
    type: "", // 'success' or 'error'
    message: "",
    errors: [],
  });

  const files = watch("attachment");

  useEffect(() => {
    if (files && files[0]?.size && files[0].size > 2097152) {
      setValue("attachment", null);
      setAlertInfo({
        type: "error",
        message: "File size should be less than 2 MB",
        errors: [],
      });
    }
  }, [files]);

  const materialCategory = watch("materialCategory");
  const deliveryAddressId = watch("deliveryAddressId");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setAlertInfo({
        type: "",
        message: "",
        errors: [],
      });
    }, 2500);
    return () => clearTimeout(timeoutId);
  }, [alertInfo]);

  const fetchDeliveryAddress = async () => {
    try {
      const { data, status, message } =
        await deliveryAddressApis.fetchDeliveryAddress();

      if (status) {
        let deliveryAddressOptions = data.map((item: any) => {
          return {
            label: item.deliveryLocationName,
            value: item.id.toString(),
          };
        });
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      } else {
        console.log(message);
      }
    } catch (error) {}
  };

  const fetchUOMOPtions = async () => {
    try {
      const { data, status, message } = await adminOrgApis.fetchUom();
      if (status) {
        setUomOptions(
          data.map((item: any) => {
            return {
              label: item.uom_name,
              value: item.id.toString(),
            };
          })
        );
      } else {
        console.log(message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCategorys = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const categoryOption = data.map((item: any) => ({
          label: item.category,
          value: item.id.toString(),
        }));
        setCategoryOptions(categoryOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchMaterials = async () => {
    try {
      const { data, status } = await materialsApis.fetchProduct();
      if (status) {
        setMaterialCategoryOptions(data);
        const materialOption = data.map((item: any) => ({
          label: item.productName,
          value: item.materialID.toString(),
        }));
        setMaterialOptions(materialOption);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);
  useEffect(() => {
    if (modal && formData.item) {
      console.log(formData?.item);

      const {
        materialCategory,
        materialName,
        categoryId,
        materialId,
        hsnCode,
        quantity,
        uomId,
        unitPrice,
        cgstPercentage,
        sgstPercentage,
        igstPercentage,
        deliveryAddressId,
        description,
        needByDate,
        file,
        changeRequestUnitPrice,
        changeRequestNeedByDate,
        changeRequestQuantity,
      } = formData.item;
      console.log(formData.item);
      setValue("materialCategory", categoryId);
      setValue("materialName", materialId);
      setValue("hsnCode", hsnCode);
      setValue("uomId", uomId);
      setValue("description", description);
      setValue("attachment", file);
      setValue("quantity", Number(quantity));
      setValue("unitPrice", Number(unitPrice));
      // if (formData.index != null) {
      setValue("deliveryAddressId", deliveryAddressId);
      setValue("needByDate", dayjs(needByDate));
      // }
      if (taxConfiguration?.gstType == "IGST") {
        const slab = TAX_SLAP_DROPDOWN.find(
          (item: any) => item.label == igstPercentage + "%"
        );
        setValue("igst", slab?.value);
      } else {
        const slab = TAX_SLAP_DROPDOWN.find(
          (item: any) =>
            item.label == Number(cgstPercentage) + Number(sgstPercentage) + "%"
        );
        setValue("cgst", slab?.value);
        setValue("sgst", slab?.value);
      }
    }
  }, [modal]);

  const submitForm = (data: any) => {
    const {
      materialCategory,
      materialName,
      hsnCode,
      quantity,
      unitPrice,
      igst,
      cgst,
      description,
      uomId,
      needByDate,
      attachment,
      deliveryAddressId,
    } = data;
    let _cess: Cess[] = [];
    let cessTotal = 0;
    taxConfiguration?.cess?.forEach((cessItem: any, index: any) => {
      if (!cessItem.cessName) return;
      const cessPercentage = data[cessItem.cessName];
      const cessAmount = (cessPercentage * quantity * unitPrice) / 100;
      cessTotal += cessAmount;
      _cess.push({
        cessName: cessItem.cessName,
        cessType: cessItem.cessType,
        cessPercentage,
        cessAmount: cessAmount?.toString(),
      });
    });
    const item = {
      materialName,
      hsnCode,
      quantity,
      unitPrice,
      amount: quantity * unitPrice,
      description,
      igst: 0,
      igstPercentage: 0,
      igstAmount: 0,
      cgst: 0,
      cgstPercentage: 0,
      cgstAmount: 0,
      sgst: 0,
      sgstPercentage: 0,
      sgstAmount: 0,
      lineTotal: 0,
    };
    item.igst = 0;
    item.igstAmount = 0;
    item.igstPercentage = 0;
    item.cgst = 0;
    item.cgstAmount = 0;
    item.cgstPercentage = 0;
    item.sgst = 0;
    item.sgstAmount = 0;
    item.sgstPercentage = 0;
    let selectedTax;
    switch (taxConfiguration?.taxType) {
      case "IGST":
        item.igst = igst;
        selectedTax = TAX_SLAP_DROPDOWN.find(
          (dropdownItem) => dropdownItem.value === igst.toString()
        );
        if (selectedTax) {
          const igstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
          item.igstPercentage = igstRate;
          item.igstAmount = (igstRate * item.amount) / 100;
        } else {
          item.igstPercentage = 0;
          item.igstAmount = 0;
        }
        break;
      case "CGST":
        item.cgst = cgst;
        item.sgst = cgst;
        selectedTax = TAX_SLAP_DROPDOWN.find(
          (dropdownItem) => dropdownItem.value === cgst.toString()
        );

        if (selectedTax) {
          const cgstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
          item.cgstPercentage = cgstRate / 2;
          item.sgstPercentage = cgstRate / 2;
          item.cgstAmount = (item.cgstPercentage * item.amount) / 100;
          item.sgstAmount = (item.sgstPercentage * item.amount) / 100;
        } else {
          item.cgstPercentage = 0;
          item.sgstPercentage = 0;
          item.cgstAmount = 0;
          item.sgstAmount = 0;
        }
        break;
      default:
        break;
    }

    if (igst) {
      item.igst = igst;
      const selectedTax = TAX_SLAP_DROPDOWN.find(
        (dropdownItem) => dropdownItem.value === igst.toString()
      );
      if (selectedTax) {
        const igstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
        item.igstPercentage = igstRate;
        item.igstAmount = (igstRate * item.amount) / 100;
      } else {
        item.igstPercentage = 0;
        item.igstAmount = 0;
      }
    }
    if (cgst) {
      item.cgst = cgst;
      item.sgst = cgst;
      const selectedTax = TAX_SLAP_DROPDOWN.find(
        (dropdownItem) => dropdownItem.value === cgst.toString()
      );

      if (selectedTax) {
        const cgstRate = parseFloat(selectedTax.label.replace("%", "")) || 0;
        item.cgstPercentage = cgstRate / 2;
        item.sgstPercentage = cgstRate / 2;
        item.cgstAmount = (item.cgstPercentage * item.amount) / 100;
        item.sgstAmount = (item.sgstPercentage * item.amount) / 100;
      } else {
        item.cgstPercentage = 0;
        item.sgstPercentage = 0;
        item.cgstAmount = 0;
        item.sgstAmount = 0;
      }
    }

    item.lineTotal =
      item.amount +
      item.igstAmount +
      item.cgstAmount +
      item.sgstAmount +
      cessTotal;

    
    const materialLabel = materialOptions.find(
      (item) => item.value == materialName
    )?.label;
    const uomLabel = uomOptions.find((item) => item.value == uomId)?.label;
    const formResult = {
      categoryId: materialCategory,
      materialId: materialName,
      materialName: materialLabel,
      hsnCode,
      uomId,
      uom: uomOptions.find((item) => item.value == uomId)?.label,
      description,
      file: attachment,
      quantity,
      unitPrice,
      deliveryAddressId,
      needByDate: dayjs(needByDate).format("DD-MMM-YYYY"),
      uomShortCode: uomLabel,
      amount: item.amount.toString(),
      // igst: item.igst.toString(),
      igstPercentage: item.igstPercentage.toString(),
      igst: item.igstAmount.toString(),
      // cgst: item.cgst.toString(),
      cgstPercentage: item.cgstPercentage.toString(),
      cgst: item.cgstAmount.toString(),
      // sgst: item.sgst.toString(),
      sgstPercentage: item.sgstPercentage.toString(),
      sgst: item.sgstAmount.toString(),
      cess: _cess,
      grossTotal: item.lineTotal,
      attachment: attachment?.length ? true : false,
    };
    
    if (formData.action == "add" || !formData.index) {
      append({
        uuid: nanoid(),
        lineNo: null,
        ...formResult,
      });
    } else {
      update(formData.index, {
        uuid: formData.index,
        lineNo: formData?.item?.lineNo,
        ...formResult,
      });
    }
    closeModal();
  };
  const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
    if (!id) {
      return <></>;
    }
    let address = options?.find((item: any) => item[matchKey] == id);
    if (!address) {
      return <></>;
    }
    return (
      <Popover
        content={
          <ul>
            <li>
              {address?.addressLine1} {address?.addressLine2}
            </li>
            <li>
              {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
              {address?.pincode}
            </li>
          </ul>
        }
        title={address?.deliveryLocationName}
      >
        <a>{text}</a>
      </Popover>
    );
  };
  useEffect(() => {
    fetchCategorys();
    fetchMaterials();
    fetchUOMOPtions();
    fetchDeliveryAddress();
  }, []);

  const isDissabled = (keyName: string) => {
    if (formData.action == "add" || formData.index == null) {
      return false;
    } else if (formData.action == "edit") {
      switch (status) {
        case "pending":
          return false;
        case "Pending Approval":
          return false;
        case "Approved":
          if (["quantity", "unitPrice", "needByDate"].includes(keyName))
            return false;
          return true;
        case "Change request accepted by Buyer":
          if (["quantity", "unitPrice", "needByDate"].includes(keyName))
            return false;
          return true;
        case "Rejected from Buyer":
          if (["quantity", "unitPrice", "needByDate"].includes(keyName))
            return false;
          return true;
        case "To be Invoiced":
          if (["quantity", "unitPrice", "needByDate"].includes(keyName))
            return false;
          return true;
        case "Changes from Buyer":
          if (["quantity", "unitPrice", "needByDate"].includes(keyName))
            return false;
          return true;
        case "Rejected":
          return true;
        default:
          return true;
      }
    }
  };

  return (
    <Modal isOpen={modal} toggle={closeModal} size="xl">
      <ModalBody>
        <h5 className="title">
          {formData.action == "add" || formData.index == null
            ? "Add New Line"
            : "Update Line"}
        </h5>
        <div style={{ marginTop: "15px" }}></div>
        {alertInfo.message && (
          <div className="mb-3 mt-1">
            <Alert
              color={alertInfo.type === "error" ? "danger" : "success"}
              className="alert-icon"
            >
              <strong>{alertInfo.message}</strong>
              <Icon
                name={
                  alertInfo.type === "error" ? "alert-circle" : "check-circle"
                }
              />
              {alertInfo.errors.length > 0 ? (
                <ul>
                  {alertInfo.errors.map((err: any, index: any) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              ) : (
                ""
              )}
            </Alert>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(submitForm)}>
            <Row className="mt-12">
              <div className="col-md-4">
                <FormSelect
                  name="materialCategory"
                  label="Material Category"
                  required={true}
                  options={categoryOptions}
                  placeholder="Select Material Category"
                  disabled={isDissabled("materialCategory")}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  name="materialName"
                  label="Material Name"
                  required={true}
                  options={materialCategoryOptions
                    ?.filter((item: any) => item.category == materialCategory)
                    ?.map((item: any) => ({
                      label: item.productName,
                      value: item.materialID,
                    }))}
                  placeholder="Select Material Name"
                  disabled={isDissabled("materialName")}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  required={true}
                  name="hsnCode"
                  label="HSN/SAC"
                  pattern={/^[0-9]{6,8}$/}
                  placeholder="Enter HSN"
                  disabled={isDissabled("hsnCode")}
                />
              </div>
            </Row>
            <div style={{ marginTop: "15px" }}></div>
            <Row>
              <div className="col-md-2">
                <FormInput
                  type="number"
                  required={true}
                  name="quantity"
                  min={1}
                  label="Quantity"
                  placeholder="Enter Quantity"
                  disabled={isDissabled("quantity")}
                />
              </div>
              <div className="col-md-2">
                <FormSelect
                  name="uomId"
                  label="UOM"
                  required={true}
                  options={uomOptions}
                  placeholder="Units"
                  disabled={isDissabled("uomId")}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="number"
                  required={true}
                  min={1}
                  name="unitPrice"
                  label="Price per Unit"
                  placeholder="Enter Price per Unit"
                  disabled={isDissabled("unitPrice")}
                />
              </div>
              {taxConfiguration?.gstType == "IGST" ? (
                <>
                  <div className="col-md-4">
                    <FormSelect
                      name="igst"
                      label="IGST(%)"
                      required={true}
                      options={TAX_SLAP_DROPDOWN}
                      placeholder="Enter IGST"
                      disabled={isDissabled("igst")}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-4">
                    <FormSelect
                      name="cgst"
                      label="CGST/SGST(%)"
                      required={true}
                      options={TAX_SLAP_DROPDOWN}
                      placeholder="Enter CGST"
                      disabled={isDissabled("cgst")}
                    />
                  </div>
                  <div style={{ marginTop: "15px" }}></div>
                </>
              )}
              {taxConfiguration?.cess?.map((item: any, index: any) => {
                if (!item.cessName) return null;
                return (
                  <>
                    {index === 0 && taxConfiguration?.gstType === "IGST" && (
                      <div style={{ marginTop: "15px" }}></div>
                    )}
                    <div className="col-md-4">
                      <FormInput
                        type="number"
                        required={true}
                        name={item.cessName}
                        label={item.cessName + "(%)"}
                        placeholder={`Enter ${item.cessName}`}
                        disabled={isDissabled(item.cessName)}
                      />
                    </div>
                  </>
                );
              })}
              {needByDateType === "Line Level" && (
                <div className="col-md-4">
                  <FormDatePicker
                    name="needByDate"
                    label="Need By Date"
                    required={true}
                    minDate={new Date()}
                    disabled={isDissabled("needByDate")}
                  />
                </div>
              )}
              <Col md={4}>
                {files && files[0]?.name && (
                  <div className="form-group m-0">
                    <div className="form-label-wrapper">
                      <label className="form-label" htmlFor={"attachment"}>
                        Attachment (If any)
                      </label>
                      <TooltipComponent
                        iconClass="card-hint"
                        icon="help-fill"
                        direction="left"
                        id={`file-tooltip`}
                        text="e.g: Design specificaitons"
                        containerClassName={""}
                      />
                    </div>
                    <div
                      className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                      style={{ borderRadius: "3px" }}
                    >
                      <span>{files[0]?.name}</span>
                      <span
                        onClick={() => {
                          setValue("attachment", null);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                          display: "block",
                          fontSize: 14,
                        }}
                      >
                        <Icon name="cross"></Icon> &nbsp;
                      </span>
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: `${files && files[0]?.name ? "none" : "block"}`,
                  }}
                >
                  <FormInput
                    type="file"
                    name="attachment"
                    label="Attachment (If any) "
                    placeholder="Enter Attachment"
                    tooltipText="e.g: Design specificaitons"
                    disabled={isDissabled("attachment")}
                  />
                  {formData?.item?.file && (
                    <div>{formData?.item?.file?.fileName}</div>
                  )}
                </div>
              </Col>

              {deliveryAddressType === "Line Level" && (
                <>
                  <div className="col-md-4">
                    <FormSelect
                      name="deliveryAddressId"
                      label="Delivery Address"
                      required={true}
                      options={deliveryAddressOptions}
                      disabled={isDissabled("deliveryAddressId")}
                      popOverOptions={
                        <ShowAddressPopOver
                          id={deliveryAddressId}
                          matchKey="id"
                          options={deliveryAddressList}
                          text="Address"
                        />
                      }
                      placeholder="Select Delivery Address"
                      tooltipText="Select the delivery address for the purchase order."
                    />
                  </div>
                </>
              )}
            </Row>
            <Row className="mt-4">
              <div className="col-md-12">
                <FormTextArea
                  name="description"
                  label="Description (if any)"
                  placeholder="Enter Description"
                  minLength={5}
                  maxLength={200}
                  disabled={isDissabled("description")}
                />
              </div>
            </Row>
            <Row className="mt-4">
              <Col lg="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  {(formData.action === "edit"||formData.action == "add") && status !== "Rejected" && (
                    <li>
                      <Button
                        className="btn btn-primary btn-md"
                        type="submit"
                        color="primary"
                      >
                        {
                          formData.action == "edit" ? "Update" : "Save"
                        }
                      </Button>
                    </li>
                  )}
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default MaterialModal;
