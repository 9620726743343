import React, { useState, useEffect, FC, useMemo, useRef } from "react";
import Content from "../../../layout/content/Content";
import { Card, Row, Col } from "reactstrap";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Button,
  Icon,
  PaginationComponent,
  DataTable,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  TooltipComponent,
} from "../../../components/Component";
import MastersAside from "../MastersAside";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Swal from "sweetalert2";
import DynamicSidebar from "../../../components/dynamicSidebar/DynamicSidebar";
import SupplierMaster from "./SupplierMaster";
import { Supplier } from "./types/Supplier";
import SupplierInfo from "./SupplierInfo";
import supplierApis from "../../../api/master/supplier";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Export from "../../../components/export/Export";
import { Flex, Spin } from "antd";
const Suppliers: FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { data: accessibility } = useSelector(
    (state: any) => state.accessibility
  );
  const [allowedActions, setAllowedActions] = useState<any>({
    add: 0,
    view: 0,
    edit: 0,
    approve: 0,
    reject: 0,
    cancel: 0,
  });

  useEffect(() => {
    if (accessibility) {
      try {
        const { add, view, edit, approve, reject, cancel } =
          accessibility["Master"];
        setAllowedActions({ add, view, edit, approve, reject, cancel });
      } catch (error) {
        console.log(error);
      }
    }
  }, [accessibility]);

  const [onSearch, setonSearch] = useState(true);
  const [onSearchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [sort, setSortState] = useState("");
  const [sm, updateSm] = useState(false);
  const [tablesm, updateTableSm] = useState(false);
  const [modal, setModal] = useState<any>({
    edit: false,
    add: false,
  });
  const [editId, setEditedId] = useState();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState<Supplier[]>([]);
  const [originalData, setOriginalData] = useState([]);

  const handleDelete = (SupplierID: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-secondary",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteSupplier(SupplierID);
      }
    });
  };

  const deleteSupplier = async (id: any) => {
    const { status, message } = await supplierApis.deleteSupplierById(id);
    if (status) {
      Swal.fire("Deleted!", "Supplier has been deleted", "success");
      await fetchData(); // Assuming fetchData is a function to fetch updated supplier data
    } else {
      Swal.fire("Error", message, "error");
    }
  };
  const fetchData = async () => {
    const { data, status, message } = await supplierApis.fetchSupplier();
    if (status) {
      setRowData(data);
      setOriginalData(data); // Store the original data
    } else {
      Swal.fire("Error", message, "error");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (onSearchText !== "") {
      const searchText = onSearchText.toLowerCase();

      const filteredObject = originalData.filter((item: any) => {
        return Object.values(item).some((value: any) => {
          // Convert value to string and then perform case-insensitive search
          return value && value.toString().toLowerCase().includes(searchText);
        });
      });
      setRowData(filteredObject); // Update state with filtered data
    } else {
      setRowData(originalData); // Reset to original data
    }
  }, [onSearchText, originalData]);

  const sortFunc = (params: any) => {
    let defaultData = rowData;
    if (params === "asc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        a.createdDate.localeCompare(b.createdDate)
      );
      setRowData([...sortedData]);
    } else if (params === "dsc") {
      let sortedData = defaultData.sort((a: any, b: any) =>
        b.createdDate.localeCompare(a.createdDate)
      );
      setRowData([...sortedData]);
    }
  };
  // function to toggle the search option
  const toggle = () => setonSearch(!onSearch);

  // Get current list, pagination
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = rowData.slice(indexOfFirstItem, indexOfLastItem);

  // Change Page
  const paginate = (pageNumber: any) => setCurrentPage(pageNumber);

  const onFilterChange = (e: any) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const addSupplier = () => {
    navigate(`/addSuppliers`);
  };

  return (
    <React.Fragment>
      <Head title="Suppliers"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                Supplier Master
              </BlockTitle>
              <BlockDes className="text-soft">
                <p>You have total {rowData.length} Supplier.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Export data={currentItems} size="sm" />
                    </li>
                    {allowedActions?.add ? (
                      <li className="nk-block-tools-opt">
                        <Button color="primary" onClick={() => addSupplier()}>
                          <Icon name="plus"></Icon>
                          <span>Add</span>
                        </Button>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <DataTable
            className="card-stretch"
            bodyClassName={undefined}
            title={undefined}
          >
            <div className="card-inner position-relative card-tools-toggle">
              <div className={`card-search search-wrap active`}>
                <div className="card-body">
                  <div
                    className="search-content"
                    style={{ whiteSpace: "nowrap", display: "flex" }}
                  >
                    <Button className="search-back btn-icon toggle-search active">
                      <Icon name="search"></Icon>
                    </Button>
                    <input
                      type="text"
                      className="border-transparent form-focus-none form-control"
                      placeholder="Search"
                      value={onSearchText}
                      onChange={(e) => onFilterChange(e)}
                    />
                    <UncontrolledDropdown>
                      <DropdownToggle
                        tag="a"
                        className="btn btn-trigger btn-icon dropdown-toggle"
                      >
                        <Icon name="filter"></Icon>
                      </DropdownToggle>
                      <DropdownMenu end className="dropdown-menu-xs">
                        <ul className="link-check">
                          <li>
                            <span>Show</span>
                          </li>
                          <li className={itemPerPage === 10 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(10);
                              }}
                            >
                              10
                            </DropdownItem>
                          </li>
                          <li className={itemPerPage === 15 ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setItemPerPage(15);
                              }}
                            >
                              15
                            </DropdownItem>
                          </li>
                        </ul>
                        <ul className="link-check">
                          <li>
                            <span>Order</span>
                          </li>
                          <li className={sort === "dsc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("dsc");
                                sortFunc("dsc");
                              }}
                            >
                              DESC
                            </DropdownItem>
                          </li>
                          <li className={sort === "asc" ? "active" : ""}>
                            <DropdownItem
                              tag="a"
                              href="#dropdownitem"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setSortState("asc");
                                sortFunc("asc");
                              }}
                            >
                              ASC
                            </DropdownItem>
                          </li>
                        </ul>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </div>
              </div>
            </div>

            <DataTableBody compact className={undefined} bodyclass={undefined}>
              <DataTableHead>
                <DataTableRow className={undefined} size={undefined}>
                  <span className="sub-text">#</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Supplier ID</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Supplier Name</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">GSTIN</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Contact Person</span>
                </DataTableRow>
                <DataTableRow size="sm" className={undefined}>
                  <span className="sub-text">Phone Number</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Email</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">State</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">District</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  <span className="sub-text">Status</span>
                </DataTableRow>
                <DataTableRow size="md" className={undefined}>
                  {/* <span className="sub-text">Details</span> */}
                </DataTableRow>
              </DataTableHead>
              {/*Head*/}
              {currentItems.length > 0
                ? currentItems.map((item: any, index) => {
                    return (
                      <DataTableItem
                        key={item.id}
                        className={undefined}
                        style={{ color: "black" }}
                      >
                        <DataTableRow size="md" className={undefined}>
                          <span>{index + 1}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.referenceID}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.supplierName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.gstin}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.contactPerson}</span>
                        </DataTableRow>
                        <DataTableRow size="sm" className={undefined}>
                          <span>{item.phoneNumber}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.email}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.state.stateName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span>{item.district.districtName}</span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <span
                            className={`tb-status text-${
                              item.status === "Active" ? "success" : "danger"
                            }`}
                          >
                            {item.status}
                          </span>
                        </DataTableRow>
                        <DataTableRow size="md" className={undefined}>
                          <ul className="nk-tb-actions">
                            <li
                              className="nk-tb-action-hidden"
                              onClick={() =>
                                navigate(
                                  `${process.env.PUBLIC_URL}/vewSuppliersDetails/${item.supplierID}`
                                )
                              }
                            >
                              <TooltipComponent
                                tag="a"
                                containerClassName="btn btn-trigger btn-icon"
                                id={"edit" + item.id}
                                icon="focus"
                                direction="top"
                                text="View Details"
                                iconClass={undefined}
                              />
                            </li>
                            <li>
                              <UncontrolledDropdown>
                                <DropdownToggle
                                  tag="a"
                                  className="dropdown-toggle btn btn-icon btn-trigger"
                                >
                                  <Icon name="more-h"></Icon>
                                </DropdownToggle>
                                <DropdownMenu end>
                                  <ul className="link-list-opt no-bdr">
                                    {/* <li onClick={() => onEditClick(item.id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit</span>
                                  </DropdownItem>
                                </li> */}
                                    <li>
                                      <Link
                                        to={`${process.env.PUBLIC_URL}/vewSuppliersDetails/${item.supplierID}`}
                                      >
                                        <Icon name="focus"></Icon>
                                        <span>View Details</span>
                                      </Link>
                                    </li>
                                    {allowedActions?.cancel ? (
                                      <li
                                        onClick={() =>
                                          handleDelete(item.supplierID)
                                        }
                                      >
                                        <Link to={`#`}>
                                          <Icon name="delete"></Icon>
                                          <span>Delete</span>
                                        </Link>
                                      </li>
                                    ) : (
                                      <></>
                                    )}
                                  </ul>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </li>
                          </ul>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })
                : null}
            </DataTableBody>
            {isLoading === true ? (
              <>
                <Flex align="center" gap="middle" className="pageLoader">
                  <Spin size="large" className="m-auto" />
                </Flex>
              </>
            ) : (
              <div className="card-inner">
                {currentItems.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={rowData.length}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No data found</span>
                  </div>
                )}
              </div>
            )}
          </DataTable>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Suppliers;
