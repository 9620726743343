import React, { useEffect, FC, useState } from "react";
import { Modal, ModalBody, Form, Row, Alert, Col, Button } from "reactstrap";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "../form-components/InputComponent";
import { Icon, TooltipComponent } from "../../components/Component";
import FormSelect from "../form-components/SelectComponent";
import FormDatePicker from "../form-components/DatePicker";
import FormTextArea from "../form-components/TextAreaComponent";
import AntSelect from "../form-components/AntSelectComponent";
import { Popover } from "antd";
import adminOrgApis from "../../api/master/adminOrg";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import misc from "../../api/misc/dropDowns";
import { nanoid } from "nanoid";
import materialsApis from "../../api/master/materials";
import supplierApis from "../../api/master/supplier";
import dayjs from "dayjs";
interface SelectOptions {
    label: string;
    value: any;
}

type Cess = {
    cessName: string;
    cessType?: string;
    cessPercentage?: string;
    cessAmount?: string;
};
interface TaxConfiguration {
    taxType: string;
    gstType?: string; //IGST or CGST+SGST
    cess?: Cess[];
}
const TAX_SLAP_DROPDOWN = [
    {
        label: "0%",
        value: "0",
    },
    {
        label: "5%",
        value: "1",
    },
    {
        label: "12%",
        value: "2",
    },
    {
        label: "18%",
        value: "3",
    },
    {
        label: "28%",
        value: "4",
    },
];
const MaterialModal: FC<any> = ({
    modal,
    closeModal,
    append,
    update,
    formData,
    taxConfiguration,
    needByDateType,
    deliveryAddressType,
}) => {
    const [sm, setSm] = useState(false);
    const [screenSize, setScreenSize] = useState(0);
    const [mobileView, setMobileView] = useState(false);
    const [uomOptions, setUomOptions] = useState<SelectOptions[]>([]);
    const [materialCategoryOptions, setMaterialCategoryOptions] = useState([]);
    const [categoryOptions, setCategoryOptions] = useState<SelectOptions[]>([]);
    const [materialOptions, setMaterialOptions] = useState<SelectOptions[]>([]);

    const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
    const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
        SelectOptions[]
    >([]);



    const methods = useForm({
        mode: "onChange",
    });
    const {
        handleSubmit,
        reset,
        watch,
        formState: { errors },
        setValue,
    } = methods;

    const [alertInfo, setAlertInfo] = useState({
        type: "", // 'success' or 'error'
        message: "",
        errors: [],
    });

    const files = watch("attachment");

    useEffect(() => {
        console.log("files",files)
        if (files && files[0]?.size && files[0].size > 2097152) {
            setValue("attachment", null);
            setAlertInfo({
                type: "error",
                message: "File size should be less than 2 MB",
                errors: [],
            });
        }
    }, [files]);

    const materialCategory = watch("materialCategory");
    const deliveryAddressId = watch("deliveryAddressId");



    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setAlertInfo({
                type: "",
                message: "",
                errors: [],
            });
        }, 2500);
        return () => clearTimeout(timeoutId);
    }, [alertInfo]);

    const fetchDeliveryAddress = async () => {
        try {
            const { data, status, message } =
                await deliveryAddressApis.fetchDeliveryAddress();

            if (status) {
                let deliveryAddressOptions = data.map((item: any) => {
                    return {
                        label: item.deliveryLocationName,
                        value: item.id.toString(),
                    };
                });
                setDeliveryAddressOptions(deliveryAddressOptions);
                setDeliveryAddressList(data);
            } else {
                console.log(message);
            }
        } catch (error) { }
    };

    const fetchUOMOPtions = async () => {
        try {
            const { data, status, message } = await adminOrgApis.fetchUom();
            if (status) {
                setUomOptions(
                    data.map((item: any) => {
                        return {
                            label: item.uom_name,
                            value: item.id.toString(),
                        };
                    })
                );
            } else {
                console.log(message);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchCategorys = async () => {
        try {
            const { data, status } = await supplierApis.fetchSupplierCategory();
            if (status) {
                const categoryOption = data.map((item: any) => ({
                    label: item.category,
                    value: item.id.toString(),
                }));
                setCategoryOptions(categoryOption);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchMaterials = async () => {
        try {
            const { data, status } = await materialsApis.fetchProduct();
            if (status) {
                setMaterialCategoryOptions(data);
                const materialOption = data.map((item: any) => ({
                    label: item.productName,
                    value: item.materialID.toString(),
                }));
                setMaterialOptions(materialOption);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const viewChange = () => {
        setScreenSize(window.innerWidth);
        if (window.innerWidth < 990) {
            setMobileView(true);
        } else {
            setMobileView(false);
            setSm(false);
        }
    };

    useEffect(() => {
        viewChange();
        window.addEventListener("load", viewChange);
        window.addEventListener("resize", viewChange);
        const headerClick = () => setSm(false);
        document
            .getElementsByClassName("nk-header")[0]
            ?.addEventListener("click", headerClick);

        return () => {
            window.removeEventListener("resize", viewChange);
            window.removeEventListener("load", viewChange);
            document
                .getElementsByClassName("nk-header")[0]
                ?.removeEventListener("click", headerClick);
        };
    }, []);
    useEffect(() => {
        if (modal && formData.item) {
            const {
                categoryId,
                materialId,
                materialCategory,
                materialName,
                deliveryDate,
                hsnCode,
                quantity,
                uomId,
                unitPrice,
                cgst,
                sgst,
                igst,
                deliveryAddressId,
                deliveryAddressName,
                description,
                needByDate,
                quotationLineId,
                file,
            } = formData.item;
            console.log("attachments",formData.item);
            setValue("materialCategory", categoryId);
            setValue("materialName", materialId);
            setValue("quantity", quantity);
            setValue("uomId", uomId);
            setValue("unitPrice", Number(unitPrice));
            setValue("cgst", cgst);
            setValue("sgst", sgst);
            setValue("igst", igst);
            setValue("description", description);
            setValue("needByDate", dayjs(deliveryDate));
            setValue("attachment", file);
            setValue("deliveryAddressId", deliveryAddressId);
            setValue("quotationLineId", quotationLineId)
            if (formData.index != null) {
                // setValue("needByDate", deliveryDate);
            }
        }
    }, [modal]);

    const getMaterialName = (id: any) => {
        console.log("idd::", id, materialCategoryOptions)
        const materialName: any = materialCategoryOptions.find((item: any) => item.materialID == id)
        console.log("materialName", materialName?.productName)
        const label = materialName?.productName;
        return label;
    }

    const submitForm = (data: any) => {
        console.log("data", data, formData);
        const {
            materialId = Number(data.materialName),
            materialCategory,
            categoryId = Number(materialCategory),
            categoryName = categoryOptions.find(
                (item) => item.value == materialCategory
            )?.label,
            materialName = getMaterialName(materialId),
            quantity,
            description,
            uomId,
            uomName = uomOptions.find(
                (item) => item.value == uomId
            )?.label,
            deliveryDate = dayjs(data.needByDate).format('DD-MMM-YYYY'),
            attachment,
            deliveryAddressId,
            quotationLineId = formData?.item?.quotationLineId,
            rfqLineId= formData?.item?.rfqLineId
        } = data;
        const item = {
            categoryName,
            materialId,
            materialName,
            categoryId,
            materialCategory,
            quantity,
            deliveryDate,
            description,
            uomName,
            deliveryAddressId,
            attachment,
            quotationLineId,
            rfqLineId,
        };
       

        const formResult = {
            uomName,
            materialId,
            categoryId,
            materialName: getMaterialName(materialId),
            categoryName,
            materialCategory,
            quantity,
            uomId,
            description,
            file: attachment,
            deliveryAddressId,
            deliveryDate: dayjs(deliveryDate).format("DD-MMM-YYYY"),
            attachment: attachment?.length ? true : false,
            quotationLineId,
            rfqLineId
        };

        console.log('formData.action', formData)


        if (formData.action == "add" || formData.index == null) {
            append({
                ...formResult,
                quotationLineId: null,
            });
        } else {
            update(formData.index, {
                ...formResult,
                quotationLineId: formData?.item?.quotationLineId,
            });
        }
        closeModal();
    };
    const ShowAddressPopOver = ({ id, text, options, matchKey }: any) => {
        if (!id) {
            return <></>;
        }
        let address = options?.find((item: any) => item[matchKey] == id);
        if (!address) {
            return <></>;
        }
        return (
            <Popover
                content={
                    <ul>
                        <li>
                            {address?.addressLine1} {address?.addressLine2}
                        </li>
                        <li>
                            {address?.city}, {address?.stateName},{" "}
                            {address?.countryName} - {address?.pincode}
                        </li>
                    </ul>
                }
                title={address?.deliveryLocationName}
            >
                <a>{text}</a>
            </Popover>
        );
    };
    useEffect(() => {
        fetchCategorys();
        fetchMaterials();
        fetchUOMOPtions();
        fetchDeliveryAddress();
    }, []);


   


    const updateDoc = (file: any) => {
        console.log("file", file)
    }


    return (
        <Modal isOpen={modal} toggle={closeModal} size="xl">
            <ModalBody>
                <h5 className="title">
                    {formData.action == "add" || formData.index == null
                        ? "Add New Line"
                        : "Update Line"}
                </h5>
                <div style={{ marginTop: "15px" }}></div>
                {alertInfo.message && (
                    <div className="mb-3 mt-1">
                        <Alert
                            color={alertInfo.type === "error" ? "danger" : "success"}
                            className="alert-icon"
                        >
                            <strong>{alertInfo.message}</strong>
                            <Icon
                                name={
                                    alertInfo.type === "error" ? "alert-circle" : "check-circle"
                                }
                            />
                            {alertInfo.errors.length > 0 ? (
                                <ul>
                                    {alertInfo.errors.map((err: any, index: any) => (
                                        <li key={index}>{err}</li>
                                    ))}
                                </ul>
                            ) : (
                                ""
                            )}
                        </Alert>
                    </div>
                )}
                <FormProvider {...methods}>
                    <form onSubmit={handleSubmit(submitForm)}>
                        <Row className="mt-12">
                            <div className="col-md-6">
                                <FormSelect
                                    name="materialCategory"
                                    label="Material Category"
                                    required={true}
                                    options={categoryOptions}
                                    placeholder="Select Material Category"
                                />
                            </div>
                            <div className="col-md-6">
                                <FormSelect
                                    name="materialName"
                                    label="Material Name"
                                    required={true}
                                    options={materialCategoryOptions?.filter(
                                        (item: any) => item.category == materialCategory
                                    )?.map((item: any) => ({
                                        label: item.productName,
                                        value: item.materialID,
                                    }))}
                                    placeholder="Select Material Name"
                                />
                            </div>
                        </Row>
                        <div style={{ marginTop: "15px" }}></div>
                        <Row>
                            <div className="col-md-3">
                                <FormInput
                                    type="number"
                                    required={true}
                                    name="quantity"
                                    label="Quantity"
                                    placeholder="Enter Quantity"
                                />
                            </div>
                            <div className="col-md-3">
                                <FormSelect
                                    name="uomId"
                                    label="UOM"
                                    required={true}
                                    options={uomOptions}
                                    placeholder="Units"
                                />
                            </div>
                            {needByDateType === "LINE_LEVEL" && (
                            <div className="col-md-6">
                                <FormDatePicker
                                    name="needByDate"
                                    label="Need By Date"
                                    required={true}
                                    minDate={new Date()}
                                />
                            </div>
                             )} 
                            <Col md={6}>
                                {files && files[0]?.name && (
                                    <div className="form-group m-0">
                                        <div className="form-label-wrapper">
                                            <label className="form-label" htmlFor={"attachment"}>
                                                Attachment (If any)
                                            </label>
                                            <TooltipComponent
                                                iconClass="card-hint"
                                                icon="help-fill"
                                                direction="left"
                                                id={`file-tooltip`}
                                                text="e.g: Design specificaitons"
                                                containerClassName={""}
                                            />
                                        </div>
                                        <div
                                            className="form-control-wrap d-flex align-items-center justify-content-between border p-1"
                                            style={{ borderRadius: "3px" }}
                                        >
                                            <span>{files[0]?.name}</span>
                                            <span
                                                onClick={() => {
                                                    setValue("attachment", null);
                                                }}
                                                style={{
                                                    cursor: "pointer",
                                                    marginLeft: "10px",
                                                    display: "block",
                                                    fontSize: 14,
                                                }}
                                            >
                                                <Icon name="cross"></Icon> &nbsp;
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <div
                                    style={{
                                        display: `${files && files[0]?.name ? "none" : "block"}`,
                                    }}
                                >
                                    <FormInput
                                        type="file"
                                        name="attachment"
                                        label="Attachment (If any) "
                                        placeholder="Enter Attachment"
                                        tooltipText="e.g: Design specificaitons"
                                    />
                                    {formData?.item?.dms && (
                                        <div>{formData?.item?.dms[0]?.fileName}</div>
                                    )}
                                </div>
                            </Col>
                        </Row>

                        {deliveryAddressType === "LINE_LEVEL" && (
                            <>
                                <Row className="mt-4">
                                    <div className="col-md-4">
                                        <FormSelect
                                            name="deliveryAddressId"
                                            label="Delivery Address"
                                            required={true}
                                            options={deliveryAddressOptions}
                                            popOverOptions={
                                                <ShowAddressPopOver
                                                    id={deliveryAddressId}
                                                    matchKey="id"
                                                    options={deliveryAddressList}
                                                    text="Address"
                                                />
                                            }
                                            placeholder="Select Delivery Address"
                                            tooltipText="Select the delivery address for the purchase order."
                                        />
                                    </div>
                                </Row>
                            </>
                        )}
                        {/* <Row className="mt-4">
                            <div className="col-md-12">
                                <FormTextArea
                                    name="description"
                                    label="Description (if any)"
                                    placeholder="Enter Description"
                                    minLength={5}
                                    maxLength={200}
                                />
                            </div>

                        </Row> */}
                        <Row className="mt-4">
                            <Col lg="12">
                                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                                    {formData.action === "edit" && formData.index != null && (
                                        <li>
                                            <Button
                                                className="btn btn-primary btn-md"
                                                type="submit"
                                                color="primary"
                                            >
                                                Update
                                            </Button>
                                        </li>
                                    )}
                                    {(formData.action == "add" || formData.index == null) && (
                                        <li>
                                            <Button
                                                className="btn btn-primary btn-md"
                                                type="submit"
                                                color="primary"
                                            >
                                                Save
                                            </Button>
                                        </li>
                                    )}
                                    <li>
                                        <a
                                            href="#cancel"
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                closeModal();
                                            }}
                                            className="link link-light"
                                        >
                                            Cancel
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </form>
                </FormProvider>
            </ModalBody>
        </Modal>
    );
};

export default MaterialModal;