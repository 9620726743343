import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  PaperClipOutlined,
  DeleteOutlined,
  PlusSquareOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloudUploadOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Upload,
  Popover,
  Timeline,
  Select,
  DatePicker,
  Empty,
} from "antd";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../components/Component";
import dayjs from "dayjs";
import { Badge, Button, Col, Row } from "reactstrap";
import invoiceApis from "../../api/Invoice/invoice";
import { nanoid } from "nanoid";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import PdfGenerator from "./SellerToBuyerInvoicePDF";
import { formatCurrency, formatDecimal } from "../../utils/Utils";
import miscService from "../../api/misc/misc";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 1,
              message: `${title} must > 0`,
            },
          ]}
          initialValue={Number(record[dataIndex])}
        >
          <InputNumber
            className="w-100"
            formatter={(value) => {
              return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }}
            parser={(value: any) => {
              return value.replace(/\$\s?|(,*)/g, "");
            }}
            onKeyPress={(event) => {
              if (!/^[0-9]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const { Panel } = AntCollapse;
function InvoiceComponent({ invoiceNumber, previousPath,extra }: any) {
  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );
  const navigate = useNavigate();
  const [showSubmitBtn, setShowSubmitBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState("1");
  const [OriginalData, setOriginalData] = useState<any>({});
  const [updatedLineItems, setUpdatedLineItems] = useState<any>([]);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [sm, setSm] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [editedData, setEditedData] = useState<any[]>([]);
  const [signature, setSignature] = useState<any>([]);
  const [DCAttachment, setDCAttachment] = useState<any>(null);
  const [newDCAttachment, setNewDCAttachment] = useState<any>(null);
  const [newInvoiceAttachment, setNewInvoiceAttachment] = useState<any>(null);
  const [InvoiceAttachment, setInvoiceAttachment] = useState<any>(null);
  const [termsVisible, setTermsVisible] = useState(false);
  const [bankDetailsVisible, setBankDetailsVisible] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
  const [dms, setDms] = useState<any>([]);
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const [currency, setCurrency] = useState<any>({
    currencyFormat: "",
    currencySymbol: "",
  });
  const [newInvoiceType, setNewInvoiceType] = useState<any>("");
  const [newInvoiceDate, setNewInvoiceDate] = useState<any>();
  const [newDueDate, setNewDueDate] = useState<any>();
  const [form] = Form.useForm();
  const [form2] = Form.useForm();
  const [additionalCharges, setAdditionalCharges] = useState<any>([]);
  const [editAdditionalCharges, setEditAdditionalCharges] =
    useState<any>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [editingKey, setEditingKey] = useState<string | null>(null);
  const [invoiceDisapproveInvoice, setInvoiceDisapproveInvoice] =
    useState<any>(false);
  const [readOnly, setReadOnly] = useState<any>(true);

  const isEditing = (record: any) => record.key === editingKey;

  const edit = (record: any) => {
    form.setFieldsValue({ quantity: "", unitPrice: "", ...record });
    setEditingKey(record.key);
  };

  const save = async (key: React.Key) => {
    try {
      const row: any = await form.validateFields();
      const newData: any = [...dataSource];
      const index = newData.findIndex((item: any) => key == item.lineNo);
      if (index > -1) {
        setUpdatedLineItems((pre: any) => {
          if (!pre.includes(key)) {
            return pre.concat(key);
          }
          return pre;
        });
        const item: any = newData[index];
        const quantityInvoiced = row?.quantityInvoiced;
        const unitPrice = Number(row.unitPrice);
        const basicAmount = quantityInvoiced * unitPrice;
        const sgst = (basicAmount * Number(item.sgstPercentage)) / 100;
        const igst = (basicAmount * Number(item.igstPercentage)) / 100;
        const cgst = (basicAmount * Number(item.cgstPercentage)) / 100;
        const updatedRow = {
          ...item,
          quantityInvoiced,
          unitPrice,
          basicAmount,
          sgst,
          igst,
          cgst,
          grossTotal: basicAmount + sgst + igst + cgst,
        };
        console.log(index);
        console.log(updatedRow);

        newData.splice(index, 1, { ...updatedRow });
        setDataSource(newData);
        setEditingKey(null);
        setEditedData(
          newData.filter((item: any) => selectedRowKeys.includes(item.lineNo))
        );
      }
      setShowSubmitBtn(true);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const cancel = () => {
    setEditingKey(null);
  };

  const columns = [
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text: any, record: any, index: number) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
      fixed: "left",
      render: (material: any) => <div className="align-left">{material}</div>,
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      render: (text: any) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "Qty Ordered",
      dataIndex: "quantityOrdered",
      key: "quantityOrdered",
      width: 150,
      render: (text: any, record: any) => (
        <div className="align-left">{Number(text) + " " + record?.uom}</div>
      ),
    },
    {
      title: "Invoiced Qty",
      dataIndex: "quantityInvoiced",
      key: "quantityInvoiced",
      width: 150,
      editable: true,
      render: (text: any, record: any) => (
        <div className="align-left" onClick={() => edit(record)}>
          {Number(text) + " " + record?.uom}
        </div>
      ),
    },
    {
      title: "Unit Price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
      editable: true,
      render: (text: any, record: any) => (
        <p className="justify-end" onClick={() => edit(record)}>
          {formatCurrency(
            Number(text),
            currency?.currencyCode
          )}
        </p>
      ),
    },
    {
      title: "Basic Amount",
      dataIndex: "basicAmount",
      key: "basicAmount",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    // {
    //   title: "Net Amount",
    //   dataIndex: "netAmount",
    //   key: "netAmount",
    //   width: 150,
    //   render: (text: any) => <div>{text}</div>,
    // },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any,record:any) => `${(Number(record?.sgst) &&Number(record?.cgst))?Number(text)+"%":"N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "cgst",
          key: "cgst",
          width: 150,
          render: (text:any, record:any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
          </span>
        </div>
      ),
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any,record:any) => `${(Number(record?.sgst) &&Number(record?.cgst))?Number(text)+"%":"N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "sgst",
          key: "sgst",
          width: 150,
          render: (text:any, record:any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(Number(record?.sgst) &&Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
          </span>
        </div>
      ),
        },
      ],
    },
    {
      title: "IGST",
      children: [
        {
          title: "%",
          dataIndex: "igstPercentage",
          key: "igstPercentage",
          width: 100,
          render: (text: any,record:any) => `${(!Number(record?.sgst) &&!Number(record?.cgst))?Number(text)+"%":"N/A"}`,
        },
        {
          title: "Amount",
          dataIndex: "igst",
          key: "igst",
          width: 150,
          render: (text:any, record:any) => (
        <div style={{ textAlign: "right" }}>
          <span>
            {(!Number(record?.sgst) &&!Number(record?.cgst))?formatCurrency(text, "INR"):"N/A"}
          </span>
        </div>
      ),
          
        },
      ],
    },
    {
      title: "Line Total",
      dataIndex: "grossTotal",
      key: "grossTotal",
      width: 150,
      render: (text: any) => <div>{text}</div>,
    },
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   width: 150,
    //   render: (text: any) => <div>{text}</div>,
    // },
    {
      title: "Actions",
      key: "actions",
      width: 120,
      fixed: "right",
      render: (_: any, record: any) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="w-100 justify-content-around">
            <Button
              size="sm"
              color=""
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              <Icon name="save" />
            </Button>

            <Button size="sm" color="" onClick={cancel}>
              <Icon name="cross" />
            </Button>
          </div>
        ) : (
          <Button size="sm" color="" onClick={() => edit(record)}>
            <Icon name="edit" />
          </Button>
        );
      },
    },
  ];

  const mergedColumns: any = columns.map((col) => {
    if (
      ["basicAmount", "netAmount", "additionalCharges", "grossTotal"].includes(
        col?.dataIndex as string
      )
    ) {
      return {
        ...col,
        render: (text: any) => (
          <p className="justify-end">
            {formatCurrency(
              Number(text),
              currency?.currencyCode
            )}
          </p>
        ),
      };
    } else if ((col.children?.length as number) > 0) {
      return {
        ...col,
        // children: col?.children?.map((child: any) => {
        //   if (child?.title == "Amount") {
        //     return {
        //       ...child,
        //       render: (text: any) => (
        //         <p className="justify-end">
        //           {formatCurrency(
        //             Number(text),
        //             currency?.currencyCode
        //           )}
        //         </p>
        //       ),
        //     };
        //   } else {
        //     return child;
        //   }
        // }),
      };
    }
    if (
      materialData?.needByDateType != "Line Level" &&
      col?.dataIndex == "needByDate"
    ) {
      return {
        ...col,
        hidden: true,
      };
    }
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === "quantity" ? "number" : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (currencyFormat == "") {
      currencyFormat = "₹#,##0.00";
    }
    if (currencySymbol == "") {
      currencySymbol = "₹";
    }
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const fetchInvoiceData = async () => {
    try {
      const response = await invoiceApis.fetchInvoice(invoiceNumber);
      const { status, message, data } = response;
      if (status) {
        if (data?.additionalChargesArray) {
          setAdditionalCharges(
            data?.additionalChargesArray?.map((charge: any) => {
              const id = nanoid(4);
              form2.setFieldsValue({
                [`AdditionalCharges.${id}.name`]: charge?.name,
                [`AdditionalCharges.${id}.price`]: Number(charge?.price),
              });
              return {
                ...charge,
                id,
              };
            })
          );
        }
        if (data?.invoiceLines) {
          setDataSource(data?.invoiceLines);
        }
        if (data?.dms?.length > 0) {
          const dms = data?.dms;
          setDms(dms);
          const _signature = dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: data?.signatureLabel,
              isActive: true,
            });
          }
          const _termsAndConditions = dms?.find(
            (item: any) =>
              item.moduleFileType == "termsAndConditions" &&
              item.module == "invoice"
          );
          if (_termsAndConditions) {
            const data: any = await fetchHtmlContentFromUrl(
              _termsAndConditions?.filePath
            );
            setTermsAndConditions(data);
            setNewTermsAndConditions(data);
          }

          const _DCAttachment = dms?.find(
            (item: any) =>
              (item.moduleFileType == "DCAttachment"||item.moduleFileType == "attachment") && item.module == "invoice"
          );
          if (_DCAttachment) {
            setDCAttachment(_DCAttachment);
          }

          const _InvoiceAttachment = dms?.find(
            (item: any) =>
              item.moduleFileType == "InvoiceAttachment" &&
              item.module == "invoice"
          );
          if (_InvoiceAttachment) {
            setInvoiceAttachment(_InvoiceAttachment);
          }
        }
        setCurrency({
          currencyCode: data?.currencyCode,
          currency: data?.currencyName,
          currencySymbol: data?.currencySymbol,
          currencyFormat: data?.currencyFormat,
        });
        // if (
        //   [
        //     "Pending Approval",
        //     "Approval Rejected",
        //     "To be Dispatched",
        //     "Partially Dispatched",
        //   ].includes(data.invoiceStatus) && data?.invoiceType!="commercial"
        // ) {
        //   setReadOnly(false);
        // } else {
        //   setReadOnly(true);
        // }
        await fetchDMSData("buyerOrgLogo", data?.orgId);
        await fetchDMSData("sellerOrgLogo", data?.supplierId);
        setMaterialData(data);
        setOriginalData(data);
        setShowSubmitBtn(false);
        setIsLoading(false);

      }
    } catch (error) {
      console.log(error);
    }
  };

      const fetchDMSData = async (type: string, id: any) => {
    try {
      const queryObj: any = {};
      switch (type) {
        case "buyerOrgLogo":
          queryObj["moduleFileType"] = "org logo";
          queryObj["module"] = "orgs";
          queryObj["orgId"] = id;
          break;
        case "sellerOrgLogo":
          queryObj["moduleFileType"] = "org logo";
          queryObj["module"] = "orgs";
          queryObj["orgId"] = id;
          break;
        case "termsAndConditions":
          queryObj["moduleFileType"] = "termsAndConditions";
          queryObj["module"] = "invoice";
          queryObj["invoiceNumber"] = id;
          break;
        default:
          return;
      }
      const _response = await miscService.getByQuery(queryObj);
      const { status, response: dmsData } = _response;
      if (status && dmsData?.length > 0) {
        const item = dmsData[0];
        switch (type) {
          case "buyerOrgLogo":
            let orgLogo = item?.filePath;
            setOriginalData((pre: any) => ({ ...pre, buyerOrgLogo: orgLogo }));
            break;
          case "sellerOrgLogo":
            let sellerOrgLogo = item?.filePath;
            setOriginalData((pre: any) => ({ ...pre, sellerOrgLogo: sellerOrgLogo }));
            break;
          case "termsAndConditions":
            const _termsAndConditions: any = await fetchHtmlContentFromUrl(
              item?.filePath
            );
            setTermsAndConditions(_termsAndConditions);
            break;
          default:
            break;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SubmitChanges = async () => {
    try {
      const _formData = new FormData();

      const newfiledata: any = [];
      const deletedFileData: any = [];
      const updatedFileData: any = [];
      const _updatedLineItems: any = [];
      dataSource?.forEach((item: any) => {
        if (updatedLineItems?.includes(item?.lineNo)) {
          _updatedLineItems.push({
            lineNo: Number(item?.lineNo),
            materialId: Number(item?.materialId),
            quantityInvoiced: Number(item?.quantityInvoiced),
          });
        }
      }, []);

      if (isTermsAndConditionsChanged) {
        const termsAndConditionsFile = new Blob([newTermsAndConditions], {
          type: "text/html",
        });
        const _termsAndConditions = dms?.find(
          (item: any) => item.moduleFileType == "termsAndConditions"
        );
        if (_termsAndConditions?.docId) {
          deletedFileData.push({
            docId: _termsAndConditions.docId,
          });
        }
        _formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsAndConditions.html", {
            type: "text/html",
          })
        );
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "invoice",
          purpose: "termsAndConditions",
          level: "Header Level",
          uniqueId: nanoid(6),
          action: "Create",
        });
        setIsTermsAndConditionsChanged(false);
        setNewTermsAndConditions(null);
      }
      if (newDCAttachment) {
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "invoice",
          purpose: "DCAttachment",
          level: "Header Level",
          uniqueId: nanoid(6),
          action: "Create",
        });
        if (DCAttachment)
          deletedFileData.push({
            docId: DCAttachment?.docId,
          });
        _formData.append(
          `files`,
          new File([newDCAttachment], newDCAttachment?.name, {
            type: newDCAttachment?.type,
          })
        );
        setNewDCAttachment(null);
      }
      if (newInvoiceAttachment) {
        newfiledata.push({
          indexOfFileLocated: newfiledata.length,
          module: "invoice",
          purpose: "InvoiceAttachment",
          level: "Header Level",
          uniqueId: nanoid(6),
          action: "Create",
        });
        if (InvoiceAttachment)
          deletedFileData.push({
            docId: InvoiceAttachment?.docId,
          });
        _formData.append(
          `files`,
          new File([newInvoiceAttachment], newInvoiceAttachment?.name, {
            type: newInvoiceAttachment?.type,
          })
        );
        setNewInvoiceAttachment(null);
      }

      const filesData = {
        newfiledata,
        deletedFileData,
        updatedFileData,
      };
      const invoiceData: any = {
        mode: "U",
        combinedLineItems: {
          existingLineItems: OriginalData?.invoiceLines?.map((item: any) => {
            return {
              lineNo: item?.lineNo,
              materialId: item?.materialId,
              quantityInvoiced: Number(item?.quantityInvoiced),
            };
          }),
          updatedLineItems: _updatedLineItems,
          newLineItems: [],
          deletedLineItems: [],
        },
        additionalChargesArray: additionalCharges?.map((charge: any) => {
          return {
            name: charge?.name,
            price: charge?.price,
          };
        }),
      };
      if (newInvoiceType != "") {
        invoiceData.invoiceType = newInvoiceType;
        setNewInvoiceType("");
      }
      if (newInvoiceDate) {
        invoiceData.invoiceDate = newInvoiceDate?.format("YYYY-MM-DD");
        setNewInvoiceDate(null);
      }
      if (newDueDate) {
        invoiceData.dueDate = newDueDate?.format("YYYY-MM-DD");
        setNewDueDate(null);
      }
      _formData.append("invoiceData", JSON.stringify(invoiceData));
      _formData.append("filesData", JSON.stringify(filesData));
      _formData.append("files", JSON.stringify([]));
      // const response = await invoiceApis.amendmentInvoice(
      //   invoiceNumber,
      //   _formData
      // );
      // const { status, data, message } = response;
      // if (status) {
      //   Swal.fire({
      //     icon: "success",
      //     title: "Successfully Updated",
      //     showConfirmButton: false,
      //     timer: 1000,
      //   });

      //   fetchInvoiceData();
      // } else {
      //   Swal.fire({
      //     icon: "error",
      //     title: message,
      //     showConfirmButton: false,
      //     timer: 1000,
      //   });
      // }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
        showConfirmButton: false,
        timer: 1000,
      });
      console.log(error);
    }
  };

  useEffect(() => {
    // fetchInvoiceData();
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  useEffect(() => {
    if (invoiceNumber) {
      setIsLoading(true);
      fetchInvoiceData();
    }
  }, [invoiceNumber]);

  const ShowBillingAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = address;
    return (
      <ul>
        <li>{deliveryLocationName}</li>
        <li>{addressLine1}</li>
        <li>{addressLine2}</li>
        <li>
          {city},{district}, {state}, {countryInfo} - {pincode}
        </li>
      </ul>
    );
  };
  const ShowDeliveryAddress = ({ address }: any) => {
    if (!address) return <></>;
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = address;
    const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return (
      <ul>
        <li>
          {deliveryLocationName},{addressLine1},{addressLine2}
        </li>
        <li>
          {city},{district}, {state}, {countryInfo} - {pincode}
        </li>
        <li>
          <strong>GSTIN</strong> : {gstin}
        </li>
        <li>
          <strong>CIN</strong> : {cin}
        </li>
        <li>
          <strong>PAN</strong> : {pan}
        </li>
        <li>
          <strong>Phone</strong> : {phoneNumber}
        </li>
      </ul>
    );
  };

  const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
    let totalNetAmmount = 0,
      cgst = 0,
      sgst = 0,
      igst = 0,
      TotalAmount = 0;
    data?.forEach((item: any) => {
      const totalAmmount =
        Number(item.unitPrice) * Number(item.quantityInvoiced);
      cgst = cgst + (totalAmmount * Number(item.cgstPercentage)) / 100;
      sgst = sgst + (totalAmmount * Number(item.sgstPercentage)) / 100;
      igst = igst + (totalAmmount * Number(item.igstPercentage)) / 100;
      totalNetAmmount = totalNetAmmount + totalAmmount;
    });
    TotalAmount = totalNetAmmount + cgst + sgst + igst;
    additionalCharges?.forEach((item: any) => {
      TotalAmount = TotalAmount + Number(item.price);
    });
    return (
      <Row
        className={`${invoice && "w-50 mt-2"}`}
        style={{
          margin: "20px",
        }}
      >
        <Col className={`justify-end`}>
          <div className={`${invoice ? "w-100" : "w-40"}`}>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>Taxable Amount</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  totalNetAmmount,
                  currency?.currencyCode
                )}
              </span>
            </div>
            {
              (cgst&&sgst)?<>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>CGST</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  cgst,
                  currency?.currencyCode
                )}
              </span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>SGST</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  sgst,
                  currency?.currencyCode
                )}
              </span>
            </div>
              </>:
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>IGST</strong>
              </div>
              <span className="r_align">
                {formatCurrency(
                  igst,
                  currency?.currencyCode
                )}
              </span>
            </div>
            }
            {additionalCharges?.map((item: any) => {
              if (!item.price || !item.price) return null;
              return (
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>{item.name}</strong>
                  </div>
                  <span className="r_align">
                    {formatCurrency(
                      item.price,
                      currency?.currencyCode
                    )}
                  </span>
                </div>
              );
            })}
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              <div className="w-50 justify-end">
                <strong>Total</strong>
              </div>
              <strong className="r_align">
                {formatCurrency(
                  TotalAmount,
                  currency?.currencyCode
                )}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = materialData?.invoiceApprovals;

    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "Approved";
              const isRejected = approval.status == "Rejected";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    )  : undefined
                  }
                >
                  {approval?.user?.firstName}
                  <div className="designation">{approval?.role?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };

  const dcFileUploadProps = {
    beforeUpload: (file: any) => {
      // Perform any file validation or processing here
      setNewDCAttachment(file);
      setShowSubmitBtn(true);
      return false; // Return false to prevent auto-upload
    },
    maxCount: 1,
    onRemove: () => {
      setNewDCAttachment(null); // Clear the file from state
    },
  };
  const invoiceFileUploadProps = {
    beforeUpload: (file: any) => {
      // Perform any file validation or processing here
      setShowSubmitBtn(true);
      setNewInvoiceAttachment(file);
      return false; // Return false to prevent auto-upload
    },
    maxCount: 1,
    onRemove: () => {
      setNewInvoiceAttachment(null); // Clear the file from state
    },
  };

  const ShowSupplierBankInfo = () => {
    if (!OriginalData?.supplierDetails) return <></>;

    const { cin, gstin, pan, phoneNumber, bankDetails, address } =
      OriginalData?.supplierDetails || {};

    const { bankName, accountNumber, accountHolderName, branch, ifscCode } =
      bankDetails || {};

    const { city, stateInfo, countryInfo, pincode } = address || {};

    return (
      <Descriptions
        title="Supplier Bank Details "
        bordered
        column={1}
        size="small"
      >
        <Descriptions.Item label="GSTIN">{gstin}</Descriptions.Item>
        <Descriptions.Item label="CIN">{cin}</Descriptions.Item>
        <Descriptions.Item label="PAN">{pan}</Descriptions.Item>
        <Descriptions.Item label="Phone">{phoneNumber}</Descriptions.Item>
        <Descriptions.Item label="Bank Name">{bankName}</Descriptions.Item>
        <Descriptions.Item label="Account Number">
          {accountNumber}
        </Descriptions.Item>
        <Descriptions.Item label="Company Account Name">
          {accountHolderName}
        </Descriptions.Item>
        <Descriptions.Item label="Branch">{branch}</Descriptions.Item>
        <Descriptions.Item label="IFSC Code">{ifscCode}</Descriptions.Item>
        <Descriptions.Item label="City">{city}</Descriptions.Item>
        <Descriptions.Item label="State">
          {stateInfo?.stateName}
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          {countryInfo?.countryName}
        </Descriptions.Item>
        <Descriptions.Item label="Pincode">{pincode}</Descriptions.Item>
      </Descriptions>
    );
  };

  const ApproveInvoice = async () => {
    // try {
    //   const response = await invoiceApis.approveInvoice(invoiceNumber);
    //   if (response.status) {
    //     Swal.fire("Approved!", "Invoice has been approved", "success");
    //   }
    // } catch (error) {}
  };

  const DisapproveInvoice = async (reasonForRejection: string) => {
    try {
      //   const response = await invoiceApis.disapproveInvoice(
      //     invoiceNumber,
      //     reasonForRejection
      //   );
      //   if (response.status) {
      //     Swal.fire("Rejected!", "Invoice has been rejected", "success");
      //   }
    } catch (error) {}
  };

  const returnBillingDetails = () => {
    if (!OriginalData?.billingAddress) {
      return {};
    }
    const {
      deliveryLocationName,
      addressLine1,
      addressLine2,
      city,
      state,
      countryInfo,
      pincode,
      district,
    } = OriginalData?.billingAddress;
    const { cin, gstin, pan, phoneNumber } = OriginalData?.orgDetails;
    return {
      name: OriginalData?.orgName || "",
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      state: state,
      pincode: pincode,
      country: countryInfo,
      gstin: gstin || "",
      pan: pan || "",
      cin: cin || "",
    };
  };

  const returnSupplierDetails = () => {
    if (!OriginalData?.supplierDetails) {
      return {};
    }
    const { cin, gstin, pan, phoneNumber, bankDetails, address } =
      OriginalData?.supplierDetails || {};

    const { bankName, accountNumber, accountHolderName, branch, ifscCode } =
      bankDetails || {};

    const { city, stateInfo, countryInfo, pincode,addressline1,addressline2 } = address || {};
    return {
      name: OriginalData?.supplierName || "",
      addressLine1: addressline1 || "",
      addressLine2: addressline2 || "",
      city: city || "",
      state: stateInfo?.stateName || "",
      pincode: pincode || "",
      country: countryInfo?.countryName || "",
      gstin: gstin || "",
      pan: pan || "",
      cin: cin || "",
    };
  };
  const returnInvoiceDetails = () => {
    let deliveryAddressString = "";
    if (
      OriginalData?.deliveryAddressType != "Line Level" &&
      OriginalData?.deliveryAddress
    ) {
      const {
        deliveryLocationName,
        addressLine1,
        addressLine2,
        city,
        state,
        countryInfo,
        pincode,
        district,
      } = OriginalData?.deliveryAddress;
      deliveryAddressString = [
        deliveryLocationName,
        addressLine1,
        addressLine2,
        city + ", " + state + ", " + district,
        countryInfo + ", " + pincode,
      ]
        ?.filter(Boolean)
        .join("\n");
    } else {
      deliveryAddressString = "Line Level";
    }
    return {
      invoiceNumber: OriginalData?.invoiceId,
      invoiceDate: dayjs(OriginalData?.invoiceDate).format("DD-MMM-YYYY"),

      terms:
        materialData?.paymentTerms +
        (materialData?.advancePercentage
          ? `(${materialData?.advancePercentage}%)`
          : ""),
      customSONumber: "",
      ewayBillNumber: OriginalData?.ewbNo||"",
      placeofSupply: OriginalData?.deliveryAddress?.city || "",
      creditDays:
        materialData?.paymentTerms != "Cash and Carry"
          ? materialData?.creditDays
          : 0,
      deliveryAddressString,
      vehicleRefNo: "N/A",
      transporterName: "N/A",
      orderNumber: OriginalData?.orderId,
      orderDate: "N/A",
      driverNumber: "N/A",

      totals: {
        taxableAmount: formatDecimal(materialData?.netAmount),
        cgst: formatDecimal(materialData?.cgst),
        sgst: formatDecimal(materialData?.sgst),
        igst: formatDecimal(materialData?.igst),
        total: formatDecimal(materialData?.grossTotal),
      },
      lines: dataSource?.map((item: any, index: any) => {
        let itemDescription = item?.materialName;
        if (item?.description) {
          itemDescription += "\t" + item?.description;
        }
        let deliveryAddressString = "";
        if (
          OriginalData?.deliveryAddressType == "Line Level" &&
          item?.deliveryAddress
        ) {
          const {
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            state,
            countryInfo,
            pincode,
            district,
          } = OriginalData?.deliveryAddress;
          deliveryAddressString = [
            deliveryLocationName,
            addressLine1,
            addressLine2,
            city,
            state,
            countryInfo,
            pincode,
            district,
          ]
            ?.filter(Boolean)
            .join(",");
          itemDescription += "\n\nDelivery Address: " + deliveryAddressString;
        }

        return {
          sno: index + 1,
          itemDescription,
          hsnSac: item?.hsnCode || "",
          rate: Number(item?.unitPrice),
          cgstPercentage: Number(item?.cgstPercentage),
          cgstAmount: Number(item?.cgst),
          sgstPercentage: Number(item?.sgstPercentage),
          sgstAmount: Number(item?.sgst),
          igstPercentage: Number(item?.igstPercentage),
          igstAmount: Number(item?.igst),
          taxableAmount: Number(item?.grossTotal),
          qty: Number(item?.quantityInvoiced),
          uom: item?.uomName,
        };
      }),
    };
  };

  return (
    <div className="card-inner card-inner-lg">
      {sm && mobileView && (
        <div className="toggle-overlay" onClick={() => setSm(!sm)}></div>
      )}
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">
              <span>
                PO #{OriginalData?.orderId} | Invoice #{OriginalData?.invoiceId}{" "}
              </span>
            </BlockTitle>
          </BlockHeadContent>

          <RejectInvoice
            isModalVisible={invoiceDisapproveInvoice}
            setIsModalVisible={setInvoiceDisapproveInvoice}
            rejectInvoice={DisapproveInvoice}
          />

          <div className=" d-flex">
            {["Pending Approval"].includes(OriginalData?.invoiceStatus) &&
              !readOnly &&
              !showSubmitBtn && (
                <div className="actions clearfix">
                  <ul
                    className="m-auto d-flex align-items-center justify-content-center p-0"
                    style={{
                      flexDirection: "row-reverse",
                    }}
                  >
                    <li className="pt-0 pb-0">
                      <Button
                        color="success"
                        type="button"
                        onClick={() => {
                          ApproveInvoice();
                        }}
                      >
                        Approve
                      </Button>
                    </li>
                    <li className="pt-0 pb-0">
                      <Button
                        color="danger"
                        onClick={() => {
                          setInvoiceDisapproveInvoice(true);
                        }}
                        type="button"
                        className="btn-dim btn btn-secondary"
                      >
                        Reject
                      </Button>
                    </li>
                  </ul>
                </div>
              )}
            {showSubmitBtn && !readOnly ? (
              <div className="actions clearfix paddingLeft10">
                <ul
                  className="m-auto d-flex align-items-center justify-content-center p-0"
                  style={{
                    flexDirection: "row-reverse",
                  }}
                >
                  <li className="pt-0 pb-0">
                    <Button
                      color="primary"
                      type="button"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "Do you want to submit the changes?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, submit it!",
                          cancelButtonText: "No, cancel",
                        }).then(async (result) => {
                          if (result.isConfirmed) {
                            await SubmitChanges();
                          }
                        });
                      }}
                    >
                      Save Changes
                    </Button>
                  </li>
                  <li className="p-0">
                    <Button
                      color="secondary"
                      onClick={() => {
                        Swal.fire({
                          title: "Are you sure?",
                          text: "Do you want to revert the changes?",
                          icon: "warning",
                          showCancelButton: true,
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, revert it!",
                          cancelButtonText: "No, cancel",
                        }).then((result) => {
                          if (result.isConfirmed) {
                            fetchInvoiceData();
                            Swal.fire(
                              "Reverted!",
                              "Your changes have been reverted.",
                              "success"
                            );
                          }
                        });
                      }}
                      type="button"
                      className="btn-dim btn btn-secondary"
                    >
                      Revert Changes
                    </Button>
                  </li>
                </ul>
              </div>
            ) : (
              <div className="paddingLeft10">
                <ul className="m-auto d-flex align-items-center justify-content-center p-0">
                  <li className="pt-0 pb-0">
                    <PdfGenerator
                      orgDetails1={returnSupplierDetails()}
                      orgDetails2={returnBillingDetails()}
                      HeaderDetails={returnInvoiceDetails()}
                      buyerOrgLogo={OriginalData?.sellerOrgLogo}
                      hasIGST={Number(materialData?.igst) != 0}
                      TermsAndConditions={termsAndConditions}
                    />
                  </li>
                  <li className="pt-0 pb-0">
                    <Button
                      className="toggle"
                      color="primary"
                      onClick={async () => {
                      navigate(previousPath,{state:{...extra?.previousState}});
                      }}
                    >
                      <Icon name="arrow-left" />
                      <span>Back</span>
                    </Button>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </BlockBetween>
      </BlockHead>
      {isLoading == true ? (
        <>
          <Flex align="center" gap="middle" className="pageLoader">
            <Spin size="large" className="m-auto" />
          </Flex>
        </>
      ) : (
        <div className="content clearfix">
          <AntCollapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="General Information" key="1">
              <div className="tab-content mt-0">
                <div className={`tab-pane${tab === "1" && " active"}`}>
                  <Col className="justify-start w-100  flex-wrap  p-0">
                    <div className="w-30 p-1 pt-0 pb-0 border-end">
                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-30">Invoice Type</div>
                        <div className="data-value w-70">
                          {materialData?.invoiceType != "commercial" &&
                          !readOnly ? (
                            <Form.Item
                              name="invoiceType"
                              label={undefined}
                              className="w-100 paddingLeft10  p-0 m-0"
                              initialValue={materialData?.invoiceType}
                            >
                              <Select
                                placeholder="Select Invoice Type"
                                defaultValue={materialData?.invoiceType}
                                onChange={(e) => {
                                  setNewInvoiceType(e);
                                  setShowSubmitBtn(true);
                                }}
                              >
                                {materialData?.invoiceType == "dc" ? (
                                  <Option value="dc">Delivery Challan</Option>
                                ) : (
                                  <Option value="proforma">
                                    Proforma Invoice
                                  </Option>
                                )}
                                <Option value="commercial">
                                  Commercial Invoice
                                </Option>
                              </Select>
                            </Form.Item>
                          ) : (
                            <span>
                              {materialData?.invoiceType == "commercial"
                                ? "Commercial Invoice"
                                : materialData?.invoiceType == "dc"
                                ? "Delivery Challan"
                                : "Proforma Invoice"}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-30">Invoice Date</div>
                        <div className="data-value w-70">
                          {!readOnly ? (
                            <Form.Item
                              name="invoiceDate"
                              label={undefined}
                              className="w-100 paddingLeft10  p-0 m-0"
                              initialValue={materialData?.invoiceDate}
                            >
                              <DatePicker
                                className="w-100"
                                placeholder="Invoice Due Date"
                                maxDate={dayjs().add(1, "day")}
                                allowClear={false}
                                defaultValue={dayjs(materialData?.invoiceDate)}
                                onChange={(e) => {
                                  setNewInvoiceDate(e);
                                  setShowSubmitBtn(true);
                                }}
                              />
                            </Form.Item>
                          ) : (
                            <span>
                              {dayjs(materialData?.invoiceDate).format(
                                "DD-MMM-YYYY"
                              )}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="data-col align-center p-0 pt-1">
                        <div className="data-label w-30">Due Date</div>
                        <div className="data-value w-70">
                          {!readOnly ? (
                            <Form.Item
                              name="dueDate"
                              label={undefined}
                              className="w-100 paddingLeft10  p-0 m-0"
                              initialValue={materialData?.dueDate}
                            >
                              <DatePicker
                                className="w-100"
                                placeholder="Invoice Due Date"
                                defaultValue={dayjs(materialData?.dueDate)}
                                allowClear={false}
                                onChange={(e) => {
                                  setNewDueDate(e);
                                  setShowSubmitBtn(true);
                                }}
                                minDate={
                                  newInvoiceDate
                                    ? newInvoiceDate
                                    : dayjs(materialData?.invoiceDate)
                                }
                              />
                            </Form.Item>
                          ) : (
                            <span>
                              {dayjs(materialData?.dueDate).format(
                                "DD-MMM-YYYY"
                              )}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="data-col align-center pt-1">
                        <div className="data-label w-30">Approvals</div>
                        <div className="data-value w-70">
                          <ApprovalTimelineContent />
                        </div>
                      </div>
                    </div>

                    <div className="w-30  paddingLeft10 border-end">
                      <div className=" pt-0">
                        <div className="data-label w-90 mb-1">
                          Billing Address
                        </div>
                        <div className="data-value w-90">
                          <ShowBillingAddress
                            address={materialData?.billingAddress}
                          />
                        </div>
                      </div>
                    </div>
                    {materialData?.deliveryAddressId && (
                      <div className="w-40 paddingLeft10 ">
                        <div className=" pt-0">
                          <div className="data-label w-9 mb-1">
                            Delivery Address
                          </div>
                          <div className="data-value w-90">
                            <ShowDeliveryAddress
                              address={materialData?.deliveryAddress}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Col>
                </div>
              </div>
            </Panel>
            <Panel header="Material Information" key="2">
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - (extra?.reduceTableWidth||380) + "px",
                    margin: "0px auto",
                  }}
                >
                  <Form form={form} component={false}>
                    <Table
                      className="customTable"
                      columns={mergedColumns.map((col: any) => {
                        if (col.key == "actions") {
                          return {
                            ...col,
                            hidden: readOnly,
                          };
                        }
                        return col;
                      })}
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      dataSource={dataSource.map((item: any) => ({
                        ...item,
                        quantityInvoiced: Number(item?.quantityInvoiced),
                        unitPrice: Number(item?.unitPrice),
                        key: item?.lineNo,
                      }))}
                      scroll={{ x: 1000 }}
                      expandable={{
                        expandedRowRender: (record: any) => (
                          <div className="expandableBody">
                            <div style={{ flex: 1, textAlign: "left" }}>
                              <p style={{ margin: 0 }}>{record.description}</p>
                              {record.file && (
                                <div>
                                  <PaperClipOutlined /> &nbsp;
                                  <a
                                    href={record?.file?.fileName}
                                    download={record?.file?.fileName}
                                  >
                                    {record?.file?.fileName}
                                  </a>
                                </div>
                              )}
                            </div>
                            {materialData?.deliveryAddressType ===
                              "Line Level" &&
                              record.deliveryAddressId && (
                                <div
                                  className="lineAddress"
                                  style={{ flex: 1 }}
                                >
                                  <h6>Delivery Address</h6>
                                  <ShowDeliveryAddress
                                    address={record.deliveryAddress}
                                  />
                                </div>
                              )}
                          </div>
                        ),
                      }}
                      bordered
                      size="middle"
                      pagination={false}
                    />
                  </Form>
                </div>
                <div className="d-flex">
                  <Form form={form2} component={false}>
                    <Row className="mt-2 w-50">
                      {!readOnly && (
                        <div>
                          <div className="d-flex align-items-center">
                            <h6 className="m-1 mb-0">
                              Additional Charges (If Any)
                            </h6>
                            <div className="w-20 m-1 mb-0">
                              {editAdditionalCharges ? (
                                <a
                                  type={undefined}
                                  onClick={() => {
                                    if (
                                      additionalCharges.find(
                                        (field: any, ind: number) => {
                                          const name = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.name`
                                          );
                                          const price = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.price`
                                          );
                                          return !name || !price;
                                        }
                                      )
                                    ) {
                                      return;
                                    }

                                    setAdditionalCharges([
                                      ...additionalCharges,
                                      {
                                        name: "",
                                        price: null,
                                        id: nanoid(4),
                                      },
                                    ]);
                                  }}
                                  className="addNewItem"
                                >
                                  <PlusSquareOutlined />
                                </a>
                              ) : (
                                <a
                                  type={undefined}
                                  onClick={() => {
                                    setEditAdditionalCharges(true);
                                  }}
                                  className="addNewItem"
                                >
                                  <EditOutlined />
                                </a>
                              )}
                            </div>
                          </div>
                          {additionalCharges.map(
                            (field: any, index: number) => {
                              return (
                                <Row key={field.id} className="m-2 mb-0 w-100">
                                  <div className="w-50">
                                    {editAdditionalCharges ? (
                                      <Form.Item
                                        name={`AdditionalCharges.${field.id}.name`}
                                        label={
                                          <strong>
                                            Additional Charge Name
                                          </strong>
                                        }
                                        rules={[
                                          {
                                            required: true,
                                            message: "Charge Name is required",
                                          },
                                        ]}
                                        className="mb-0"
                                        initialValue={field.name}
                                      >
                                        <Input
                                          className="w-100"
                                          disabled={!editAdditionalCharges}
                                          autoComplete="off"
                                          defaultValue={field.name}
                                        />
                                      </Form.Item>
                                    ) : (
                                      <strong>{field.name}</strong>
                                    )}
                                  </div>
                                  <div className="w-30">
                                    {editAdditionalCharges ? (
                                      <Form.Item
                                        name={`AdditionalCharges.${field.id}.price`}
                                        label={<strong>Price</strong>}
                                        rules={[
                                          {
                                            required: true,
                                            message: "Price is required",
                                          },
                                        ]}
                                        className="mb-0"
                                        initialValue={Number(field.price)}
                                      >
                                        <InputNumber
                                          min={0}
                                          disabled={!editAdditionalCharges}
                                          defaultValue={Number(field.price)}
                                          className="w-100"
                                          formatter={(value) =>
                                            `${value}`.replace(
                                              /\B(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                          }
                                          parser={(value: any) =>
                                            value!.replace(/\$\s?|(,*)/g, "")
                                          }
                                          onKeyPress={(event) => {
                                            if (!/^[0-9]+$/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          autoComplete="off"
                                        />
                                      </Form.Item>
                                    ) : (
                                      <strong>
                                        {formatCurrency(
                                          field.price,
                                          currency?.currencyCode
                                        )}
                                      </strong>
                                    )}
                                  </div>
                                  <Col className="d-flex align-items-end w-15">
                                    {editAdditionalCharges && (
                                      <a
                                        href="#dropdownitem"
                                        onClick={() => {
                                          form2.setFieldsValue({
                                            [`AdditionalCharges.${field.id}.name`]:
                                              "",
                                            [`AdditionalCharges.${field.id}.price`]:
                                              null,
                                          });
                                          const newAdditionalCharges: any = [];
                                          additionalCharges.forEach(
                                            (fld: any, ind: number) => {
                                              if (fld.id != field.id) {
                                                const name =
                                                  form2.getFieldValue(
                                                    `AdditionalCharges.${fld.id}.name`
                                                  );
                                                const price =
                                                  form2.getFieldValue(
                                                    `AdditionalCharges.${fld.id}.price`
                                                  );
                                                if (name && price) {
                                                  newAdditionalCharges.push({
                                                    name,
                                                    price,
                                                    id: fld.id,
                                                  });
                                                }
                                              }
                                            }
                                          );
                                          setAdditionalCharges(
                                            newAdditionalCharges
                                          );
                                          setShowSubmitBtn(true);
                                        }}
                                        className="addNewItem"
                                      >
                                        <DeleteOutlined />
                                      </a>
                                    )}
                                  </Col>
                                </Row>
                              );
                            }
                          )}
                          {additionalCharges.length > 0 &&
                            editAdditionalCharges && (
                              <Button
                                className="m-4 mt-3 mb-0"
                                color="primary"
                                onClick={() => {
                                  const newAdditionalCharges: any = [];
                                  additionalCharges.forEach(
                                    (field: any, index: number) => {
                                      const name = form2.getFieldValue(
                                        `AdditionalCharges.${field?.id}.name`
                                      );
                                      const price = form2.getFieldValue(
                                        `AdditionalCharges.${field?.id}.price`
                                      );
                                      console.log(field?.id, name, price);

                                      if (name && price) {
                                        newAdditionalCharges.push({
                                          name,
                                          price,
                                          id: field?.id,
                                        });
                                      }
                                    }
                                  );
                                  setAdditionalCharges(() => {
                                    return newAdditionalCharges;
                                  });
                                  if (newAdditionalCharges.length != 0) {
                                    setEditAdditionalCharges(false);
                                  }

                                  setShowSubmitBtn(true);
                                }}
                              >
                                Save
                              </Button>
                            )}
                        </div>
                      )}
                    </Row>
                  </Form>

                  <ViewTableTotals
                    data={dataSource}
                    additionalCharges={additionalCharges}
                    invoice={true}
                  />
                </div>
                <Row className="m-4 justify-content-end ">
                  {signature?.isActive && (
                    <div className="signatureBtnWrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>Signature</strong>
                      </div>
                      <div
                        style={{
                          minHeight: signature?.image ? "0px" : "120px",
                          display: signature?.image ? "block" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {signature?.image && (
                          <>
                            <div className="signatueImgWrapper border">
                              <img
                                src={signature?.image}
                                alt="Signed"
                                style={{ width: "160px" }}
                              />
                            </div>

                            <div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="signatureLabel"
                                >
                                  Signature Label
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="signatureLabel"
                                  onChange={(e) => {}}
                                  disabled
                                  defaultValue={
                                    signature?.label || "Authorised Signatory"
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </Panel>
            <Panel header="Other Information" key="3">
              <div className="w-100 d-flex">
                <Descriptions
                  title=""
                  className="custom-description-title w-30"
                >
                  <Descriptions.Item label="Terms and Conditions">
                    <span
                      style={{
                        color: "#2263b3",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                      onClick={() => setTermsVisible(true)}
                    >
                      View
                    </span>
                  </Descriptions.Item>
                </Descriptions>

                <div className="w-30 m-3 mt-0 mb-0">
                  <div>
                    <strong>Invoice Attachment &nbsp; &nbsp;</strong>
                    {!readOnly && (
                      <Upload {...invoiceFileUploadProps}>
                        <CloudUploadOutlined />
                      </Upload>
                    )}
                  </div>
                  {InvoiceAttachment && !newInvoiceAttachment && (
                    <div>
                      <a
                        href={InvoiceAttachment?.filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {InvoiceAttachment?.fileName}
                      </a>
                    </div>
                  )}
                </div>

                <div className="w-30 m-3 mt-0 mb-0">
                  <strong>Attach Delivery Challan &nbsp; &nbsp;</strong>
                  {!readOnly && (
                    <Upload {...dcFileUploadProps} className="w-50">
                      <CloudUploadOutlined />
                    </Upload>
                  )}
                  {DCAttachment && !newDCAttachment && (
                    <div>
                      <a
                        href={DCAttachment?.filePath}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {DCAttachment?.fileName}
                      </a>
                    </div>
                  )}
                </div>

                <div className="w-30 m-3 mt-0 mb-0">
                  <strong>Bank Details &nbsp; &nbsp;</strong>
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setBankDetailsVisible(true)}
                  >
                    View
                  </span>
                </div>
              </div>
            </Panel>
          </AntCollapse>

          <AntModal
            title={undefined}
            visible={termsVisible}
            footer={null}
            onCancel={() => {
              setIsTermsAndConditionsChanged(false);
              setTermsVisible(false);
            }}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            <div className="d-flex">
              <h5 className="m-3">Terms and Conditions</h5>
              {!isTermsAndConditionsChanged && !readOnly && (
                <EditOutlined
                  onClick={() => setIsTermsAndConditionsChanged(true)}
                />
              )}
            </div>
            {isTermsAndConditionsChanged ? (
              <ReactQuill
                theme="snow"
                value={newTermsAndConditions}
                onChange={(data) => {
                  setNewTermsAndConditions(data);
                }}
                className="mb-3"
              />
            ) : termsAndConditions ? (
              <pre className="m-3">{termsAndConditions || ""}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No Terms and Conditions Available for this Invoice" />
              </div>
            )}

            {isTermsAndConditionsChanged && (
              <ul className="actions clearfix d-flex">
                <li>
                  <Button
                    color="primary"
                    type="submit"
                    onClick={() => {
                      setTermsVisible(false);
                      setIsTermsAndConditionsChanged(true);
                      setShowSubmitBtn(true);
                    }}
                  >
                    Submit
                  </Button>
                </li>
                <li className="m-3 mt-0">
                  <Button
                    color="primary"
                    onClick={() => {
                      setIsTermsAndConditionsChanged(false);
                      setNewTermsAndConditions(termsAndConditions);
                    }}
                    className="btn-dim btn btn-secondary"
                  >
                    Cancel
                  </Button>
                </li>
              </ul>
            )}
          </AntModal>

          <AntModal
            title={undefined}
            visible={bankDetailsVisible}
            footer={null}
            onCancel={() => {
              setBankDetailsVisible(false);
            }}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            <ShowSupplierBankInfo />
          </AntModal>
        </div>
      )}
    </div>
  );
}

const RejectInvoice = ({
  isModalVisible,
  setIsModalVisible,
  rejectInvoice,
}: any) => {
  const [form] = Form.useForm();

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        const reason = values.reason;
        rejectInvoice(reason); // Call your reject function here
        form.resetFields();
        setIsModalVisible(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <AntModal
      title="Reject Purchase Order"
      visible={isModalVisible}
      footer={null} // Remove the default footer buttons
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical" name="rejectForm">
        <Form.Item
          name="reason"
          label="Reason for rejection"
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <Input.TextArea placeholder="Type your reason here..." />
        </Form.Item>
        <div className="actions clearfix">
          <ul>
            <li>
              <Button color="primary" type="submit" onClick={handleOk}>
                Submit
              </Button>
            </li>
            <li>
              <Button
                color="primary"
                onClick={handleCancel}
                className="btn-dim btn btn-secondary"
              >
                Cancel
              </Button>
            </li>
          </ul>
        </div>
      </Form>
    </AntModal>
  );
};

export default InvoiceComponent;
