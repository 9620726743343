import React, { useState, useEffect, FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Content from "../../../../layout/content/Content";
import { Card, Badge, Row, Col, Button, Modal, ModalBody } from "reactstrap";
import Head from "../../../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../../components/Component";
import { Controller, FormProvider, useForm } from "react-hook-form";
import {
  PaperClipOutlined,
  DownloadOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  DeleteOutlined,
  UploadOutlined,
  PlusSquareOutlined,
  EditOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  Descriptions,
  Modal as AntModal,
  Spin,
  Flex,
  Drawer,
  Upload,
  Select,
  Popover,
  DatePicker,
  TableColumnsType,
  Timeline,
  Empty,
  UploadFile,
  List,
  Checkbox,
} from "antd";
import FormSelect from "../../../form-components/SelectComponent";
import FormInput from "../../../form-components/InputComponent";
import FormDatePicker from "../../../form-components/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import QuotationsAside from "../../QuotationsAside";
import statusMapper from "../../../purchase-orders/StatusMapper";
// import QuotationApprovalDrawer from "../invoice/QuotationApprovalDrawer";
import { nanoid } from "nanoid";
import Misc from "../../../masters/Misc";
import ReactQuill from "react-quill";
import { Option } from "antd/es/mentions";
import invoiceApis from "../../../../api/Invoice/invoice";
import Swal from "sweetalert2";
import CropperComponent from "../../../components/CropperComponent";
import SignatureModal from "../../../purchase-orders/SignatureModal";
import usersApis from "../../../../api/master/users";
import quotationApis from "../../../../api/master/quotations";
import { useLocation } from "react-router-dom";
// import QuotationApprovalDrawer from "./newQuotations/QuotationApprovalDrawer";
// import { AddressDetails, Material, SelectOptions } from "./newQuotations/types";
import Dragger from "antd/es/upload/Dragger";
import costCenterApis from "../../../../api/master/costCenter";
// import deliveryAddressApis from "../../api/master/deliveryAddress";
import MaterialModal from "../../MaterialModal";
import supplierApis from "../../../../api/master/supplier";
import { formatDecimal } from "../../../../utils/Utils";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import PdfGenerator from "./SellerToBuyerPDF";
import { count } from "console";

interface SelectOptions {
  label: string;
  value: string;
}

interface AddressDetails {
  id: any;
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateName: string;
  countryName: string;
  pincode: string;
}

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}: any) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              type: "number",
              min: 1,
              message: `${title} must > 0`,
            },
          ]}
          initialValue={Number(record[dataIndex])}
        >
          <InputNumber
            className="w-100"
            formatter={(value) => {
              return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }}
            parser={(value: any) => {
              return value.replace(/\$\s?|(,*)/g, "");
            }}
            onKeyPress={(event) => {
              if (!/^[0-9]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const { Panel } = AntCollapse;

const { FormatCurrency, beforeUpload } = Misc;
type Cess = {
  cessName: string;
  cessType?: string;
  cessPercentage?: string;
  cessAmount?: string;
};
interface TaxConfiguration {
  taxType: string;
  gstType?: string; //IGST or CGST+SGST
  cess?: Cess[];
}

interface CurrencyData {
  description: string;
  currency_code: string;
  currency_symbol: string;
  currency_format: string;
  example: string;
}

interface SuppliersDrawerProps {
  visible: boolean;
  onClose: () => void;
  rfqId: string;
  siteName: string;
  dataDrawer: any;
}

const SupplierDetailaDrawer: React.FC<SuppliersDrawerProps> = ({
  visible,
  onClose,
  rfqId,
  siteName,
  dataDrawer,
}) => {
  console.log("11111:::######");
  const { data: currentUser }: any = useSelector(
    (state: RootState) => state.currentUser
  );
  const [isLoading, setIsLoading] = useState(true);
  let { qid }: any = useParams();
  const navigate = useNavigate();
  const [OriginalData, setOriginalData] = useState<any>({});
  const [rfqStatus, setRfqStatus] = useState<any>("");
  const [sm, setSm] = useState(false);
  const [tab, setTab] = useState("1");
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const [taxConfiguration, setTaxConfiguration] = useState<TaxConfiguration>();
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const [materialData, setMaterialData] = useState<any>({});
  const [supplierList, setSupplierList] = useState<any>([]);
  const [attachments, setAttachments] = useState<any>([]);
  const [reqDocAttachments, setReqDocAttachments] = useState<any>([]);

  const [deletedAttachments, setDeletedAttachments] = useState<any>([]);
  const [newAttachments, setNewAttachments] = useState<any>([]);
  const [DMS, setDMS] = useState<any>([]);
  const [editLineRow, setEditLineRow] = useState<any>();
  const [generalInformationEdit, setGeneralInformationEdit] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState<any>("");
  const [newTermsAndConditions, setNewTermsAndConditions] = useState<any>("");
  const [instructions, setInstructions] = useState("");
  const [newInstructions, setNewInstructions] = useState<any>("");
  const [isInstructionsChanged, setIsInstructionsChanged] = useState(false);
  const [currentUserId, setCurrentUserId] = useState<any>(null);
  const [approverIds, setApproverIds] = useState<any>([]);
  const [isTermsAndConditionsChanged, setIsTermsAndConditionsChanged] =
    useState(false);

  const methods = useForm({
    mode: "onChange",
  });

  const [signature, setSignature] = useState<any>([]);

  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const [addNewRowModel, setAddNewRowModel] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [showSubmitBtm, setShowSubmitBtm] = useState(false);

  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [billingAddressList, setBillingAddressList] = useState<
    AddressDetails[]
  >([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [suppliers, setSuppliers] = useState<any[]>([]);
  const [selectedArrayData, setSelectedArrayData] = useState([]);

  const [currency, setCurrency] = useState<CurrencyData>({
    description: "",
    currency_code: "",
    currency_symbol: "",
    currency_format: "",
    example: "",
  });

  const [editingKey, setEditingKey] = useState<any | null>(null);

  const [updatedLineItems, setUpdatedLineItems] = useState<any>([]);

  const [editedData, setEditedData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [newReqAttachment, setNewReqAttachment] = useState<File[]>([]);

  const [fileNameMapping, setFileNameMapping] = useState<Map<string, string>>(
    new Map()
  );

  const isEditing = (record: any) => record.key == editingKey;

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  const [additionalCharges, setAdditionalCharges]: any = useState<any>([]);
  const [editAdditionalCharges, setEditAdditionalCharges] =
    useState<any>(false);

  const [newNegotiableType, setNewNegotiableType] = useState<any>("");

  const [newValidityDate, setNewValidityDate] = useState<any>();
  const [newPaymentType, setNewPaymentType] = useState<any>("");
  const [newPaymentDays, setNewPaymentDays] = useState<any>("");
  const [newPaymentPercentage, setNewPaymentPercentage] = useState<any>("");
  useEffect(() => {
    console.log("dataDrawer", dataDrawer);
    fetchDetails();
    // fetchDeliveryAddress();
    fetchCostCenter();
    fetchSites();
    viewChange();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  const handleAttachmentChange = (info: { fileList: UploadFile[] }) => {
    setNewAttachments((pre: any) => {
      return [...pre, ...info.fileList];
    });
    setShowSubmitBtm(true);
  };

  const fetchHtmlContentFromUrl = async (url: string) => {
    try {
      const response = await axios.get(url, {
        headers: {
          "Content-Type": "text/html",
        },
      });

      return response?.data;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // const fetchDeliveryAddress = async () => {
  //     try {
  //       const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
  //       if (status) {
  //         let deliveryAddressOptions = data.map((item: any) => ({
  //           label: item.deliveryLocationName,
  //           value: item.id.toString(),
  //         }));
  //         setDeliveryAddressOptions(deliveryAddressOptions);
  //         setDeliveryAddressList(data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  const fetchDetails = async () => {
    console.log("ssssss", dataDrawer);
    // fetchSuppliers();
    try {
      const res: any = dataDrawer;
      const { response } = res;
      if (dataDrawer) {
        const data: any = dataDrawer;
        console.log("response:::::@@@@@", data);
        setOriginalData(data.supplierInfo);
        console.log("data", data);
        setRfqStatus(data?.status);
        console.log("rfqStatus", data?.supplierInfo?.supplierHeader.status);

        if (data?.supplierInfo.supplierHeader.additionalChargesArray) {
          setAdditionalCharges(
            data?.supplierInfo.supplierHeader.additionalChargesArray?.map(
              (charge: any) => {
                const id = nanoid(4);
                form2.setFieldsValue({
                  [`AdditionalCharges.${id}.name`]: charge?.name,
                  [`AdditionalCharges.${id}.price`]: Number(charge?.price),
                });
                return {
                  ...charge,
                  id,
                };
              }
            )
          );
        }

        if (data?.supplierInfo?.supplierApprovals) {
          let ids = data?.supplierInfo?.supplierApprovals?.map(
            (item: any) => item?.userId
          );
          ids = ids?.filter((item: any) => item?.status != "Approved");
          setApproverIds(ids);
        }

        if (data?.supplierInfo?.supplierHeader?.dms?.length > 0) {
          setDMS(data?.supplierInfo?.supplierHeader?.dms);
          const _termsAndConditions =
            data?.supplierInfo?.supplierHeader?.dms?.find(
              (item: any) => item.moduleFileType == "termsAndConditions"
            );
          if (_termsAndConditions) {
            const data: any = await fetchHtmlContentFromUrl(
              _termsAndConditions?.filePath
            );
            setTermsAndConditions(data);
            setNewTermsAndConditions(data);
          }
          const _signature = data?.supplierInfo?.supplierHeader?.dms?.find(
            (item: any) => item.moduleFileType == "signature"
          );
          if (_signature) {
            setSignature({
              docId: _signature?.docId,
              fileName: _signature?.fileName,
              image: _signature?.filePath,
              moduleFileType: _signature?.moduleFileType,
              module: _signature?.module,
              label: data?.supplierInfo?.supplierHeader?.signatureLabel,
              isActive: true,
            });
            // setNewInstructions(data);
          }
          const _attachments = data?.supplierInfo?.supplierHeader?.dms?.filter(
            (item: any) => item.moduleFileType == "attachments"
          );
          console.log("_attachments", _attachments);
          if (_attachments) {
            console.log("11::::setAttachments");
            setAttachments(
              _attachments.map((attachment: any) => ({
                docId: attachment?.docId,
                fileName: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "attachments",
                isActive: true,
              }))
            );
          }
        }

        if (data?.supplierInfo?.supplierDocs?.length > 0) {
          // Access the dms arrays within supplierDocs
          const dmsArrays = data?.supplierInfo?.supplierDocs
            ?.map((doc: any) => doc.dms)
            .flat();
          console.log("dmsArrays", dmsArrays);

          const docsMap = new Map();
          data?.supplierInfo?.supplierDocs?.forEach((doc: any) => {
            if (doc.dms) {
              doc.dms.forEach((dmsItem: any) => {
                docsMap.set(dmsItem?.docId, doc?.requiredDocs);
              });
            }
          });

          const updatedDms = dmsArrays.map((dmsItem: any) => ({
            ...dmsItem,
            requiredDocs: docsMap?.get(dmsItem?.docId) || "",
          }));

          console.log("docsMap", docsMap);

          console.log("updatedDms", updatedDms);

          const _recDocAttachments = updatedDms?.filter(
            (item: any) => item.moduleFileType == "Supplier Docs"
          );
          console.log("_recDocAttachments", _recDocAttachments);
          if (_recDocAttachments) {
            const transformedAttachments = _recDocAttachments.map(
              (attachment: any) => ({
                docId: attachment?.docId,
                name: attachment?.fileName,
                link: attachment?.filePath,
                moduleFileType: attachment?.moduleFileType,
                module: attachment?.module,
                label: "Supplier Docs",
                requiredDocsName: attachment.requiredDocs,
                isActive: true,
              })
            );
            console.log("DMS:::LOGS:::", transformedAttachments);
            setReqDocAttachments(transformedAttachments);
          }
        }

        setMaterialData(data);
        const LineItems = data?.supplierInfo.supplierLines;
        console.log("LineItems", LineItems);
        setData(LineItems);
        setShowSubmitBtm(false);
        setDeletedAttachments([]);
        setNewAttachments([]);
        // setCurrency({
        //     description: currencyCode,
        //     currency_code: currencyCode,
        //     currency_symbol: currencySymbol,
        //     currency_format: currencyFormat,
        //     example: currencyCode,
        // });
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const ViewTableTotals = ({ data, additionalCharges, invoice }: any) => {
    console.log("ViewTableTotals", data, additionalCharges, invoice);
    let totalNetAmmount = 0,
      cgst = 0,
      sgst = 0,
      igst = 0,
      TotalAmount = 0;
    data?.forEach((item: any) => {
      const totalAmmount = Number(item.suppliedQty) * Number(item.price);
      console.log("totalAmmount", totalAmmount);
      cgst = cgst + (totalAmmount * Number(item.cgst)) / 100;
      sgst = sgst + (totalAmmount * Number(item.sgst)) / 100;
      igst = igst + (totalAmmount * Number(item.igst)) / 100;
      totalNetAmmount += totalAmmount;
    });
    console.log("totalNetAmmount::::", data);
    TotalAmount = totalNetAmmount + cgst + sgst + igst;
    console.log("totalNetAmmounttotalNetAmmounttotalNetAmmount", TotalAmount);
    additionalCharges?.forEach((item: any) => {
      TotalAmount = TotalAmount + Number(item.price);
    });
    return (
      <Row
        className={`${invoice && "w-50 mt-2"}`}
        style={{
          margin: "20px",
        }}
      >
        <Col className={`justify-end`}>
          <div className={`${invoice ? "w-100" : "w-40"}`}>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>Taxable Amount</strong>
              </div>
              <span className="r_align">
                {FormatCurrency(totalNetAmmount, "INR")}
              </span>
            </div>
            {
              (cgst&&sgst)?<>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>CGST</strong>
              </div>
              <span className="r_align">{FormatCurrency(cgst, "INR")}</span>
            </div>
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>SGST</strong>
              </div>
              <span className="r_align">{FormatCurrency(sgst, "INR")}</span>
            </div>
              </>:
            <div className="d-flex justify-content-between">
              <div className="w-50 justify-end">
                <strong>IGST</strong>
              </div>
              <span className="r_align">{FormatCurrency(igst, "INR")}</span>
            </div>
          }
            {additionalCharges?.map((item: any) => {
              if (!item.price || !item.price) return null;
              return (
                <div className="d-flex justify-content-between">
                  <div className="w-50 justify-end">
                    <strong>{item.name}</strong>
                  </div>
                  <span className="r_align">
                    {FormatCurrency(item.price, "INR")}
                  </span>
                </div>
              );
            })}
            <div
              className="d-flex justify-content-between"
              style={{ fontSize: "18px", marginTop: "10px" }}
            >
              <div className="w-50 justify-end">
                <strong>Total</strong>
              </div>
              <strong className="r_align">
                {FormatCurrency(TotalAmount, "INR")}
              </strong>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const fetchCostCenter = async () => {
    try {
      const { data, status } = await costCenterApis.fetchCostCenter();
      if (status) {
        const costCenterOptions = data.map((item: any) => ({
          label: item.costCenterName,
          value: item.id.toString(),
        }));
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    // {
    //     ...Table.EXPAND_COLUMN,
    //     fixed: "left",
    // },
    // {
    //     title: "MATERIAL ID",
    //     dataIndex: "materialId",
    //     key: "materialId",
    //     fixed: "left" as const,
    //     width: 120,
    // },
    {
      title: "CATEGORY",
      dataIndex: "categoryName",
      key: "categoryName",
      fixed: "left" as const,
      width: 150,
    },
    {
      title: "Material Name",
      dataIndex: "materialName",
      key: "materialName",
      width: 200,
    },
    // {
    //     title: "DESCRIPTION",
    //     dataIndex: "description",
    //     key: "description",
    //     width: 200,
    // },
    {
      title: "UOM",
      dataIndex: "uomName",
      key: "uomName",
      width: 150,
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "qty",
      width: 150,
    },
    {
      title: "Expected Delivery Date",
      dataIndex: "needByDate",
      width: 200,
      render: (text: any, record: any) =>
        `${dayjs(text).format("DD-MMM-YYYY")}`,
    },
    {
      title: "Supply Qty",
      dataIndex: `supplyQuantity`,
      key: "supplyQuantity",
      width: 120,
    },
    {
      title: "Min Purchase Qty",
      dataIndex: `minPurchaseQuantity`,
      key: "minPurchaseQuantity",
      width: 150,
    },
    {
      title: "HSN",
      dataIndex: `hsn`,
      key: "hsn",
      width: 120,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
      render: (text: any, record: any) =>
        `${new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
        }).format(text)}`,
    },
    {
      title: "SGST (%)",
      dataIndex: "sgst",
      key: "sgst",
      width: 200,
    },
    {
      title: "CGST (%)",
      dataIndex: "cgst",
      key: "cgst",
      width: 200,
    },
    {
      title: "IGST (%)",
      dataIndex: "igst",
      key: "igst",
      width: 200,
    },
    {
      title: "Lead Days",
      dataIndex: "leadDays",
      key: "leadDays",
      width: 250,
    },
    {
      title: "Line Total",
      dataIndex: "lineTotal",
      key: "lineTotal",
      width: 250,
      fixed: "right" as const,
      render: (text: any, record: any) =>
        `${new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
        }).format(text)}`,
    },
  ];

  const cancel = () => {
    setEditingKey(null);
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = OriginalData?.supplierApprovals;
    console.log("ApprovalData", OriginalData);
    if (!approvalsData || approvalsData?.length === 0) {
      return <Badge color="outline-danger">No</Badge>;
    }

    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.map((approval: any, index: number) => {
              const isApproved = approval.status == "APPROVED";
              const isRejected = approval.status == "REJECTED";
              return (
                <Timeline.Item
                  key={index}
                  dot={
                    isApproved ? (
                      <CheckCircleOutlined style={{ color: "green" }} />
                    ) : isRejected ? (
                      <CloseCircleOutlined style={{ color: "red" }} />
                    ) : undefined
                  }
                >
                  {approval?.userName}
                  <div className="designation">{approval?.roleName}</div>
                  {approval?.approvalDate && (
                    <div className="designation">
                      {dayjs(approval?.approvalDate).format("DD-MMM-YYYY")}
                    </div>
                  )}
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          <Badge color="outline-success">Yes</Badge>
        </span>
      </Popover>
    );
  };

  console.log(
    "OriginalData?.quotationInfo?.quotationHeader?.billingAddressName",
    OriginalData?.quotationInfo?.quotationHeader?.billingAddressName
  );

  const billingAddressContent = dataDrawer?.quotationInfo?.quotationHeader
    ?.billingAddressName ? (
    <div className="billingAddress_align">
      <p>
        {
          dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName
            ?.addressLine1
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName
            ?.addressLine2
        }
      </p>
      <p>
        {dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName?.city},{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName
            ?.district.districtName
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName?.state
            .stateName
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName
            ?.countryInfo.countryName
        }{" "}
        -{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName
            ?.pincode
        }
      </p>
    </div>
  ) : null;

  const orderDeliveryAddressContent = dataDrawer?.quotationInfo?.quotationHeader
    ?.deliveryAddressName ? (
    <div className="billingAddress_align">
      <p>
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.addressLine1
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.addressLine2
        }
      </p>
      <p>
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.cityName
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.district?.districtName
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName?.state
            ?.stateName
        }
        ,{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.countryInfo?.countryName
        }{" "}
        -{" "}
        {
          dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName
            ?.pincode
        }
      </p>
    </div>
  ) : null;

  useEffect(() => {
    if (editLineRow) {
      setAddNewRowModel(true);
    }
  }, [editLineRow]);

  const uploadProps = {
    beforeUpload: (file: File) => {
      // Update file name mapping with the requiredDocsName
      console.log("file", file, reqDocAttachments);
      const attachment = reqDocAttachments.find(
        (doc: any) => doc.name === file.name
      );
      if (attachment) {
        setFileNameMapping((prevMapping) =>
          new Map(prevMapping).set(file.name, attachment.requiredDocsName)
        );
      }
      setNewReqAttachment((prevFiles) => [...prevFiles, file]);
      setShowSubmitBtm(true);
      return false; // Prevent auto-upload
    },
    maxCount: 1, // Adjust according to your needs
  };

  // const handelPaymetTypeChange = (event: any) => {
  //     const value = event.target.value;
  //     setMaterialData((prevData: any) => {
  //         const updatedSupplierInfo = {
  //             ...prevData.supplierInfo,
  //             supplierHeader: {
  //                 ...prevData.supplierInfo?.supplierHeader,
  //                 paymentTerms: value === "Credit" ? "Credit" : prevData.supplierInfo?.supplierHeader?.paymentTerms
  //             }
  //         };

  //         return {
  //             ...prevData,
  //             supplierInfo: updatedSupplierInfo
  //         };
  //     });
  // }

  const returnBillingDetails = () => {
    if (!dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName) {
      return {};
    }
    let address =
      dataDrawer?.quotationInfo?.quotationHeader?.billingAddressName;
    if (!address) {
      return {};
    }
    return {
      name: currentUser?.orgName || "",
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.cityName,
      state: address?.state?.stateName,
      pincode: address.pincode,
      country: address?.countryInfo?.countryName,
      gstin: currentUser?.gstin || "",
      pan: currentUser?.pan || "",
      cin: currentUser?.cin || "",
    };
  };

  const returnSupplierDetails = () => {
    let seller = dataDrawer?.supplierInfo?.supplierHeader?.referencedSupplier;
    if (!seller) {
      return {};
    }
    return {
      name: dataDrawer?.supplierName,
      addressLine1: seller?.addressLine1,
      addressLine2: seller?.addressLine2,
      city: seller?.city,
      state: seller?.state,
      pincode: seller?.pincode,
      country: seller?.country,
      gstin: seller?.gstin || "",
      pan: seller?.pan || "",
      cin: seller?.cin || "",
    };
  };
  const returnRFQDetails = () => {
    const id = materialData?.deliveryAddressId;
    let address: any = {};
    if (!id) {
      address = {};
    }
    address = dataDrawer?.quotationInfo?.quotationHeader?.deliveryAddressName;
    return {
      rfqNumber: dataDrawer?.supplierInfo?.supplierHeader?.rfqId,
      rfqDate: dayjs(OriginalData?.orderDate).format("DD-MMM-YYYY"),
      terms:
        materialData?.supplierInfo?.supplierHeader?.paymentTerms +
        (materialData?.supplierInfo?.supplierHeader?.paymentPercentage
          ? `(${Number(
              materialData?.supplierInfo?.supplierHeader?.paymentPercentage
            )?.toFixed(1)}%)`
          : ""),
      expectedShipmentDate:
        dataDrawer?.quotationInfo?.quotationHeader?.deliveryDateType ==
        "ORDER_LEVEL"
          ? dayjs(
              dataDrawer?.quotationInfo?.quotationHeader?.deliveryDate
            ).format("DD-MMM-YYYY")
          : "Line Level",
      placeofSupply: address?.cityName || "",
      creditDays:
        materialData?.supplierInfo?.supplierHeader?.paymentTerms === "Credit"
          ? materialData?.supplierInfo?.supplierHeader?.paymentDays
          : 0,
      deliveryAddressString:
        [
          address?.addressLine1,
          address?.addressLine2,
          address?.cityName,
          address?.district?.districtName,
          address?.state?.stateName,
          address?.pincode?.toString(),
          address?.countryInfo?.countryName,
        ]
          ?.filter(Boolean)
          ?.join(", ") || "--",
      totals: {
        taxableAmount: formatDecimal(
          materialData?.supplierInfo?.supplierHeader?.netTotal
        ),
        cgst: formatDecimal(materialData?.supplierInfo?.supplierHeader?.cgst),
        sgst: formatDecimal(materialData?.supplierInfo?.supplierHeader?.sgst),
        igst: formatDecimal(materialData?.supplierInfo?.supplierHeader?.igst),
        total: formatDecimal(materialData?.supplierInfo?.supplierHeader?.gross),
      },
      lines: materialData?.supplierInfo?.supplierLines?.map(
        (item: any, index: any) => {
          let itemDescription = item?.materialName;
          if (item?.description) {
            itemDescription += "\t" + item?.description;
          }
          if (
            dataDrawer?.quotationInfo?.quotationHeader?.deliveryDateType !=
            "ORDER_LEVEL"
          ) {
            // let _address = deliveryAddressList?.find(
            //   (_item: any) => _item?.id == item?.deliveryAddressId
            // );
            // if (_address) {
            //   const addressString = [
            //     _address.addressLine1,
            //     _address.addressLine2,
            //     _address.city,
            //     _address.stateName,
            //     _address.pincode?.toString(),
            //     _address.countryName,
            //   ]
            //     .filter(Boolean)
            //     .join(", ");
            //   itemDescription += "\n\nDelivery Address: " + addressString;
            // }
          }
          // {
          //                 "id": 60,
          //                 "supplierQuoteId": 43,
          //                 "quotationLineId": 126,
          //                 "quotationId": 126,
          //                 "rfqId": "RFQ000030",
          //                 "version": 2,
          //                 "latest": 1,
          //                 "minQty": "1.00",
          //                 "suppliedQty": "4.00",
          //                 "supplyQuantity": "4.00",
          //                 "minPurchaseQuantity": "1.00",
          //                 "hsn": "1",
          //                 "price": "5.00",
          //                 "cgst": 0.01,
          //                 "sgst": 0.01,
          //                 "igst": 0.01,
          //                 "leadDays": 1,
          //                 "lineTotal": "20.01",
          //                 "createdBy": 124,
          //                 "materialId": 689,
          //                 "categoryId": 23,
          //                 "categoryName": "Concrete TMT",
          //                 "uomId": 12,
          //                 "uomName": "milligrams",
          //                 "quantity": 155,
          //                 "needByDate": "2024-09-21T00:00:00.000Z",
          //                 "quotationLines": {
          //                     "quotationLineId": 126,
          //                     "rfqLineId": "RQL000045",
          //                     "latest": true,
          //                     "materialId": 689,
          //                     "categoryId": 23,
          //                     "categoryName": "Concrete TMT",
          //                     "uomId": 12,
          //                     "uomName": "milligrams",
          //                     "quantity": 155,
          //                     "needByDate": "2024-09-21T00:00:00.000Z"
          //                 }
          //             },
          return {
            sno: index + 1,
            itemDescription,
            hsnSac: item?.hsn || "",
            rate: Number(item?.price),
            cgstPercentage: Number(item?.cgst),
            cgstAmount: Number(item?.cgstAmt),
            sgstPercentage: Number(item?.sgst),
            sgstAmount: Number(item?.sgstAmt),
            igstPercentage: Number(item?.igst),
            igstAmount: Number(item?.igstAmt),
            taxableAmount: Number(item?.lineTotal),
            qty: Number(item?.quantity),
            uom: item?.uomName,
          };
        }
      ),
    };
  };

  return (
    <>
      <Drawer
        // title={`Shortlisted Suppliers for RFQ: ${rfqId}, Site: ${siteName}`}
        title={
          <div className="d-flex justify-content-between align-items-center">
            <strong>{dataDrawer?.supplierName}</strong>
            <>
              <div style={{ textAlign: "right" }}>
                <PdfGenerator
                  buyerDetails={returnSupplierDetails()}
                  supplierDetails={returnBillingDetails()}
                  rfqDetails={returnRFQDetails()}
                  buyerOrgLogo={
                    dataDrawer?.supplierInfo?.supplierHeader?.referencedSupplier
                      ?.logoPath
                  }
                  hasIGST={
                    Number(materialData?.supplierInfo?.supplierHeader?.igst) !=
                    0
                  }
                  TermsAndConditions={termsAndConditions}
                />
              </div>
            </>
          </div>
        }
        placement="right"
        width="85vw"
        style={{
          position: "absolute",
          zIndex: 1100,
        }}
        styles={{ body: { paddingBottom: 80 } }}
        onClose={onClose}
        open={visible}
        // bodyStyle={{ paddingBottom: 80 }}
      >
        <Head title="View Quotation Details" />
        {/* <Content> */}
        {/* <Card className="card-bordered"> */}
        <div className="card-aside-wrap">
          {isLoading == true ? (
            <>
              <Flex align="center" gap="middle" className="pageLoader">
                <Spin size="large" className="m-auto" />
              </Flex>
            </>
          ) : (
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">
                      <span>
                        RFQ - {dataDrawer?.supplierInfo?.supplierHeader?.rfqId}{" "}
                        | {dataDrawer?.supplierInfo?.supplierHeader?.status}{" "}
                      </span>
                    </BlockTitle>
                  </BlockHeadContent>
                  <BlockHeadContent className="align-self-start d-lg-none">
                    <Button
                      className={`toggle btn btn-icon btn-trigger mt-n1 ${
                        sm ? "active" : ""
                      }`}
                      onClick={() => setSm(!sm)}
                    >
                      <Icon name="menu-alt-r" />
                    </Button>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>
              <div className="content clearfix">
                <AntCollapse defaultActiveKey={["1"]}>
                  <Panel
                    header={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <span>General Details</span>
                      </div>
                    }
                    key="1"
                  >
                    <Descriptions title="" className="custom-description-title">
                      <Descriptions.Item label="Quotation For">
                        {
                          materialData?.quotationInfo?.quotationHeader
                            ?.quotationFor
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="Description">
                        {
                          materialData?.quotationInfo?.quotationHeader
                            ?.quotationDescription
                        }
                      </Descriptions.Item>
                      <Descriptions.Item label="Site Name">
                        {materialData?.quotationInfo?.quotationHeader?.siteName}
                      </Descriptions.Item>
                      <Descriptions.Item label="Approvals">
                        {<ApprovalTimelineContent />}
                      </Descriptions.Item>
                      <Descriptions.Item label="Negotiable">
                        {" "}
                        {materialData?.supplierInfo?.supplierHeader
                          ?.negotiable == "Y"
                          ? "Yes"
                          : "No"}
                      </Descriptions.Item>
                      <Descriptions.Item label="validity Date">
                        {dayjs(
                          materialData?.supplierInfo?.supplierHeader
                            ?.validityDate
                        ).format("DD-MMM-YYYY")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Payment Terms">
                        {
                          materialData?.supplierInfo?.supplierHeader
                            ?.paymentTerms
                        }
                      </Descriptions.Item>
                      {materialData?.supplierInfo?.supplierHeader
                        ?.paymentTerms === "Credit" && (
                        <Descriptions.Item label="Credit Days">
                          {materialData?.supplierInfo?.supplierHeader
                            ?.paymentDays || "--"}
                        </Descriptions.Item>
                      )}
                      {materialData?.supplierInfo?.supplierHeader
                        ?.paymentTerms === "Advance" && (
                        <Descriptions.Item label="Payment %">
                          {materialData?.supplierInfo?.supplierHeader
                            ?.paymentPercentage || "--"}
                        </Descriptions.Item>
                      )}
                      <Descriptions.Item label="Billing Address">
                        <Popover
                          content={billingAddressContent}
                          title={
                            <span style={{ color: "#2263b3" }}>
                              Billing Address
                            </span>
                          }
                        >
                          <span
                            style={{
                              color: "#2263b3",
                              textDecoration: "underline",
                              cursor: "pointer",
                            }}
                          >
                            {
                              materialData?.quotationInfo?.quotationHeader
                                ?.billingAddressName?.deliveryLocationName
                            }
                            {/* {
                                                            OriginalData?.quotationHeader?.billingAddressName
                                                        } */}
                          </span>
                        </Popover>
                      </Descriptions.Item>
                      {/* <Descriptions.Item label="Delivery Address">
                                                    {materialData?.supplierInfo?.quotationInfo?.quotationHeader?.deliveryLevelType === "LINE_LEVEL"
                                                        ? "Line Level"
                                                        : "Order Level"}
                                                </Descriptions.Item> */}

                      {materialData?.quotationInfo?.quotationHeader
                        ?.deliveryLevelType === "ORDER_LEVEL" && (
                        <Descriptions.Item label="Delivery Address">
                          <Popover
                            content={orderDeliveryAddressContent}
                            title={
                              <span style={{ color: "#2263b3" }}>
                                Delivery Address
                              </span>
                            }
                          >
                            <span
                              style={{
                                color: "#2263b3",
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                            >
                              {
                                materialData?.quotationInfo?.quotationHeader
                                  ?.deliveryAddressName?.deliveryLocationName
                              }
                            </span>
                          </Popover>
                        </Descriptions.Item>
                      )}
                    </Descriptions>
                  </Panel>
                  <Panel header="Materials" key="2">
                    <div className="table-responsive poTable">
                      <div
                        style={{
                          width: screenSize - 400 + "px",
                          margin: "0px auto",
                        }}
                      >
                        <Form form={form} component={false}>
                          <Table
                            // columns={columns}
                            columns={columns}
                            dataSource={data.map((item: any) => ({
                              ...item,
                              key: item.quotationLineId,
                            }))}
                            scroll={{ x: 1000 }}
                            components={{
                              body: {
                                cell: EditableCell,
                              },
                            }}
                            bordered
                            size="middle"
                            pagination={false}
                          />
                        </Form>
                      </div>
                      <div className="d-flex">
                        <Form form={form2} component={false}>
                          <Row className="mt-2 w-50">
                            <div>
                              <div className="d-flex align-items-center">
                                <h6 className="m-1 mb-0">
                                  Additional Charges (If Any)
                                </h6>
                                {/* <div className="w-20 m-1 mb-0">
                                                                        {editAdditionalCharges ? (
                                                                            <a
                                                                                type={undefined}
                                                                                onClick={() => {
                                                                                    if (
                                                                                        additionalCharges.find(
                                                                                            (field: any, ind: number) => {
                                                                                                const name = form2.getFieldValue(
                                                                                                    `AdditionalCharges.${field?.id}.name`
                                                                                                );
                                                                                                const price = form2.getFieldValue(
                                                                                                    `AdditionalCharges.${field?.id}.price`
                                                                                                );
                                                                                                return !name || !price;
                                                                                            }
                                                                                        )
                                                                                    ) {
                                                                                        return;
                                                                                    }

                                                                                    setAdditionalCharges([
                                                                                        ...additionalCharges,
                                                                                        {
                                                                                            name: "",
                                                                                            price: null,
                                                                                            id: nanoid(4),
                                                                                        },
                                                                                    ]);
                                                                                }}
                                                                                className="addNewItem"
                                                                            >
                                                                                <PlusSquareOutlined />
                                                                            </a>
                                                                        ) : (
                                                                            <a
                                                                                type={undefined}
                                                                                onClick={() => {
                                                                                    setEditAdditionalCharges(true);
                                                                                }}
                                                                                className="addNewItem"
                                                                            >
                                                                                <EditOutlined />
                                                                            </a>
                                                                        )}
                                                                    </div> */}
                              </div>

                              {additionalCharges?.map(
                                (field: any, index: number) => {
                                  return (
                                    <Row
                                      key={field.id}
                                      className="m-2 mb-0 w-100"
                                    >
                                      <div className="w-50">
                                        <strong>{field.name}</strong>
                                      </div>
                                      <div className="w-30">
                                        <strong>
                                          {FormatCurrency(field.price, "INR")}
                                        </strong>
                                      </div>
                                      <Col className="d-flex align-items-end w-15">
                                        {editAdditionalCharges && (
                                          <a
                                            href="#dropdownitem"
                                            onClick={() => {
                                              form2.setFieldsValue({
                                                [`AdditionalCharges.${field.id}.name`]:
                                                  "",
                                                [`AdditionalCharges.${field.id}.price`]:
                                                  null,
                                              });
                                              const newAdditionalCharges: any =
                                                [];
                                              additionalCharges.forEach(
                                                (fld: any, ind: number) => {
                                                  if (fld.id != field.id) {
                                                    const name =
                                                      form2.getFieldValue(
                                                        `AdditionalCharges.${fld.id}.name`
                                                      );
                                                    const price =
                                                      form2.getFieldValue(
                                                        `AdditionalCharges.${fld.id}.price`
                                                      );
                                                    if (name && price) {
                                                      newAdditionalCharges.push(
                                                        {
                                                          name,
                                                          price,
                                                          id: fld.id,
                                                        }
                                                      );
                                                    }
                                                  }
                                                }
                                              );
                                              setAdditionalCharges(
                                                newAdditionalCharges
                                              );
                                              setShowSubmitBtm(true);
                                            }}
                                            className="addNewItem"
                                          >
                                            <DeleteOutlined />
                                          </a>
                                        )}
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                              {additionalCharges?.length > 0 &&
                                editAdditionalCharges && (
                                  <Button
                                    className="m-4 mt-3 mb-0"
                                    color="primary"
                                    onClick={() => {
                                      const newAdditionalCharges: any = [];
                                      additionalCharges.forEach(
                                        (field: any, index: number) => {
                                          const name = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.name`
                                          );
                                          const price = form2.getFieldValue(
                                            `AdditionalCharges.${field?.id}.price`
                                          );
                                          console.log(field?.id, name, price);

                                          if (name && price) {
                                            newAdditionalCharges.push({
                                              name,
                                              price,
                                              id: field?.id,
                                            });
                                          }
                                        }
                                      );
                                      setAdditionalCharges(() => {
                                        return newAdditionalCharges;
                                      });
                                      if (newAdditionalCharges?.length != 0) {
                                        setEditAdditionalCharges(false);
                                      }

                                      setShowSubmitBtm(true);
                                    }}
                                  >
                                    Save
                                  </Button>
                                )}
                            </div>
                          </Row>
                        </Form>

                        <ViewTableTotals
                          data={data}
                          additionalCharges={additionalCharges}
                          invoice={true}
                        />
                      </div>
                      <Row className="m-4 justify-content-end ">
                        {signature?.isActive && (
                          <div className="signatureBtnWrapper">
                            <div className="d-flex justify-content-between align-items-center">
                              <strong>Signature</strong>
                            </div>
                            <div
                              style={{
                                minHeight: signature?.image ? "0px" : "120px",
                                display: signature?.image ? "block" : "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {signature?.image && (
                                <>
                                  <div className="signatueImgWrapper border">
                                    <img
                                      src={signature?.image}
                                      alt="Signed"
                                      style={{ width: "160px" }}
                                    />
                                  </div>

                                  <div>
                                    <div className="form-group">
                                      <label
                                        className="form-label"
                                        htmlFor="signatureLabel"
                                      >
                                        Signature Label
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="signatureLabel"
                                        onChange={(e) => {}}
                                        disabled
                                        defaultValue={
                                          signature?.label ||
                                          "Authorised Signatory"
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </Row>
                    </div>
                  </Panel>
                  <Panel header="Other Information" key="3">
                    <Descriptions title="" className="custom-description-title">
                      <Descriptions.Item label="">
                        <div>
                          <strong className="">
                            Required Documents &nbsp;
                          </strong>
                          {reqDocAttachments.map((docs: any) => (
                            <div
                              key={docs.docId}
                              className="w-auto m-3 mt-2 mb-0"
                            >
                              {/* {JSON.stringify(docs)} */}
                              <div>
                                <strong>
                                  {docs.requiredDocsName} &nbsp; &nbsp;
                                </strong>
                                <Upload {...uploadProps} id={docs.docId}>
                                  <CloudUploadOutlined />
                                </Upload>
                                {docs &&
                                  !newReqAttachment.some(
                                    (file: any) => file.name === docs.name
                                  ) && (
                                    <a
                                      href={docs?.link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {docs?.name}
                                    </a>
                                  )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item label="Terms and Conditions">
                        <span
                          style={{
                            color: "#2263b3",
                            textDecoration: "underline",
                            cursor: "pointer",
                          }}
                          onClick={() => setTermsVisible(true)}
                        >
                          View
                        </span>
                      </Descriptions.Item>

                      <Descriptions.Item label="">
                        <div>
                          <strong className="">
                            Attachments &nbsp;
                            <PaperClipOutlined />
                          </strong>
                          <div>
                            {attachments?.map(
                              (attachment: any, index: number) => (
                                <div
                                  key={index}
                                  className="w-100 d-flex justify-content-between"
                                >
                                  <div>
                                    <strong>{index + 1}.</strong>
                                    &nbsp;
                                    {attachment.fileName}
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                  </div>
                                  <div>
                                    <a
                                      href={attachment.link}
                                      download={attachment.link}
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                      }}
                                    >
                                      <DownloadOutlined />
                                    </a>
                                    &nbsp; &nbsp;
                                    <a
                                      style={{
                                        color: "red",
                                        fontSize: "16px",
                                      }}
                                      onClick={() => {
                                        setDeletedAttachments((prev: any) => [
                                          ...prev,
                                          { docId: attachment?.docId },
                                        ]);
                                        let updatedAttachmentsList =
                                          attachments?.filter(
                                            (item: any, i: number) => i != index
                                          );
                                        setAttachments(updatedAttachmentsList);
                                        setShowSubmitBtm(true);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </a>
                                  </div>
                                </div>
                              )
                            )}
                            {newAttachments?.map(
                              (attachment: any, index: number) => (
                                <div
                                  key={index}
                                  className="w-100 d-flex justify-content-between"
                                >
                                  <div>
                                    <strong>
                                      {attachments?.length + index + 1}.
                                    </strong>
                                    &nbsp;
                                    {attachment?.name}
                                    &nbsp; &nbsp; &nbsp; &nbsp;
                                  </div>
                                  <div>
                                    <a
                                      href={attachment.link}
                                      download={attachment.link}
                                      style={{
                                        color: "black",
                                        fontSize: "18px",
                                      }}
                                    >
                                      <DownloadOutlined />
                                    </a>
                                    &nbsp; &nbsp;
                                    <a
                                      style={{
                                        color: "red",
                                        fontSize: "16px",
                                      }}
                                      onClick={() => {
                                        setNewAttachments((prev: any) =>
                                          prev.filter(
                                            (item: any, i: number) => i != index
                                          )
                                        );
                                        setShowSubmitBtm(true);
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </a>
                                  </div>
                                </div>
                              )
                            )}
                            {/* {(
                                                                <div className="mt-1">
                                                                    <Dragger
                                                                        beforeUpload={beforeUpload}
                                                                        fileList={[]}
                                                                        onChange={handleAttachmentChange}
                                                                    >
                                                                        <a
                                                                            onClick={() => { }}
                                                                            className="addNewItem"
                                                                        >
                                                                            <Icon name="plus-round" />
                                                                            <span>Add Attachment</span>
                                                                        </a>
                                                                    </Dragger>
                                                                </div>
                                                            )} */}
                          </div>
                        </div>
                      </Descriptions.Item>

                      {/* {suppliers &&
                                                    <Descriptions.Item label="Suppliers">
                                                        {suppliers.map((item) => (
                                                            <li>
                                                                {item.supplierName}
                                                            </li>
                                                        )
                                                        )}
                                                    </Descriptions.Item>
                                                } */}
                    </Descriptions>
                  </Panel>
                </AntCollapse>
              </div>
            </div>
          )}
        </div>
        {/* </Card> */}
        {/* </Content> */}
        <AntModal
          title={undefined}
          visible={instructionsVisible}
          footer={null}
          onCancel={() => {
            setIsInstructionsChanged(false);
            setInstructionsVisible(false);
          }}
          width="60%"
          bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        >
          {/* <div className="d-flex">
                    <h5 className="m-3">Instructions </h5>
                    {!isInstructionsChanged &&
                        ["Pending", "Draft", "REQUESTED"].includes(rfqStatus) && (
                            <EditOutlined onClick={() => setIsInstructionsChanged(true)} />
                        )}
                </div> */}
          {["Pending", "Draft", "REQUESTED"].includes(rfqStatus) &&
          isInstructionsChanged ? (
            <ReactQuill
              theme="snow"
              value={newInstructions}
              onChange={(data) => {
                setNewInstructions(data);
              }}
              className="mb-3"
            />
          ) : instructions ? (
            <div
              dangerouslySetInnerHTML={{
                __html: instructions || "",
              }}
              className="m-3"
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Empty description="No Instructions Available for this Purchase Order" />
            </div>
          )}
          {isInstructionsChanged && (
            <ul className="actions clearfix d-flex">
              <li>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setInstructionsVisible(false);
                    setIsInstructionsChanged(true);
                    setShowSubmitBtm(true);
                  }}
                >
                  Submit
                </Button>
              </li>
              <li className="m-3 mt-0">
                <Button
                  color="primary"
                  onClick={() => {
                    setIsInstructionsChanged(false);
                    setNewInstructions(instructions);
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Cancel
                </Button>
              </li>
            </ul>
          )}
        </AntModal>
        <AntModal
          title={undefined}
          visible={termsVisible}
          footer={null}
          onCancel={() => {
            setIsTermsAndConditionsChanged(false);
            setTermsVisible(false);
          }}
          width="60%"
          bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
        >
          <div className="d-flex">
            <h5 className="m-3">Terms and Conditions</h5>
          </div>
          {isTermsAndConditionsChanged ? (
            <ReactQuill
              theme="snow"
              value={newTermsAndConditions}
              onChange={(data) => {
                setNewTermsAndConditions(data);
              }}
              className="mb-3"
            />
          ) : termsAndConditions ? (
            <div
              dangerouslySetInnerHTML={{
                __html: termsAndConditions || "",
              }}
              className="m-3"
            />
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <Empty description="No Terms and Conditions Available for this Purchase Order" />
            </div>
          )}

          {isTermsAndConditionsChanged && (
            <ul className="actions clearfix d-flex">
              <li>
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => {
                    setTermsVisible(false);
                    setIsTermsAndConditionsChanged(true);
                    setShowSubmitBtm(true);
                  }}
                >
                  Submit
                </Button>
              </li>
              <li className="m-3 mt-0">
                <Button
                  color="primary"
                  onClick={() => {
                    setIsTermsAndConditionsChanged(false);
                    setNewTermsAndConditions(termsAndConditions);
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Cancel
                </Button>
              </li>
            </ul>
          )}
        </AntModal>
      </Drawer>
    </>
  );
};

export default SupplierDetailaDrawer;
