import React from "react";
import { useNavigate } from "react-router";
import FormatDate from "../../masters/DateFormater";
import { Icon, TooltipComponent } from "../../../components/Component";
import DefaultReportTemplate from "../defaultReportTemplate";
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function InvoiceBySupplierReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      key: "orderId",
      title: "Order #",
      dataIndex: "orderId",
      width: 200,
      // fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/purchase-order-report-preview/${record.orderNumber}`, {
                state: { previousPath: "/invoice-by-supplier-reports-list" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice #",
      dataIndex: "invoiceId",
      key: "invoiceId",
      width: 200,
      fixed: "left",
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/invoice-preview-report/${record.invoiceNumber}`, {
                state: { previousPath: "/invoice-by-supplier-reports-list" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      width: 100,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "Created By",
      dataIndex: "createdByName",
      key: "createdByName",
      width: 200,
    },
    {
      title: "Creation Date",
      dataIndex: "creationDate",
      key: "creationDate",
      width: 100,
      render: (date: any) => <span>{FormatDate(date) || "N/A"}</span>,
    },
    {
      title: "GRN #",
      dataIndex: "deliveryNoteId",
      key: "deliveryNoteId",
      width: 100,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/grn-preview-report/${record.grnNumber}`, {
                state: { previousPath: "/invoice-by-supplier-reports-list" },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "GRN Date",
      dataIndex: "reqDeliveryDate",
      key: "reqDeliveryDate",
      width: 100,
      render: (date: any) => <span>{date?FormatDate(date):"N/A"}</span>,
    },
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      width: 200,
    },
    {
      title: "Invoice Value with Tax",
      dataIndex: "amount",
      key: "amount",
      width: 200,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Delivery Address",
      dataIndex: "deliveryAddress",
      key: "deliveryAddress",
      width: 250,
      render: (text: string, record: any, index: any) => {
        return (
          <>
            <span id={`desc-tooltip-${index}`}>
              {text.length > 20 ? `${text!.substring(0, 25)}... ` : text}
            </span>
            {text.length > 20 && (
              <TooltipComponent
                iconClass="info-circle text-info"
                icon="info"
                id={`desc-tooltip-${index}`}
                direction="top"
                text={text}
                containerClassName={""}
              />
            )}
          </>
        );
      },
    },
    // {
    //   title: "Site ID",
    //   dataIndex: "siteId",
    //   key: "siteId",
    //   width: 100,
    //   sorter: (a: any, b: any) => a.siteId - b.siteId,
    // },
    {
      title: "Site Name",
      dataIndex: "siteName",
      key: "siteName",
      width: 200,
    },
    {
      title: "Invoice Status",
      dataIndex: "invoiceStatus",
      key: "invoiceStatus",
      width: 100,
    },
    // {
    //   title: "Currency Code",
    //   dataIndex: "currencyCode",
    //   key: "currencyCode",
    //   width: 100,
    //   sorter: (a: any, b: any) =>
    //     a.currencyCode.localeCompare(b.currencyCode),
    // },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Invoice By Supplier"
      moduleKey="invoiceBySupplierReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        supplier: true,
      }}
      previousPath="/view-purchase-reports"
    />
  );
}

export default InvoiceBySupplierReport;
