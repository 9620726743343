import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Button, Badge, ModalBody, Spinner } from "reactstrap";
import Head from "../../layout/head/Head";
import { useForm, useFormContext } from "react-hook-form";
import {
  Table,
  Collapse as AntCollapse,
  Button as AntButton,
  TableColumnsType,
  Descriptions,
  Modal,
  Timeline,
  Popover,
  Empty,
} from "antd";
import costCenterApis from "../../api/master/costCenter";
import GeneralLedgerApis from "../../api/master/generalLedger";
import departments from "../../api/master/departments";
import deliveryAddressApis from "../../api/master/deliveryAddress";
import adminOrgApis from "../../api/master/adminOrg";
import { PaperClipOutlined, DownloadOutlined } from "@ant-design/icons";
import Swal from "sweetalert2";
import purchaseOrderCreation from "../../api/purchase-orders/pruchaseOrderCreation";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router";
import rolesApi from "../../api/master/roles";
import dayjs from "dayjs";
import usersApis from "../../api/master/users";
import budgetApis from "../../api/master/budget";
import { useDispatch } from "react-redux";
import { fetchSequenceAccess } from "../../store/slices/misc/sequenceAccess";
import PdfGenerator from "./TestPDF";
import { useSelector } from "react-redux";
import {formatCurrency, formatDecimal } from "../../utils/Utils";
import contractorsApis from "../../api/master/contractors";

interface SelectOptions {
  label: string;
  value: string;
}

const { Panel } = AntCollapse;

const PurchaseOrder_Preview: React.FC = (props: any) => {
  const [orderData, setOrderData] = useState<any>({});
  const [contractorOptions, setContractorOptions] = useState<SelectOptions[]>([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { data: currentUser } = useSelector((state: any) => state.currentUser);
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [tab, setTab] = useState("1");
  const [instructionsVisible, setInstructionsVisible] = useState(false);
  const [termsVisible, setTermsVisible] = useState(false);
  const { watch, register } = useFormContext();
  const [taxConfigurationObj, setTaxConfigurationObj] = useState<any>({});
  const [sm, setSm] = useState(false);
  const [screenSize, setScreenSize] = useState(0);
  const [mobileView, setMobileView] = useState(false);
  const { control, handleSubmit } = useForm();
  const [supplierList, setSupplierList] = useState<any>([]);
  const [supplierOptions, setSupplierOptions] = useState<SelectOptions[]>([]);
  const [costCenterOptions, setCostCenterOptions] = useState<SelectOptions[]>(
    []
  );
  const [sitesOptions, setSitesOptions] = useState<SelectOptions[]>([]);
  const [generalLedgerOptions, setGeneralLedgerOptions] = useState<
    SelectOptions[]
  >([]);
  const [departmentOptions, setDepartmentOptions] = useState<SelectOptions[]>(
    []
  );
  const [budgetOptions, setBudgetOptions] = useState<SelectOptions[]>([]);
  const [deliveryAddressList, setDeliveryAddressList] = useState<any>([]);
  const [billingAddressList, setBillingAddressList] = useState<any>([]);
  const [billingAddressOptions, setBillingAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [deliveryAddressOptions, setDeliveryAddressOptions] = useState<
    SelectOptions[]
  >([]);
  const [roles, setRoles] = useState<any>([]);
  const [currency, setCurrency] = useState<any>({
    currencyFormat: "₹#,##0.00",
    currencySymbol: "₹",
    currency_code: "INR",
  });
  const [signature, setSignature] = useState<any>([]);

  const previousFormData = watch();

  const returnPoDetails = () => {
    const id = previousFormData?.deliveryAddressId;
    let address: any = {};
    if (!id) {
      address = {};
    }
    address = deliveryAddressList?.find((item: any) => item?.id == id);
    return {
      poNumber: orderData?.orderNumber || "N/A",
      poDate: dayjs().format("DD-MMM-YYYY"),
      terms:
        previousFormData?.paymentTerms +
        (previousFormData?.advancePercentage
          ? `(${previousFormData?.advancePercentage}%)`
          : ""),
      expectedShipmentDate:
        previousFormData?.needByDateType == "Order Level"
          ? dayjs(previousFormData?.needByDate).format("DD-MMM-YYYY")
          : "Line Level Date",
      placeofSupply: address?.city || "",
      creditDays:
        previousFormData?.paymentTerms != "Cash and Carry"
          ? previousFormData?.creditDays
          : 0,
      deliveryAddressString: [
        address?.addressLine1,
        address?.addressLine2,
        address?.city,
        address?.stateName,
        address?.pincode?.toString(),
        address?.countryName,
      ]
        ?.filter(Boolean)
        ?.join(", "),
      totals: {
        taxableAmount: formatDecimal(
          previousFormData?.items.reduce(
            (a: any, b: any) => a + Number(b.amount),
            0
          )
        ),
        cgst: formatDecimal(
          previousFormData?.items.reduce(
            (a: any, b: any) => a + Number(b.cgstAmount),
            0
          )
        ),
        sgst: formatDecimal(
          previousFormData?.items.reduce(
            (a: any, b: any) => a + Number(b.sgstAmount),
            0
          )
        ),
        igst: formatDecimal(
          previousFormData?.items.reduce(
            (a: any, b: any) => a + Number(b.igstAmount),
            0
          )
        ),
        total: formatDecimal(
          previousFormData?.items.reduce(
            (a: any, b: any) => a + Number(b.lineTotal),
            0
          )
        ),
      },
      lines: previousFormData?.items?.map((item: any, index: any) => {
        let itemDescription = item?.materialLabel;
        if (item?.description) {
          itemDescription += "\t" + item?.description;
        }
        if (previousFormData?.deliveryAddressType === "Line Level") {
          let _address = deliveryAddressList?.find(
            (_item: any) => _item?.id == item?.deliveryAddressId
          );
          if (_address) {
            const addressString = [
              _address.addressLine1,
              _address.addressLine2,
              _address.city,
              _address.stateName,
              _address.pincode?.toString(),
              _address.countryName,
            ]
              .filter(Boolean)
              .join(", ");
            itemDescription += "\n\nDelivery Address: " + addressString;
          }
        }
        if(previousFormData?.needByDateType !="Order Level"){
          itemDescription += "\n\nNeed By Date: " + dayjs(item?.needByDate).format("DD-MMM-YYYY");
        }
        return {
          sno: index + 1,
          itemDescription,
          hsnSac: item?.hsnCode || "",
          rate: Number(item?.unitPrice),
          cgstPercentage: Number(item?.cgstPercentage),
          cgstAmount: Number(item?.cgstAmount),
          sgstPercentage: Number(item?.sgstPercentage),
          sgstAmount: Number(item?.sgstAmount),
          igstPercentage: Number(item?.igstPercentage),
          igstAmount: Number(item?.igstAmount),
          taxableAmount: Number(item?.lineTotal),
          qty: Number(item?.quantity),
          uom: item?.uomLabel,
        };
      }),
    };
  };

  const returnBillingDetails = () => {
    const id = previousFormData?.billingAddressId;
    if (!id) {
      return {};
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return {};
    }
    return {
      name: currentUser?.orgName || "",
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      city: address.city,
      state: address.stateName,
      pincode: address.pincode,
      country: address.countryName,
      gstin: currentUser?.gstin || "",
      pan: currentUser?.pan || "",
      cin: currentUser?.cin || "",
    };
  };

  const returnSupplierDetails = () => {
    const id = previousFormData?.supplierId;
    if (!id) {
      return {};
    }
    let seller = supplierList?.find(
      (item: any) => item?.sellerOrgId == id
    )?.sellerOrg;
    if (!seller) {
      return {};
    }
    return {
      name: seller?.name || "",
      addressLine1: seller?.address?.area || "",
      addressLine2: seller?.address?.district?.districtName || "",
      city: seller?.address?.city || "",
      state: seller?.address?.state?.stateName || "",
      pincode: seller?.address?.pincode || "",
      country: seller?.address?.country?.countryName || "",
      gstin: seller?.gstin || "",
      pan: seller?.pan || "",
      cin: seller?.cin || "",
    };
  };

  const [columns, setColumns] = useState<TableColumnsType<any>>([
    {
      ...Table.EXPAND_COLUMN,
      fixed: "left",
    },
    {
      title: "#",
      key: "#",
      width: 50,
      fixed: "left",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Material Name",
      dataIndex: "materialLabel",
      key: "materialLabel",
      width: 200,
      fixed: "left",
    },
    {
      title: "Need By Date",
      dataIndex: "needByDate",
      key: "needByDate",
      width: 150,
      hidden: previousFormData?.needByDateType === "Line Level" ? false : true,
      render: (text) => dayjs(text).format("DD-MMM-YYYY"),
    },
    {
      title: "HSN / SAC",
      dataIndex: "hsnCode",
      key: "hsnCode",
      width: 100,
    },
    {
      title: "Qty",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (text, record) => `${text} ${record?.uomLabel}`,
    },
    {
      title: "Rate",
      dataIndex: "unitPrice",
      key: "unitPrice",
      width: 150,
    },
    {
      title: "Basic Amount",
      dataIndex: "amount",
      key: "amount",
      width: 150,
    },
    {
      title: "CGST",
      children: [
        {
          title: "%",
          dataIndex: "cgstPercentage",
          key: "cgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "cgstAmount",
          key: "cgstAmount",
          width: 150,
          render: (text) => <p className="text-right">{text}</p>,
        },
      ],
    },
    {
      title: "SGST",
      children: [
        {
          title: "%",
          dataIndex: "sgstPercentage",
          key: "sgstPercentage",
          width: 100,
          render: (text: any) => `${Number(text)}%`,
        },
        {
          title: "Amount",
          dataIndex: "sgstAmount",
          key: "sgstAmount",
          width: 150,
        },
      ],
    },
    {
      title: "Line Total",
      key: "lineTotal",
      width: 150,
      render: (text, record: any) => {
        return (
          <p className="justify-end">
            {formatCurrency(Number(record?.lineTotal) || 0, "INR")}
          </p>
        );
      },
    },
  ]);

  const onSubmit = async (data: any) => {
    setDisableSubmit(true);
    const formData: any = new FormData();
    let _filesData: any = [];
    let orderData: any = {
      ...previousFormData,
    };
    try {
      const lineItems = await Promise.all(
        previousFormData?.items.map(async (lineItem: any, index: number) => {
          let obj = { ...lineItem };
          let finalObj: any = {
            uuid: obj?.key?.toString(),
            materialId: Number(obj?.materialName),
            hsnCode: Number(obj?.hsnCode),
            categoryId: Number(obj?.materialCategory),
            description: obj?.description,
            quantity: Number(obj?.quantity),
            uomId: Number(obj?.uomId),
            unitPrice: Number(obj?.unitPrice),
            discountPercentage: 0,
            sgstPercentage: Number(obj?.sgstPercentage),
            cgstPercentage: Number(obj?.cgstPercentage),
            igstPercentage: Number(obj?.igstPercentage),
            additionalCharges: 0,
          };

          if (obj?.file && obj?.file[0]) {
            formData.append("files", obj?.file[0]);
            _filesData.push({
              indexOfFileLocated: _filesData.length,
              module: "po",
              purpose: "special instructions",
              level: "Line Level",
              uniqueId: obj.key,
              action: "Create",
            });
          } else if (obj?.dms?.length > 0) {
            _filesData.push({
              docId: obj?.dms[0]?.docId,
              indexOfFileLocated: 99999,
              module: "po",
              purpose: "special instructions",
              level: "Line Level",
              uniqueId: obj.key,
              action: "Create",
            });
          }
          if (previousFormData?.needByDateType === "Line Level") {
            finalObj.needByDate = dayjs(obj?.needByDate).format("YYYY-MM-DD");
          }
          if (previousFormData?.deliveryAddressType == "Line Level") {
            finalObj.deliveryAddressId = Number(obj?.deliveryAddressId);
          }
          if (obj?.quoteShortListId) {
            finalObj.quoteShortListId = Number(obj?.quoteShortListId);
          }
          if (obj?.supplierQuoteLineId) {
            finalObj.supplierQuoteLineId = Number(obj?.supplierQuoteLineId);
          }

          return finalObj;
        })
      );

      Object.keys(orderData).forEach((key) => {
        if (
          !isNaN(Number(orderData[key])) &&
          !["needByDate", "description"].includes(key)
        ) {
          orderData[key] = Number(orderData[key]);
        } else if (orderData[key] === "true" || orderData[key] === "false") {
          orderData[key] = orderData[key] === "true";
        } else {
          orderData[key] = orderData[key];
        }
      });
      const {
        siteId,
        needByDateType,
        supplierId,
        costCenterId,
        description,
        ledgerId,
        departmentId,
        budgetId,
        inwardRequired,
        autoRelease,
        lineItemAddition,
        autoPoDoc,
        billingAddressId,
        deliveryAddressType,
        paymentTerms,
        advancePercentage,
        creditDays,
        needByDate,
        deliveryAddressId,
        signatureObj,
        currencyObj,
        approvalsData,
        instructions,
        termsAndConditions,
        attachments,
        approvalRequired,
        quotationId,
        supplierQuoteId,
        orderId,
        contractorId,
      } = orderData;

      const currency = JSON.parse(currencyObj);
      const signature = JSON.parse(signatureObj);
      const approvals = JSON.parse(approvalsData);

      if (approvals?.data?.length > 0 && approvalRequired) {
        formData.append(
          "orderApprovalHierarchy",
          JSON.stringify(approvals?.data)
        );
      } else {
        formData.append("orderApprovalHierarchy", JSON.stringify([]));
      }
      if (signature?.image) {
        const image = base64ToFile(signature?.image, "signature.png");
        console.log("image", image);
        formData.append("files", image);
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "po",
          purpose: "signature",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      if (attachments) {
        attachments.forEach((attachment: any, index: number) => {
          if (!attachment) {
            return;
          }
          const file = attachment?.originFileObj
          formData.append(`files`, file);
          _filesData.push({
            indexOfFileLocated: _filesData.length,
            module: "po",
            purpose: "attachments",
            level: "Line Level",
            uniqueId: nanoid(4),
            action: "Create",
          });
        });
      }
      if (instructions) {
        const instructionsFile = new Blob([instructions], {
          type: "text/html",
        });
        formData.append(
          `files`,
          new File([instructionsFile], "instructions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "po",
          purpose: "instructions",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      if (termsAndConditions) {
        const termsAndConditionsFile = new Blob([termsAndConditions], {
          type: "text/html",
        });
        formData.append(
          `files`,
          new File([termsAndConditionsFile], "termsandconditions.html", {
            type: "text/html",
          })
        );
        _filesData.push({
          indexOfFileLocated: _filesData.length,
          module: "po",
          purpose: "termsAndConditions",
          level: "Header Level",
          uniqueId: nanoid(4),
          action: "Create",
        });
      }

      let _OrderData: any = {
        referenceId: nanoid(4),
        mode: "F",
        currencyId: currency?.id,
        signatureLabel: signature?.label,
        siteId,
        needByDateType,
        supplierId,
        costCenterId: costCenterId ? Number(costCenterId) : null,
        description,
        ledgerId,
        departmentId,
        budgetId,
        contractorId,
        inwardRequired,
        autoRelease: !approvalRequired,
        lineItemAddition,
        autoPoDoc,
        billingAddressId,
        deliveryAddressType,
        paymentTerms,
        advancePercentage,
        creditDays,
        lineItems: lineItems,
      };
      if (orderId) {
        _OrderData.orderId = orderId;
      }
      if (quotationId) {
        _OrderData.quotationNumber = quotationId;
      }
      if (supplierQuoteId) {
        _OrderData.supplierQuoteId = supplierQuoteId;
      }
      if (deliveryAddressType != "Line Level") {
        _OrderData.deliveryAddressId = deliveryAddressId;
      }
      if (previousFormData?.needByDateType == "Order Level") {
        _OrderData.needByDate = dayjs(needByDate).format("YYYY-MM-DD");
      }
      formData.append("orderData", JSON.stringify(_OrderData));
      formData.append("filesData", JSON.stringify(_filesData));
      postPO(formData);
    } catch (error) {
      console.log(error);
      setDisableSubmit(false);
    }
  };
  function base64ToFile(base64String: string, fileName: string) {
    const byteString = atob(base64String.split(",")[1]);
    const mimeString = base64String.split(",")[0].split(":")[1].split(";")[0];
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: mimeString });
    return new File([blob], fileName, { type: mimeString });
  }
  const postPO = async (_data: any) => {
    try {
      const response: any =
        await purchaseOrderCreation.purchaseOrderCreationMethod(_data);
      const { status, message, data } = response;
      if (status) {
        Swal.fire("Created!", data?.orderId, "success");
        navigate(`/view-purchase-order/${data?.orderNumber}`);
      } else {
        Swal.fire("Failed!", message, "error");
      }
    } catch (error) {
      Swal.fire("Failed!", "Something went wrong", "error");
    }
    setDisableSubmit(false);
  };
  const viewChange = () => {
    setScreenSize(window.innerWidth);
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };
  useEffect(() => {
    dispatch(fetchSequenceAccess());
  }, [dispatch]);

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const fetchBudgetDetails = async (siteId: any) => {
    try {
      const { data, status } = await budgetApis.getBudgetBySite(
        previousFormData.siteId
      );

      if (status) {
        let budgetOptions = data.map((item: any) => ({
          label: item.budgetName,
          value: item.headerId,
        }));
        setBudgetOptions(budgetOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSupplier = async () => {
    try {
      const res: any =
        await purchaseOrderCreation.fetchBuyerLinkedSupplierListMethod();
      const { data, status } = res;
      if (status) {
        let supplierOptions = data.map((item: any) => ({
          label: item?.sellerOrg?.name,
          value: item?.sellerOrg?.orgId?.toString(),
        }));
        setSupplierOptions(supplierOptions);
        setSupplierList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchCostCenter = async () => {
    try {
      const { data, status, message } = await costCenterApis.fetchCostCenter();
      if (status) {
        let costCenterOptions = data.map((item: any) => {
          return { label: item.costCenterName, value: item.id.toString() };
        });
        setCostCenterOptions(costCenterOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSites = async () => {
    try {
      const data = await usersApis.getCurrentUser();
      if (data) {
        let sitesOptions = data?.siteAccessibility?.map((item: any) => ({
          label: item.siteName,
          value: item.siteID.toString(),
        }));
        setSitesOptions(sitesOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchGeneralLedger = async () => {
    try {
      const { data, status, message } = await GeneralLedgerApis.fetchLedger();
      if (status) {
        let generalLedgerOptions = data.map((item: any) => {
          return { label: item.ledgerName, value: item.ledgerID.toString() };
        });
        setGeneralLedgerOptions(generalLedgerOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDepartment = async () => {
    try {
      const { data, status, message } = await departments.fetchDeptData();
      if (status) {
        let departmentOptions = data.map((item: any) => {
          return { label: item.deptName, value: item.deptID.toString() };
        });
        setDepartmentOptions(departmentOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchDeliveryAddress = async () => {
    try {
      const { data, status } = await deliveryAddressApis.fetchDeliveryAddress();
      if (status) {
        let deliveryAddressOptions = data.map((item: any) => ({
          label: item.deliveryLocationName,
          value: item.id.toString(),
        }));
        setDeliveryAddressOptions(deliveryAddressOptions);
        setDeliveryAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchBillingAddress = async () => {
    try {
      const { data, status } = await adminOrgApis.fetchAdminOrg();
      if (status) {
        let billingAddressOptions = data.map((item: any) => ({
          label: item.name,
          value: item.orgID.toString(),
        }));
        setBillingAddressOptions(billingAddressOptions);
        setBillingAddressList(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchApprovalHierarchyUsersByModuleId = async (moduleID: number) => {
    try {
      const res = await rolesApi.fetchHierarchyUsersByModuleId(
        moduleID,
        Number(previousFormData.siteId)
      );
      if (!res) return;
      const { data, status } = res;
      if (status) {
        setRoles(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchModuleConfig = async () => {
    try {
      const res = await rolesApi.getModuleConfig();
      const moduleID = res?.data?.find(
        (x: any) => x.moduleName == "Purchase Order"
      )?.moduleID;
      await fetchApprovalHierarchyUsersByModuleId(moduleID);
    } catch (err) {
      throw err;
    }
  };

  const updateColumnsWithCurrencySymbol = useCallback(() => {
    setColumns((prevColumns) => {
      return prevColumns.map((col: any) => {
        if (col.dataIndex == "unitPrice" || col.dataIndex == "amount") {
          return {
            ...col,
            render: (text: any) => (
              <div className="justify-end">
                {formatCurrency(
                  text,
                  currency?.currency_code,
                )}
              </div>
            ),
          };
        }
        if (col.children) {
          let children = col.children.map((child: any) => {
            if (child.title == "Amount") {
              return {
                ...child,
                render: (text: any) => (
                  <div className="justify-end">
                    {formatCurrency(
                      text,
                      currency?.currency_code,
                    )}
                  </div>
                ),
              };
            }
            return child;
          });
          return {
            ...col,
            children,
          };
        }
        return col;
      });
    });
  }, [currency]);

  useEffect(() => {
    updateColumnsWithCurrencySymbol();
  }, [currency, updateColumnsWithCurrencySymbol]);

  const FormatCurrency = (
    number: any,
    currencyFormat: any,
    currencySymbol: any
  ) => {
    if (!currencyFormat) {
      currencyFormat = "₹#,##0.00";
    }
    if (!currencySymbol) {
      currencySymbol = "₹";
    }
    const symbolBeforePattern = /^([^#0,]+)?([#0.,]+)$/;
    const symbolAfterPattern = /^([#0.,]+)([^#0,]+)?$/;
    let locale = "en-US"; // Default locale
    let symbolPosition = "before"; // Default to symbol before the number
    let minimumFractionDigits = 2; // Default to 2 decimal places
    let matchBefore = currencyFormat?.match(symbolBeforePattern);
    let matchAfter = currencyFormat?.match(symbolAfterPattern);
    if (matchBefore) {
      symbolPosition = "before";
    } else if (matchAfter) {
      symbolPosition = "after";
    }
    let fractionMatch = currencyFormat?.match(/[.,]0*$/);
    if (fractionMatch) {
      minimumFractionDigits = fractionMatch[0].length - 1;
    }
    let formatter = new Intl.NumberFormat(locale, {
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    });
    let formattedNumber = formatter.format(number);
    if (formattedNumber == "NaN" || formattedNumber == "undefined") {
      return "";
    }
    if (symbolPosition === "before") {
      return currencySymbol + formattedNumber;
    } else {
      return formattedNumber + currencySymbol;
    }
  };

  useEffect(() => {
    const { currencyObj, signatureObj, taxConfigurationObj } = previousFormData;
    if (currencyObj) {
      const _currency = JSON.parse(currencyObj);
      setCurrency(_currency);
    }
    if (signatureObj) {
      const _signature = JSON.parse(signatureObj);
      setSignature(_signature);
    }
    if (taxConfigurationObj) {
      onConfigureTaxSave(JSON.parse(taxConfigurationObj), false);
    }
    fetchBudgetDetails(previousFormData.siteId);
    fetchContractorList(previousFormData.siteId);
    fetchModuleConfig();
    fetchBillingAddress();
    fetchDeliveryAddress();
    fetchSupplier();
    fetchCostCenter();
    fetchSites();
    fetchGeneralLedger();
    fetchDepartment();
  }, []);

    const fetchContractorList = async (siteId: any) => {
    try {
      const { data, status } = await contractorsApis.fetchContractorBySiteId({
        id: ""+siteId,
      });
      if (status) {
        let contractorOptions = data.map((item: any) => ({
          label: item?.companyName,
          value: item?.contractorID?.toString(),
        }));
        setContractorOptions(contractorOptions);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onConfigureTaxSave = (data: any, updateField = true) => {
    setTaxConfigurationObj(data);
    setColumns((pre) => {
      if (!data?.cess?.length) return pre;
      let newColArray = [...pre].slice(0, 7);
      let additionalCol: any = [];
      if (data?.gstType == "IGST") {
        additionalCol.push({
          title: "IGST",
          children: [
            {
              title: "%",
              dataIndex: "igstPercentage",
              key: "igstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "igstAmount",
              key: "igstAmount",
              width: 150,
            },
          ],
        });
      } else {
        additionalCol.push({
          title: "CGST",
          children: [
            {
              title: "%",
              dataIndex: "cgstPercentage",
              key: "cgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "cgstAmount",
              key: "cgstAmount",
              width: 150,
            },
          ],
        });
        additionalCol.push({
          title: "SGST",
          children: [
            {
              title: "%",
              dataIndex: "sgstPercentage",
              key: "sgstPercentage",
              width: 100,
              render: (text: any) => `${Number(text)}%`,
            },
            {
              title: "Amount",
              dataIndex: "sgstAmount",
              key: "sgstAmount",
              width: 150,
            },
          ],
        });
      }
      if (data?.cess.length) {
        data.cess.forEach((item: any) => {
          if (!item.cessName) return;
          additionalCol.push({
            title: item.cessName,
            children: [
              {
                title: "%",
                dataIndex: item.cessName,
                key: item.cessName,
                width: 100,
                render: (text: any) => `${Number(text)}%`,
              },
              {
                title: "Amount",
                dataIndex: `${item.cessName}Amount`,
                key: `${item.cessName}Amount`,
                width: 150,
              },
            ],
          });
        });
      }
      additionalCol.push({
        title: "Line Total",
        key: "lineTotal",
        width: 150,
        render: (text: any, record: any) => {
          return (
            <p className="justify-end">
              {formatCurrency(
                Number(record?.lineTotal),
                currency?.currency_code,
              )}
            </p>
          );
        },
      });

      return newColArray.concat(additionalCol);
    });
  };

  const ShowSupplierInfo = ({ id }: any) => {
    let supplier = supplierList.find(
      (item: any) => item.sellerOrgId == id
    )?.sellerOrg;

    if (!supplier) {
      return <></>;
    }
    return (
      <ul>
        <li>{supplier.name}</li>
        <li>
          {[
            supplier?.address?.addressLine1,
            supplier?.address?.addressLine2,
            supplier?.address?.city,
            supplier?.address?.pincode,
            supplier?.address?.district?.districtName,
            supplier?.address?.state?.stateName,
            supplier?.address?.country?.countryName,
          ]
            .filter(Boolean)
            .join(", ")}
        </li>
        <li>GSTIN : {supplier.gstin}</li>
        <li>PAN : {supplier.pan}</li>
        <li>CIN : {supplier.cin}</li>
      </ul>
    );
  };

  const ShowAddressPopOver = ({ id }: any) => {
    if (!id) {
      return <></>;
    }
    let address = deliveryAddressList?.find((item: any) => item?.id == id);
    if (!address) {
      return <></>;
    }
    return (
      <ul>
        <li>{address?.addressLine1}</li>
        <li>{address?.addressLine2}</li>
        <li>
          {address?.city}, {address?.stateName}, {address?.countryName} -{" "}
          {address?.pincode}
        </li>
      </ul>
    );
  };

  const ApprovalTimelineContent = () => {
    const approvalsData = JSON.parse(previousFormData?.approvalsData);
    if (
      !approvalsData ||
      approvalsData?.data?.length === 0 ||
      previousFormData?.approvalRequired == "false"
    )
      return <Badge color="outline-danger">No</Badge>;
    return (
      <Popover
        content={
          <Timeline className="approval-timeline">
            {approvalsData?.data?.map((approval: any, index: number) => {
              const Role = roles.find(
                (role: any) => role?.roleID == approval?.roleId
              );
              return (
                <Timeline.Item key={index}>
                  {
                    Role?.employees?.find((e: any) => e?.id == approval?.userId)
                      ?.name
                  }
                  <div className="designation">{Role?.roleName}</div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        }
        title={<span style={{ color: "#2263b3" }}>Approval Hierarchy</span>}
      >
        <span
          style={{
            color: "#2263b3",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          Yes
        </span>
      </Popover>
    );
  };

  const getPdfUrl = (pdfBlob:any) => {
    try {
      console.log(pdfBlob);
      // setPdfBlobData(pdfBlob);
    
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Head title="New - Purchase Order " />
      <div className="content clearfix">
        <form onSubmit={handleSubmit(onSubmit)}>
          <AntCollapse defaultActiveKey={["1", "2", "3", "4", "5"]}>
            <Panel
              header={
                <div className="d-flex align-items-center justify-content-between">
                  <>General Information</>
                  <PdfGenerator
                    buyerDetails={returnBillingDetails()}
                    poDetails={returnPoDetails()}
                    supplierDetails={returnSupplierDetails()}
                    buyerOrgLogo={currentUser?.orgLogo}
                    hasIGST={taxConfigurationObj?.gstType == "IGST"}
                    TermsAndConditions={previousFormData?.termsAndConditions}
                    getPdfUrl={getPdfUrl}
                  />
                </div>
              }
              key="1"
            >
              <ul className="nav nav-tabs nav-tabs-card nav-tabs-xs w-100 p-0 m-0">
                <li className="nav-item" onClick={() => setTab("1")}>
                  <a
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className={`nav-link${tab === "1" ? " active" : ""}`}
                    href="#year"
                  >
                    General Info
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab("2")}>
                  <a
                    href="#overview"
                    onClick={(ev) => {
                      ev.preventDefault();
                    }}
                    className={`nav-link${tab === "2" ? " active" : ""}`}
                  >
                    Delivery Info
                  </a>
                </li>
              </ul>

              <div className="tab-content mt-0">
                {tab === "1" && (
                  <div className={`tab-pane${tab === "1" && " active"}`}>
                    <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                      <div className="w-30 border-end ">
                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Site</div>
                          <div className="data-value w-70 ">
                            {
                              sitesOptions.find(
                                (item) => item.value == previousFormData.siteId
                              )?.label
                            }
                          </div>
                        </div>
                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Supplier</div>
                          <div className="data-value w-70 ">
                            {
                              supplierOptions.find(
                                (item) =>
                                  item.value == previousFormData.supplierId
                              )?.label
                            }
                          </div>
                        </div>
                        <div className="data-col align-center pt-1">
                          <div className="data-label w-30">Description</div>
                          <div className="data-value w-70">
                            {previousFormData?.description || "--"}
                          </div>
                        </div>
                        <div className="data-col align-center pt-1">
                          <div className="data-label w-30">Approvals</div>
                          <div className="data-value w-70">
                            <ApprovalTimelineContent />
                          </div>
                        </div>
                      </div>
                      <div className="w-30 border-end paddingLeft10  ">
                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Contractor </div>
                          <div className="data-value w-70 ">
                            {contractorOptions.find(
                              (item) => item.value == previousFormData.contractorId
                            )?.label || "--"}
                          </div>
                        </div>

                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Budget</div>
                          <div className="data-value w-70 ">
                            {budgetOptions.find(
                              (item) => item.value == previousFormData.budgetId
                            )?.label || "--"}
                          </div>
                        </div>

                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Payment Terms</div>
                          <div className="data-value w-70 ">
                            <Badge color="outline-success">
                              {previousFormData?.paymentTerms || "--"}
                            </Badge>
                          </div>
                        </div>
                        {previousFormData?.paymentTerms == "Advance" && (
                          <div className="data-col align-center pt-1 ">
                            <div className="data-label w-30">Advance</div>
                            <div className="data-value w-70 ">
                              <span className="change up text-danger">
                                {previousFormData?.advancePercentage || "--"}%
                              </span>
                            </div>
                          </div>
                        )}
                        {previousFormData?.paymentTerms != "Cash and Carry" && (
                          <div className="data-col align-center pt-1 ">
                            <div className="data-label w-30">Credit Days</div>
                            <div className="data-value w-70 ">
                              <Badge color="outline-primary">
                                {previousFormData?.creditDays || "--"} days
                              </Badge>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-30 paddingLeft10">
                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Cost Center</div>
                          <div className="data-value w-70 ">
                            {
                              costCenterOptions.find(
                                (item) =>
                                  item.value == previousFormData.costCenterId
                              )?.label
                            }
                          </div>
                        </div>

                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Ledger</div>
                          <div className="data-value w-70 ">
                            {
                              generalLedgerOptions.find(
                                (item) =>
                                  item.value == previousFormData.ledgerId
                              )?.label
                            }
                          </div>
                        </div>

                        <div className="data-col align-center pt-1 ">
                          <div className="data-label w-30">Department</div>
                          <div className="data-value w-70 ">
                            {
                              departmentOptions.find(
                                (item) =>
                                  item.value == previousFormData.departmentId
                              )?.label
                            }
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                )}
                {tab === "2" && (
                  <div className={`tab-pane${tab === "2" && " active"}`}>
                    <Col className="justify-start w-100  flex-wrap mt-3 p-3">
                      <div className="w-25 border-end">
                        <div className="data-col align-center pt-1">
                          <div className="data-label w-50">Inward Required</div>
                          <div className="data-value w-50">
                            {previousFormData?.inwardRequired == "true" ? (
                              <Badge color="outline-success">Yes</Badge>
                            ) : (
                              <Badge color="outline-danger">No</Badge>
                            )}
                          </div>
                        </div>
                        <div className="data-col align-center pt-1">
                          <div className="data-label w-50">
                            Line Level Delivery
                          </div>
                          <div className="data-value w-50">
                            {previousFormData?.needByDateType !=
                            "Order Level" ? (
                              <Badge color="outline-success">Yes</Badge>
                            ) : (
                              <Badge color="outline-danger">No</Badge>
                            )}
                          </div>
                        </div>

                        <div className="data-col align-center pt-1">
                          <div className="data-label w-50">Delivery Type</div>
                          <div className="data-value w-50">
                            {previousFormData?.deliveryAddressType || "--"}
                          </div>
                        </div>
                        {previousFormData?.deliveryAddressType !=
                          "Order Level" && (
                          <div className="data-col pt-1">
                            <div className="data-label w-50">Delivery Date</div>
                            <div className="data-value w-50">
                              <Badge color="outline-primary">
                                {dayjs(previousFormData?.needByDate).format(
                                  "DD-MMM-YYYY"
                                )}
                              </Badge>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="w-25 border-end paddingLeft10">
                        <div className=" pt-1">
                          <div className="data-label w-90 mb-1">
                            Billing Address
                          </div>
                          <div className="data-value w-90">
                            <ShowAddressPopOver
                              id={previousFormData?.billingAddressId}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 border-end paddingLeft10 ">
                        <div className=" pt-1">
                          <div className="data-label w-9 mb-1">
                            Delivery Address
                          </div>
                          <div className="data-value w-90">
                            <ShowAddressPopOver
                              id={previousFormData?.deliveryAddressId}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="w-25 paddingLeft10">
                        <div className="  pt-1">
                          <div className="data-label w-90 mb-1">
                            Supplier Info
                          </div>
                          <div className="data-value w-90">
                            <ShowSupplierInfo
                              id={previousFormData?.supplierId}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </div>
                )}
              </div>
            </Panel>
            <Panel header="Material Information" key="2">
              <div className="table-responsive poTable">
                <div
                  style={{
                    width: screenSize - 170 + "px",
                    margin: "auto",
                  }}
                >
                  {columns && (
                    <Table
                      className="customTable"
                      columns={columns}
                      dataSource={previousFormData?.items}
                      scroll={{ x: 1000 }}
                      expandable={{
                        expandedRowRender: (record: any) => (
                          <div className="expandableBody">
                            <div style={{ flex: 1, textAlign: "left" }}>
                              <p style={{ margin: 0 }}>{record.description}</p>
                              {record.file && record.file[0] && (
                                <div>
                                  <PaperClipOutlined /> &nbsp;
                                  <a
                                    href={URL.createObjectURL(record.file[0])}
                                    download={record.file[0]?.name}
                                  >
                                    {record.file[0]?.name}
                                  </a>
                                </div>
                              )}
                            </div>
                            {previousFormData?.deliveryAddressType ===
                              "Line Level" &&
                              record.deliveryAddressId && (
                                <div
                                  className="lineAddress"
                                  style={{ flex: 1 }}
                                >
                                  <h6>Delivery Address</h6>
                                  <ShowAddressPopOver
                                    id={record.deliveryAddressId}
                                  />
                                </div>
                              )}
                          </div>
                        ),
                      }}
                      bordered
                      size="small"
                      pagination={false}
                    />
                  )}
                </div>
                {previousFormData?.items?.length > 0 && (
                  <Row
                    style={{
                      margin: "20px",
                    }}
                  >
                    <Col className="justify-end">
                      <div className="w-40">
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>Taxable Amount</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.amount),
                                0
                              ),
                              currency?.currency_code,
                            )}
                          </span>
                        </div>
                        {
                          previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.sgstAmount),
                                0
                              )+previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.cgstAmount),
                                0
                              )>0?<>
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>CGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.cgstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>SGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.sgstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                        </>:
                        <div className="d-flex justify-content-between">
                          <div className="w-50 justify-end">
                            <strong>IGST</strong>
                          </div>
                          <span className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.igstAmount),
                                0
                              ),
                              currency?.currency_code
                            )}
                          </span>
                        </div>
                        }
                        <div
                          className="d-flex justify-content-between"
                          style={{ fontSize: "18px", marginTop: "10px" }}
                        >
                          <div className="w-50 justify-end">
                            <strong>Total</strong>
                          </div>
                          <strong className="r_align">
                            {formatCurrency(
                              previousFormData?.items.reduce(
                                (a: any, b: any) => a + Number(b.lineTotal),
                                0
                              ),
                              currency?.currency_code,
                            )}
                          </strong>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <Row className="m-4 justify-content-end ">
                  {signature?.isActive && (
                    <div className="signatureBtnWrapper">
                      <div className="d-flex justify-content-between align-items-center">
                        <strong>Signature</strong>
                      </div>
                      <div
                        style={{
                          minHeight: signature?.image ? "0px" : "120px",
                          display: signature?.image ? "block" : "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {signature?.image && (
                          <>
                            <div className="signatueImgWrapper border">
                              <img
                                src={signature?.image}
                                alt="Signed"
                                style={{ width: "160px" }}
                              />
                            </div>

                            <div>
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="signatureLabel"
                                >
                                  Signature Label
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="signatureLabel"
                                  onChange={(e) => {}}
                                  disabled
                                  defaultValue={
                                    signature?.label || "Authorised Signatory"
                                  }
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </Row>
              </div>
            </Panel>
            <Panel header="Other Information" key="3">
              <Descriptions title="" className="custom-description-title">
                <Descriptions.Item label="Instructions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setInstructionsVisible(true)}
                  >
                    View
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Terms and Conditions">
                  <span
                    style={{
                      color: "#2263b3",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => setTermsVisible(true)}
                  >
                    View
                  </span>
                </Descriptions.Item>
                {previousFormData?.attachments?.length > 0 && (
                  <Descriptions.Item label="">
                    <div>
                      <div className="">
                        Attachments &nbsp;
                        <PaperClipOutlined />
                      </div>
                      <div>
                        {previousFormData.attachments?.map(
                          (attachment: any, index: number) => (
                            <div
                              key={index}
                              className="w-100 d-flex justify-content-between"
                            >
                              <div>
                                <strong>{index + 1}.</strong>
                                &nbsp;
                                {attachment.name}
                                &nbsp; &nbsp; &nbsp; &nbsp;
                              </div>
                              <div>
                                <a
                                  href={URL.createObjectURL(
                                    attachment?.originFileObj
                                  )}
                                  download={attachment.name}
                                  style={{ color: "black", fontSize: "18px" }}
                                >
                                  <DownloadOutlined />
                                </a>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </Descriptions.Item>
                )}
              </Descriptions>
            </Panel>
          </AntCollapse>
          <Modal
            title="Instructions"
            visible={instructionsVisible}
            footer={null}
            onCancel={() => setInstructionsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.instructions ? (
              <pre>{previousFormData?.instructions}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No instructions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <Modal
            title="Terms and Conditions"
            visible={termsVisible}
            footer={null}
            onCancel={() => setTermsVisible(false)}
            width="60%"
            bodyStyle={{ maxHeight: "70vh", overflowY: "auto" }}
          >
            {previousFormData?.termsAndConditions ? (
              <pre>{previousFormData?.termsAndConditions}</pre>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <Empty description="No terms and conditions provided for this purchase order" />
              </div>
            )}
          </Modal>
          <div className="actions clearfix">
            <ul>
              <li>
                {disableSubmit ? (
                  <Button color="primary" type="button">
                    <Spinner size="sm" color="light" />
                  </Button>
                ) : (
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                )}
              </li>
              <li>
                <Button
                  color="primary"
                  onClick={() => {
                    props.prev();
                  }}
                  className="btn-dim btn btn-secondary"
                >
                  Previous
                </Button>
              </li>
            </ul>
          </div>
        </form>
      </div>
    </>
  );
};

export default PurchaseOrder_Preview;
