import React, { useCallback, useEffect, useState } from "react";
import { Table, Spin } from "antd";
import { AnyIfEmpty } from "react-redux";

const ServerSideTable: React.FC<any> = ({ columns, fetchData, rowKey,externalData }) => {
  const [data, setData]: any = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0, // Initialize total for correct pagination
  });
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("ASC");
  const [direction, setDirection] = useState("P");
  const [orderNumber, setOrderNumber] = useState(null);
  const [previousPage, setPreviousPage] = useState(0);
  const [editedRows, setEditedRows] = useState<any>({});

  const mergeEditedData = (fetchedData:any) => {
    // Merge edited rows with fetched data
    return fetchedData.map((row:any) => {
      if (editedRows[row[rowKey]]) {
        return { ...row, ...editedRows[row[rowKey]] };
      }
      return row;
    });
  };

  const loadData = useCallback(async () => {
    if (!fetchData) return; // Only fetch if fetchData is provided
    setLoading(true);
    console.log("Load Data Params:", {
      currentPage: pagination.current,
      direction,
      pageDiff: pagination.current - previousPage,
      sortOrder,
      orderNumber,
  });

    // Calculate pageDiff based on the direction and previous page values
    const pageDiff =
      direction === "P"
        ? pagination.current - 1
        : Math.abs(pagination.current - previousPage) - 1;

    console.log("pageDiff", pageDiff);
    const apiParams = {
      sortOrder,
      pageSize: pagination.pageSize.toString(),
      direction,
      pageDiff,
      orderNumber,
    };

    try {
      const response = await fetchData(apiParams);
      console.log("Fetched Data:", response.data);
      setData(response.data);
      setPagination((prev) => ({
        ...prev,
        total: direction === "P" ? response.totalCount : prev.total,
      }));
    } catch (error) {
      console.error("Error loading data:", error);
    } finally {
      setLoading(false);
    }
  }, [
    pagination.current,
    pagination.pageSize,
    direction,
    orderNumber,
    previousPage,
    sortOrder,
    fetchData,
  ]);

  useEffect(() => {
    if (externalData && externalData.length > 0) {
        console.log("Merging with External Data:", externalData);
        setData(mergeEditedData(externalData));
    } else {
        console.log("External data is empty, loading data from API...");
        loadData(); // Fallback to fetch from API
    }
}, [externalData, loadData]);

  const handleTableChange = (newPagination: any, filters: any, sorter: any) => {
    const newPage = newPagination.current;
    const isPaginationChange = newPage !== pagination.current;

    setPreviousPage(pagination.current);

    // Check if sorting has changed
    if (sorter.order) {
      console.log("Sorting detected", sorter);
      setSortOrder(sorter.order === "descend" ? "DESC" : "ASC");
      setDirection("P"); // Reset direction for sorting
      setOrderNumber(null); // No need for orderNumber in sorting context
    }
    if (isPaginationChange) {
      // Handle pagination
      const newDirection =
        newPage > pagination.current
          ? "F" // Forward
          : "B"; // Backward

      setDirection(newDirection);
      setOrderNumber(
        newDirection === "F"
          ? data[data.length - 1]?.paginationId // Last item ID for forward direction
          : data[0]?.paginationId // First item ID for backward direction
      );
    }

    // Update pagination state with new page and page size
    setPagination({
      ...newPagination,
      total: pagination.total,
    });
  };


  const handleEditRow = (id:any, field:any, value:any) => {
    // Update editedRows
    setEditedRows((prev:any) => ({
      ...prev,
      [id]: {
        ...(prev[id] || {}),
        [field]: value,
      },
    }));

    // Update table data to reflect changes immediately
    setData((prevData:any) =>
      prevData.map((row:any) =>
        row[rowKey] === id ? { ...row, [field]: value } : row
      )
    );
  };


  const editableColumns = columns.map((col:any) => {
    if (col.editable) {
      return {
        ...col,
        onCell: (record:any) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleEditRow,
        }),
      };
    }
    return col;
  });

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={data}
        rowKey={rowKey}
        pagination={{
          ...pagination,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50"],
          onChange: (page, pageSize) => {
            // Update the pagination state with the new page number and page size
            setPagination((prevPagination: any) => ({
              ...prevPagination,
              current: page,
              pageSize: pageSize,
            }));
          },
          onShowSizeChange: (current, size) => {
            // This ensures that the page size is updated when the size changer is used
            setPagination((prevPagination: any) => ({
              ...prevPagination,
              pageSize: size,
              current: 1, // Reset to page 1 when page size is changed
            }));
          },
        }}
        onChange={handleTableChange}
        bordered
        size="middle"
        scroll={{ x: 1400 }}
      />
    </Spin>
  );
};

export default ServerSideTable;
