import React from "react";
import DefaultReportTemplate from "../defaultReportTemplate";
import { useNavigate } from "react-router";
import {Icon} from "../../../components/Component"
import { Tooltip } from "antd";
import { formatCurrency, formatCurrency2 } from "../../../utils/Utils";

function SupplierBalancesReport() {
  const navigate = useNavigate();
  const columns: any = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
      key: "supplierName",
      fixed: "left",
      width: 250,
      render(text: any, record: any) {
        return (
          <span
            className="inlineTableCellLink"
            onClick={() => {
              navigate(`/supplierBalancesDetailReport/${record?.supplierId}`, {
                state: {
                  name: record?.supplierName,
                },
              });
            }}
          >
            {text}
          </span>
        );
      },
    },
    {
      title: "Invoiced Amount",
      dataIndex: "invoicedAmount",
      key: "invoicedAmount",
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Paid Amount",
      dataIndex: "paidAmount",
      key: "paidAmount",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Credit Note",
      dataIndex: "creditNote",
      key: "creditNote",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Balance Payables",
      dataIndex: "balancePayables",
      key: "balancePayables",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Payables",
      dataIndex: "overduePayables",
      key: "overduePayables",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
    {
      title: "Overdue Rec. %",
      dataIndex: "overdueRecPercentage",
      key: "overdueRecPercentage",
      width: 150,
      render: (text: any, record: any) => (
        <div style={{ textAlign: "right" }}>
          {formatCurrency(text?.toString(), record?.currencyCode)}
        </div>
      ),
    },
  ];
  return (
    <DefaultReportTemplate
      pageTitle="Supplier Balances Report"
      moduleKey="supplierBalancesReport"
      columns={columns}
      filtersOption={{
        site: true,
        contractor: true,
        dateRange: true,
        aging: false,
      }}
      previousPath="/view-accounts-payable-reports"
      extra={{
        tableScrollX: 1200,
      }}
    />
  );
}

export default SupplierBalancesReport;
