import React, { useState, useEffect, FC } from "react";
import { Link } from "react-router-dom";
import Content from "../../layout/content/Content";
import { Card } from "reactstrap";
import Head from "../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  PreviewAltCard,
} from "../../components/Component";
import { Row, Col } from "reactstrap";
import QuotationsAside from "./QuotationsAside";
import QuotationSummaryAnalytics from "./analytics/QuotationSummaryAnalytics";
import MaterialWiseSavings from "./analytics/MaterialWiseSavings";
import QuotationResponseTime from "./analytics/QuotationResponseTime";
import Top10NegotiationsByMaterials from "./analytics/Top10NegotiationsByMaterials";
import MonthlySavingsTrends from "./analytics/MonthlySavingsTrends";
import { Select, DatePicker, Flex, Spin } from "antd";
import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import supplierApis from "../../api/master/supplier";
import quotationApis from "../../api/master/quotations";
import Swal from "sweetalert2";
import contractorsApis from "../../api/master/contractors";

const { Option } = Select;
const { RangePicker } = DatePicker;

const RFQAnalytics: FC = () => {
  const {
    data: currentUser,
    loading,
    error,
  } = useSelector((state: RootState) => state.currentUser);
  const [sm, setSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const [site, setSite] = useState("");
  const [siteOptions, setSiteOptions] = useState<any>([]);

  const [category, setCategory] = useState("");
  const [categoryOptions, setCategoryOptions] = useState<any>([]);

  const [contractor, setContractor] = useState("");
  const [contractorOptions, setContractorOptions] = useState<any>([]);

  const [dateRange, setDateRange] = useState("Quarter to Date");
  const [customDateRange, setCustomDateRange] = useState<any>();

  const [quotationSummary, setQuotationSummary] = useState<any>([]);
  const [materialWiseSavings, setMaterialWiseSavings] = useState<any>([]);
  const [quotationResponseTime, setQuotationResponseTime] = useState<any>([]);
  const [top10NegotiationsbyMaterials, setTop10NegotiationsbyMaterials] =
    useState<any>([]);
  const [monthlySavingsTrends, setMonthlySavingsTrends] = useState<any>([]);
  const [loadingCount, setLoadingCount] = useState(5);

  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      setSm(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const { siteAccessibility } = currentUser;
      if (siteAccessibility) {
        const allSites: any = [];
        const _siteOptions: any = [];
        siteAccessibility?.forEach((item: any) => {
          allSites.push(item.siteID);
          _siteOptions.push({ label: item.siteName, value: item.siteID });
        });
        setSite(allSites.join(","));
        setSiteOptions([
          {
            label: "All Sites",
            value: allSites?.join(","),
          },
          ..._siteOptions,
        ]);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const { data, status } = await supplierApis.fetchSupplierCategory();
      if (status) {
        const allCategory: any = [];
        const _categoryOptions: any = [];
        data?.forEach((item: any) => {
          // console.log("data", item);
          allCategory.push(item.id);
          _categoryOptions.push({ label: item.category, value: item.id });
        });
        setCategory(allCategory.join(","));
        setCategoryOptions([
          {
            label: "All Categories",
            value: allCategory?.join(","),
          },
          ..._categoryOptions,
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const fetchContractorList = async (siteId: any) => {
  //   try {
  //     const { data, status } = await contractorsApis.fetchContractorBySiteId({
  //       id: siteId + "",
  //     });
  //     if (status) {
  //       let allContractors: any = [];
  //       let _contractorOptions: any = [];
  //       data.forEach((item: any) => {
  //         allContractors.push(item?.contractorID?.toString());
  //         _contractorOptions.push({
  //           label: item?.companyName,
  //           value: item?.contractorID?.toString(),
  //         });
  //       });

  //       setContractor(allContractors?.join(","));
  //       if (_contractorOptions.length > 1) {
  //         _contractorOptions = [
  //           {
  //             label: "ALL",
  //             value: allContractors?.join(","),
  //           },
  //           ..._contractorOptions,
  //         ];
  //       }
  //       setContractorOptions(_contractorOptions);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const convertToDates = (dateType: string) => {
    switch (dateType) {
      case "Month to Date":
        return {
          from: dayjs().startOf("month").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Quarter to Date":
        return {
          from: dayjs()
            .subtract(3, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      case "Year to Date":
        return {
          from: dayjs().startOf("year").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
      default:
        return {
          from: dayjs(customDateRange[0]).format("YYYY-MM-DD"),
          to: dayjs(customDateRange[1]).format("YYYY-MM-DD"),
        };
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    const headerClick = () => setSm(false);
    document
      .getElementsByClassName("nk-header")[0]
      ?.addEventListener("click", headerClick);

    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
      document
        .getElementsByClassName("nk-header")[0]
        ?.removeEventListener("click", headerClick);
    };
  }, []);

  const handleSiteChange = (value: string) => {
    setSite(value.toString());
  };

  const fetchQuotationSummary = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        contractorId: contractor,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await quotationApis.quotationSummary(formData);
      const { response, status, message } = res;
      if (status) {
        setQuotationSummary(response);
        setLoadingCount((pre: any) => --pre);
      } else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMaterialWiseSavings = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        contractorId: contractor,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await quotationApis.quotationMaterialWiseSavings(formData);
      const { response, status, message } = res;
      if (status) {
        setMaterialWiseSavings(response);
        setLoadingCount((pre: any) => --pre);
      } else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchQuotationResponseTime = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        contractorId: contractor,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await quotationApis.quotationResponseTime(formData);
      const { response, status, message } = res;
      if (status) {
        setQuotationResponseTime(response);
        setLoadingCount((pre: any) => --pre);
      } else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchTop10NegotiationsbyMaterials = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        contractorId: contractor,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await quotationApis.quotationTop10NegotiationByMaterials(
        formData
      );
      const { response, status, message } = res;
      if (status) {
        setTop10NegotiationsbyMaterials(response);
        setLoadingCount((pre: any) => --pre);
      } else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMonthlySavingsTrends = async () => {
    try {
      const { from, to } = convertToDates(dateRange);
      const formData = {
        siteId: site,
        contractorId: contractor,
        categoryId: category,
        startDate: from,
        endDate: to,
      };
      const res = await quotationApis.quotationMonthOnMonthSavings(formData);
      const { response, status, message } = res;
      if (status) {
        setMonthlySavingsTrends(response);
        setLoadingCount((pre: any) => --pre);
      } else {
        Swal.fire("Error", message, "error");
      }
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateRangeChange = (value: string) => {
    setDateRange(value);
    if (value !== "Custom Date") {
      setCustomDateRange(null);
    }
  };

  const handleCustomDateChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      setCustomDateRange([
        moment(dates[0].toDate()),
        moment(dates[1].toDate()),
      ]);
    } else {
      setCustomDateRange(null);
    }
  };

  const handleCategoryChange = (value: string) => {
    setCategory(value.toString());
  };

  useEffect(() => {
    if (site && category) {
      setLoadingCount(5);
      fetchQuotationSummary();
      fetchMaterialWiseSavings();
      fetchQuotationResponseTime();
      fetchTop10NegotiationsbyMaterials();
      fetchMonthlySavingsTrends();
    } else setLoadingCount(0);
  }, [site, category, dateRange, customDateRange]);

  // useEffect(() => {
  //   if (site) {
  //     fetchContractorList(site);
  //   }
  // }, [site]);

  return (
    <>
      <Head title="RFQ - RFQAnalytics" />
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <QuotationsAside updateSm={setSm} sm={sm} />
            </div>
            <div className="card-inner card-inner-lg">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => setSm(!sm)}
                ></div>
              )}
              <BlockHead size="lg">
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h4">RFQ Analytics</BlockTitle>
                    <BlockDes>
                      <p>
                        Comprehensive RFQ Analytics to Monitor and Optimize Your
                        Quotation Processes
                      </p>
                    </BlockDes>
                  </BlockHeadContent>
                </BlockBetween>
                <div className="d-flex align-items-center flex-wrap">
                  <div style={{ marginRight: 16,marginTop: 8  }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Site:
                    </label>
                    <Select
                      defaultValue="ALL"
                      style={{ width: 150 }}
                      onChange={handleSiteChange}
                    >
                      {siteOptions?.map((item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </div>
                  {/* <div style={{ marginRight: 16, marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Contractor:
                    </label>
                    <Select
                      defaultValue={
                        contractorOptions?.length == 1
                          ? contractorOptions[0].label
                          : "ALL"
                      }
                      style={{ width: 150 }}
                      onChange={(value: any) => {
                        setContractor(value);
                      }}
                    >
                      {contractorOptions?.map((item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </div> */}
                  <div style={{ marginRight: 16,marginTop: 8  }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Category:
                    </label>
                    <Select
                      defaultValue="ALL"
                      style={{ width: 150 }}
                      onChange={handleCategoryChange}
                    >
                      {categoryOptions?.map((item: any) => (
                        <Option value={item.value}>{item.label}</Option>
                      ))}
                    </Select>
                  </div>
                  <div style={{ marginTop: 8 }}>
                    <label style={{ fontWeight: "bold", marginRight: 8 }}>
                      Filter by Date Range:
                    </label>
                    <Select
                      defaultValue="Quarter to Date"
                      style={{ width: 200, marginRight: 16 }}
                      onChange={handleDateRangeChange}
                    >
                      <Option value="Month to Date">Month to Date</Option>
                      <Option value="Quarter to Date">Quarter to Date</Option>
                      <Option value="Year to Date">Year to Date</Option>
                      <Option value="Custom Date">Custom Date</Option>
                    </Select>
                    {dateRange === "Custom Date" && (
                      <RangePicker
                        value={
                          customDateRange
                            ? [
                                dayjs(customDateRange[0].toDate()),
                                dayjs(customDateRange[1].toDate()),
                              ]
                            : null
                        }
                        onChange={(dates, dateStrings) =>
                          handleCustomDateChange(dates)
                        }
                        style={{ width: 300 }}
                      />
                    )}
                  </div>
                </div>
              </BlockHead>
              {loadingCount > 0 ? (
                <>
                  <Flex align="center" gap="middle" className="pageLoader">
                    <Spin size="large" className="m-auto" />
                  </Flex>
                </>
              ) : (
                <Block>
                  <Row className="g-gs">
                    <Col md="4">
                      <PreviewAltCard
                        className="card-full"
                        bodyClass={undefined}
                      >
                        <QuotationSummaryAnalytics
                          quotationSummaryData={quotationSummary}
                        />
                      </PreviewAltCard>
                    </Col>
                    <Col md="8">
                      <PreviewAltCard
                        className="card-full"
                        bodyClass={undefined}
                      >
                        <MaterialWiseSavings
                          materialWiseSavingsData={materialWiseSavings}
                        />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                  <Row className="g-gs" style={{ marginTop: 3 }}>
                    <Col md="6">
                      <PreviewAltCard
                        className="card-full"
                        bodyClass={undefined}
                      >
                        <QuotationResponseTime
                          quotationResponseTimeData={quotationResponseTime}
                        />
                      </PreviewAltCard>
                    </Col>
                    <Col md="6">
                      <PreviewAltCard
                        className="card-full"
                        bodyClass={undefined}
                      >
                        <Top10NegotiationsByMaterials
                          top10NegotiationsByMaterialsData={
                            top10NegotiationsbyMaterials
                          }
                        />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12" style={{ marginTop: 30 }}>
                      <PreviewAltCard
                        className="card-full"
                        bodyClass={undefined}
                      >
                        <MonthlySavingsTrends
                          monthlySavingsTrendsData={monthlySavingsTrends}
                        />
                      </PreviewAltCard>
                    </Col>
                  </Row>
                </Block>
              )}
            </div>
          </div>
        </Card>
      </Content>
    </>
  );
};

export default RFQAnalytics;
